import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hook';
import { IListing } from '../../../../models/create-listing/iListing';
import { CardDescription } from '../../CardDescription';
import { selectCurrentListing } from '../../createListingSelectors';
import { setCurrentListing } from '../../store/createListingSlice';


export function CardDescriptionPage() {
    const dispatch = useAppDispatch();
    const currentListing: IListing = useAppSelector(selectCurrentListing);

    const [selected, setSelected] = useState<string>(currentListing.description);

    const onSelected = (description: string) => {
        setSelected(description);
        dispatch(setCurrentListing({ ...currentListing, description }));
    };


    return <CardDescription onChange={onSelected} value={selected} />;

}
