import classes from '../styles/mobileHeader.module.scss';
import React from 'react';

export function MobileHeaderBar(props:
    {
        children?: React.ReactNode,
        previousPageName?: string,
        nextPageName?: string,
        currentPage?: string,
        showTitle: boolean,
        onBackClicked?: () => void;
        onNextClicked?: () => void;
    }) {
    return <div className={classes.mobileHeaderRoot} >
        <div className={classes.mobileBackButton}>
            <a aria-label='link' onClick={props.onBackClicked} >
                {props.previousPageName}
            </a>
        </div>
        {props.showTitle && <span className={classes.currentStep}>{props.currentPage}</span>}
        <>{props.children}</>
    </div>;
}