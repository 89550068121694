import React, { useEffect } from 'react';
import { Dialog } from './shared/dialog';
import classes from './image.module.scss';
import { createPortal } from 'react-dom';
import { ReactComponent as ArrowLeft } from '../assets/svg/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../assets/svg/arrow-right.svg';
import { ReactComponent as CloseIcon } from '../assets/svg/exit.svg';
import TouchSweep from '../utils/swipe';

export interface ImageDialogProps {
    show: boolean;
    onClose: () => void;
    imageList?: string[];
    selectedImage?: number;
    selectedImageChanged?: (index: number) => void;
}

export function ImageDialog(props: ImageDialogProps) {

    function close() {
        props.onClose();
    }

    const [selectedImage, setSelectedImage] = React.useState<number>(props.selectedImage ?? 0);

    useEffect(() => {
        setSelectedImage(props.selectedImage ?? 0);
    }, [props.selectedImage]);


    const ImageSweep = (props: { imageList: string[]; selectedImageChanged?: (index: number) => void; }) => {
        const ref = React.useRef<HTMLDivElement>(null);

        const loopArray = (index: number, array: any[]) => {
            if (index >= array.length) return 0;
            if (index < 0) return array.length - 1;

            return index;
        };

        function selectImageFunction(e: { type: 'swipeleft' | 'swiperight'; }) {
            switch (e.type) {
                case 'swipeleft': {
                    setSelectedImage(loopArray(selectedImage + 1, props.imageList));
                    break;
                }
                case 'swiperight': {
                    setSelectedImage(loopArray(selectedImage - 1, props.imageList));
                    break;
                }
            }

        }

        useTouchSweep(ref, selectImageFunction);


        return <listing-image-dialog>
            <>
                <div className={classes.content} ref={ref} style={{ pointerEvents: 'stroke' }} >
                    <img src={props.imageList[selectedImage]} style={{ width: '100%' }} />;
                </div>
            </>
            <div className={classes.controls}>
                <button aria-label="swipe left" className={classes.arrowLeft} onClick={() => selectImageFunction({ type: 'swipeleft' })}>
                    <ArrowLeft />
                </button>
                <button aria-label='swipe right' className={classes.arrowRight}>
                    <ArrowRight onClick={() => selectImageFunction({ type: 'swiperight' })} />
                </button>
            </div>
        </listing-image-dialog>;
    };


    return createPortal(<Dialog show={props.show} onClose={close} style={{ padding: 20 }} className={classes.dialog}>
        <>
            <ImageSweep imageList={props.imageList ?? []} />
            <CloseDialog onClick={close} style={{ position: 'absolute', right: 40, top: 30 }} />
        </>
    </Dialog>, document.body);
}

export function CloseDialog(props: { onClick: () => void; style: React.CSSProperties; }) {

    const onClick = () => {
        props.onClick();
    };
    return <span style={{ ...props.style, textAlign: 'right', cursor: 'pointer' }} onClick={onClick}>
        <CloseIcon style={{ height: 30, width: 30, fill: '#ffffff60' }} />
    </span>;

}

export function useTouchSweep<T extends HTMLElement>(ref: React.RefObject<T>, onSweep?: (e: any) => void) {

    const [element, setElement] = React.useState<React.RefObject<T>>(ref);

    useEffect(() => {
        setElement(ref);
    }, [ref.current]);


    useEffect(() => {

        if (!element.current) return;

        new TouchSweep(
            element.current, {
            value: 1
        });


        element.current.addEventListener('swipeleft', swipeleft);
        element.current.addEventListener('swiperight', swiperight);
        element.current.addEventListener('swipeup', swipeup);
        element.current.addEventListener('swipedown', swipedown);
        element.current.addEventListener('swipemove', swipemove);
        element.current.addEventListener('tap', tap);

        return () => {
            element.current?.removeEventListener('swipeleft', swipeleft);
            element.current?.removeEventListener('swiperight', swiperight);
            element.current?.removeEventListener('swipeup', swipeup);
            element.current?.removeEventListener('swipedown', swipedown);
            element.current?.removeEventListener('swipemove', swipemove);
            element.current?.removeEventListener('tap', tap);
        };

    }, [element.current]);

    function swipeleft(e: any) {
        onSweep?.(e);
    }
    function swiperight(e: any) {
        onSweep?.(e);
    }
    function swipeup(e: any) {
        onSweep?.(e);
    }
    function swipedown(e: any) {
        onSweep?.(e);
    }
    function swipemove(e: any) {
        onSweep?.(e);
    }
    function tap(e: any) {
        onSweep?.(e);
    }

    return {

    };

}