import React from 'react';
import { ListingType } from '../../../../components/tiles/Tiles';
import { useAppDispatch, useAppSelector } from '../../../../hook';
import { IListing } from '../../../../models/create-listing/iListing';
import { selectCurrentListing } from '../../createListingSelectors';
import { CardListingType } from '../../ListingType';
import { clearCurrentListing, initialiseCurrentListing, setListingType, setLoadingStatus } from '../../store/createListingSlice';


export function CardListingTypePage() {
    const dispatch = useAppDispatch();

    const currentListing: IListing = useAppSelector(selectCurrentListing);

    const onSelected = (listingType: ListingType) => {

        // clear session and clear listing when a listingType is selected.
        if (currentListing.listingType !== listingType) {
            dispatch(initialiseCurrentListing);
            dispatch(clearCurrentListing());
        }
        // end clear session and clear listing.

        dispatch(setLoadingStatus(true)); // middleware creates a stub listing to attach images to on this step
        dispatch(setListingType(listingType));
    };

    return <CardListingType onSelected={onSelected} selected={currentListing.listingType as ListingType} />;
}