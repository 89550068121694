
import classes from './../../styles/file-upload.module.scss';
import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as UploadIcon } from '../../assets/svg/upload-image.svg';
import { ReactComponent as DeleteIcon } from '../../assets/svg/bin.svg';
// import DeleteIcon from '@mui/icons-material/DeleteForever';

import PreviewIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { IconButton } from '@mui/material';
import heic2any from 'heic2any';


export function FileUpload(props: {
  title?: string;
  placeholder?: string;
  fileTypes?: string[];
  onSelected?: (file: File) => void;
  onRemoved?: (name: string, files: File) => void;
  required?: boolean;
  name: string;
  value?: File;
  objectUrl?: string;
  role?: string;
  icon?: string | React.ReactNode;
  className?: string;
  showTrash?: boolean;
}) {
  const fileUpload = useRef<HTMLInputElement>(null);
  const { convert } = useHEICConvert();
  const [selectedFile, setSelectedFile] = useState<File | null>();
  const [preview, setPreview] = useState<string | undefined>(props.objectUrl);
  const onClick = () => {
    if (fileUpload.current) fileUpload.current.click();
  };

  const removeImage = (event: React.MouseEvent<HTMLOrSVGElement>) => {
    setPreview('');
    props.onRemoved && fileUpload.current?.files && event.stopPropagation();
    event.preventDefault();

    if (fileUpload.current?.files) {
      props.onRemoved &&
        props.onRemoved(props.name || '', fileUpload.current?.files[0]);
      fileUpload.current.value = '';
    }
  };

  useEffect(() => {
    setPreview(props.objectUrl);
  }, [props]);

  const processFile = async (file?: File | null) => {
    if (file?.name) {
      const isHEIC = file.name.split('.').reverse()[0].toLowerCase() === 'heic';

      if (isHEIC) {
        file = await convert(file);
      }

      props.onSelected?.(file);
    }
  };

  useEffect(() => {
    processFile(selectedFile);
  }, [selectedFile]);

  return (
    <div className={`${classes.fileUploadContainer} ${props.className ?? ''}`}>
      <input
        type="file"
        role={props.role}
        name={props.name}
        accept={props.fileTypes?.join(', ')}
        style={{ height: 0, width: 0 }}
        ref={fileUpload}
        onChange={(event) => {
          (event.target.files?.length || 0) > 0 &&
            setSelectedFile(event.target.files?.[0] as File);

          // Reset control incase we need to uploade the same file again.
          event.target.files = null;
          event.target.value = '';
        }
        }
      />

      <div
        placeholder={props.placeholder}
        className={classes.fileUpload}
        defaultValue={selectedFile?.name}
        onClick={onClick}
        style={{ backgroundImage: preview }}
      >
        {preview ? (
          <>
            <div className={classes.backdrop}> </div>
            <img src={preview} alt="No preview provided" />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <IconButton className={classes.previewIconButton} role="preview-image" >
                <PreviewIcon className={classes.previewIcon} />
              </IconButton>
              <IconButton onClick={removeImage} className={`${classes.removeIconButton} ${props.showTrash ? classes.permanent : ''} `} role="remove-image" >
                <DeleteIcon className={classes.removeIcon} style={{ height: 24, width: 24, fill: 'white' }} />
              </IconButton>
            </div>
          </>
        ) : (<div className={classes.iconContainer}>
          {props.icon ?? <UploadIcon
            className={classes.uploadIcon}
          />}
          <span>{props.title}</span>
        </div>
        )}
      </div>
    </div>
  );
}


const useHEICConvert = () => {

  const convert = async (file: File) => {
    const convertedHEIC = await heic2any({ blob: file, toType: 'image/jpeg' }) as Blob;
    return new File([convertedHEIC], file.name, { type: 'image/jpeg' });
  };

  return {
    convert
  };

};