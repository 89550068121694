import React from 'react';
import style from '../styles/Logo.module.scss';
//import logo from '../assets/png/logo.png';
import logo from '../assets/svg/logo.svg';
import { Link } from 'react-router-dom';

export function Logo(props: { children?: string; }) {
  return (
    <div className={style.logo}>
      <Link style={{ textDecoration: 'none', color: 'grey' }} to="/">
        <img src={logo} alt="logo" height={40} />
        {props.children}
      </Link>
    </div>
  );
}
