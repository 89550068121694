import { Grade } from '../models/create-listing/Grade';



//TODO OKAY OKAY this file needs a major overhaul, ive just made a huge mess here for the sake of hurrying things along;


// the reason for the granularity here was at the time of build we didnt know what gradings the companies would use.
// so we had to build a system that would allow for an explicit grading system to be used.
export const subgradeCategories: string[] = ['Centering', 'Corners', 'Edges', 'Surface'];
export const SelfAssess = 'Self-Assess';
export const gradeLabels: any[] = [
  { value: -1, label: 'Authentica Only - Altered' },
  { value: 0, label: 'Authentic Only' },
  { value: 1, label: '(Poor)' },
  { value: 1.5, label: '(Fair)' },
  { value: 2, label: '(Good)' },
  { value: 3, label: '(Very Good)' },
  { value: 4, label: '(Very Good - Excellent)' },
  { value: 5, label: 'Excellent' },
  { value: 6, label: 'Excellent - Near Mint)' },
  { value: 7, label: 'Near Mint)' },
  { value: 8, label: 'Near Mint - Mint)' },
  { value: 9, label: 'Mint)' },
  { value: 10, label: 'GEM-Mint)' }];

export const subGradeOptions: Grade[] = [
  { name: '', company: '', title: 'N/A', value: 0 }
  , { name: '', company: '', title: '1', value: 1 }
  , { name: '', company: '', title: '1.5', value: 1.5 }
  , { name: '', company: '', title: '2', value: 2 }
  , { name: '', company: '', title: '2.5', value: 1.5 }
  , { name: '', company: '', title: '3', value: 3 }
  , { name: '', company: '', title: '3.5', value: 3.5 }
  , { name: '', company: '', title: '4', value: 4 }
  , { name: '', company: '', title: '4.5', value: 4.5 }
  , { name: '', company: '', title: '5', value: 5 }
  , { name: '', company: '', title: '5.5', value: 5.5 }
  , { name: '', company: '', title: '6', value: 6 }
  , { name: '', company: '', title: '6.5', value: 6.5 }
  , { name: '', company: '', title: '7', value: 7 }
  , { name: '', company: '', title: '7.5', value: 7.5 }
  , { name: '', company: '', title: '8', value: 8 }
  , { name: '', company: '', title: '8.5', value: 8.5 }
  , { name: '', company: '', title: '9', value: 9 }
  , { name: '', company: '', title: '9.5', value: 9.5 }
  , { name: '', company: '', title: '10', value: 10 }
];

export const subGradeOptionsMap: Array<Grade> = subgradeCategories.reduce((acc, curr) => {
  acc.push({ name: '', company: '', value: +curr, title: curr, children: subGradeOptions.map(i => ({ ...i, value: i.value + +curr })) });
  return acc;
}, [] as Grade[]);



export const gradesList: Array<Grade> = [
  {
    company: 'other',
    name: 'other',
    value: 1,
    label: '(Poor)',
    title: '1',
    children: [{
      company: 'other',
      name: 'other',
      value: 1.5,
      label: '(Poor)',
      title: '1.5',
    }]
  },
  {
    company: 'other',
    name: 'other',
    value: 2,
    label: '(Fair)',
    title: '2',
    children: [{
      company: 'other',
      name: 'other',
      value: 2.5,
      label: '(Fair)',
      title: '2.5',
    }]
  },
  {
    company: 'other',
    name: 'other',
    value: 3,
    label: '(VG)',
    title: '3',
    children: [{
      company: 'other',
      name: 'other',
      value: 3.5,
      label: '(VG)',
      title: '3.5',
    }]
  },
  {
    company: 'other',
    name: 'other',
    value: 4,
    label: '(VG-EX)',
    title: '4',
    children: [{
      company: 'other',
      name: 'other',
      value: 4.5,
      label: '(VG-EX)',
      title: '4.5',
    }]
  },
  {
    company: 'other',
    name: 'other',
    value: 5,
    label: '(EX)',
    title: '5',
    children: [{
      company: 'other',
      name: 'other',
      value: 5.5,
      label: '(EX)',
      title: '5.5',
    }]
  },
  {
    company: 'other',
    name: 'other',
    value: 6,
    label: '(EX-NM)',
    title: '6',
    children: [{
      company: 'other',
      name: 'other',
      value: 6.5,
      label: '(EX-NM)',
      title: '6.5',
    }]
  },
  {
    company: 'other',
    name: 'other',
    value: 7,
    label: '(NM)',
    title: '7',
    children: [{
      company: 'other',
      name: 'other',
      value: 7.4,
      label: '(NM)',
      title: '7.5',
    }]
  },
  {
    company: 'other',
    name: 'other',
    value: 8,
    label: '(NM-Mint)',
    title: '8',
    children: [{
      company: 'other',
      name: 'other',
      value: 8.5,
      label: '(NM-Mint)',
      title: '8.5',
    }]
  },
  {
    company: 'other',
    name: 'other',
    value: 9,
    label: '(Mint)',
    title: '9',
    children: [{
      company: 'other',
      name: 'other',
      value: 9.5,
      label: '(Mint)',
      title: '9.5',
    }]
  },
  {
    company: 'other',
    name: 'other',
    value: 10,
    label: '(Mint)',
    title: '10',
  },
  {
    company: 'bgs',
    description: ' A very poorly handled collectible with a heavy accumulation of major defects.',
    name: 'bgs',
    value: 1,
    title: '1',
    label: '(Poor)',
    children: [{
      company: 'bgs',
      name: 'bgs',
      description: ' A collectible that shows extensive evidence of handling with a heavy accumulation of major defects.',
      value: 1.5,
      title: '1.5',
      label: '(Poor)'
    }]
  },
  {
    company: 'bgs',
    name: 'bgs',
    value: 2,
    description: ' A collectible that shows extensive evidence of handling with numerous moderate-to-major defects.',
    title: '2',
    label: '(Good)',
    children: [{
      company: 'bgs',
      name: 'bgs',
      value: 2.5,
      title: '2.5',
      label: '(Good)',
      description: ' A collectible that shows extensive evidence of handling with multiple moderate-to-major defects.',
    }]
  },
  {
    company: 'bgs',
    name: 'bgs',
    value: 3,
    title: '3',
    label: '(VG)',
    children: [{
      company: 'bgs',
      description: ' A collectible that shows significant evidence of handling with several moderate-to-major defects.',
      name: 'bgs',
      value: 3.5,
      title: '3.5',
      label: '(VG)'
    }]
  },
  {
    description: ' A collectible that shows significant evidence of handling with several moderate-to-major defects.',
    company: 'bgs',
    name: 'bgs',
    value: 4,
    title: '4',
    label: '(VG-EX)',
    children: [{
      company: 'bgs',
      name: 'bgs',
      value: 4.5,
      description: ' A below-average collectible with multiple moderate defects.',
      title: '4.5',
      label: '(VG-EX)'
    }]
  },
  {
    company: 'bgs',
    name: 'bgs',
    description: ' A slightly below-average collectible with multiple moderate defects.',
    value: 5,
    title: '5',
    label: '(EX)',
    children: [{
      company: 'bgs',
      name: 'bgs',
      value: 5.5,
      title: '5.5',
      label: '(EX)',
      description: 'An average collectible with several moderate defects.',
    }]
  },
  {
    company: 'bgs',
    name: 'bgs',
    value: 6,
    title: '6',
    description: ' An average collectible with several moderate defects.',
    label: '(EX-Mint)',
    children: [{
      company: 'bgs',
      name: 'bgs',
      value: 6.5,
      title: '6.5',
      label: '(EX-Mint)',
      description: ' A slightly above-average collectible with a major defect and some smaller defects, or a significant accumulation of small defects.',
    }]
  },
  {
    company: 'bgs',
    name: 'bgs',
    value: 7,
    title: '7',
    label: '(Near-Mint)',
    children: [{
      description: ' An above-average collectible with a major defect and some smaller defects, or a significant accumulation of small defects.',
      company: 'bgs',
      name: 'bgs',
      value: 7.5,
      title: '7.5',
      label: '(Near-Mint)'
    }]
  },

  {
    company: 'bgs',
    description: ' An above-average collectible with a major defect or an accumulation of small defects.',
    name: 'bgs',
    value: 8,
    title: '8',
    label: '(NM-Mint)',
    children: [{
      company: 'bgs',
      name: 'bgs',
      description: ' An above-average collectible with a major defect or an accumulation of small defects.',
      value: 8.5,
      title: '8.5',
      label: '(NM-Mint)',
    }]
  },
  {
    company: 'bgs',
    name: 'bgs',
    value: 9,
    description: ' An attractive collectible with a moderate defect or an accumulation of small defects.',
    title: '9',
    label: '(Mint)',
    children: [
      {
        company: 'bgs',
        name: 'bgs',
        value: 9.5,
        title: '9.5',
        label: '(Gem Mint)',
        description: ' An attractive collectible with a moderate defect or a number of small defects.',
      },
    ],
  },
  {
    company: 'bgs',
    name: 'bgs',
    value: 10,
    title: '10',
    label: 'Pristine',
    description: 'The highest grade assigned. The collectible must have no evidence of any manufacturing or handling defects.',
    children: [
      {
        company: 'bgs',
        name: 'bgs',
        value: 10.5,
        title: '10',
        label: '(Black Label)',
        description: 'The highest grade assigned. The collectible must have no evidence of any manufacturing or handling defects.',
      },
    ],
  },

  {
    title: '1',
    value: 1,
    label: '(Poor)',
    company: 'psa',
    name: 'psa',
    description: 'The same basic criteria (as for full tickets) apply to stubs, with the additional tearing/removal factor of the "audit stub" (by the gate attendant) or, in some cases, a "fan stub." The shape and severity of the tear/removal of the stub, with all other condition qualities being equal, may affect the final grade of a stub. As expected, the more severe and less deFaird the tear/removal, the lower the grade of the stub. In some cases, no excess tearing (beyond the acceptable limits for a stub) will be allowed within a particular grade. For example, a PSA Gem Mint 10 "fan stub" may not exhibit any evidence of excess tearing at all. Submitters will have the option of choosing not to have a grade assigned to the tickets and, instead, merely have the tickets authenticated and encapsulated. Those tickets will be labeled "AUTHENTIC." Graders also reserve the right, based on eye appeal, not to render a grade on a severely damaged ticket stub and, instead, apply the "AUTHENTIC" label. That way, the ticket is authenticated and protected in the PSA holder – it simply is void of a specific grade.',
    children: [{
      title: '1.5',
      value: 1.5,
      label: '(Fair)',
      company: 'psa',
      name: 'psa',
      description: 'A PSA PR-FR 1 will appear obviously abused: the ticket\'s corners and edges exhibit pronounced and extreme wear. Multiple creases, scratches, non-game related punctures and extreme warping may be present. Heavy staining, paper loss, fading and numerous print defects may be present.',
    }],
  },
  {
    title: '2',
    value: 2,
    label: '(Good)',
    company: 'psa',
    name: 'psa',
    description: 'A PSA Good 2 must be close in nature to a VG 3, but up to two of the following additional defects may be present: two or more medium-to-heavy surface creases may be present, and this ticket may also show noticeable warping. Up to two folds or full creases may exist, and each one may extend or cross the entire ticket. Multiple and deep surface scratching may exist. Three non-game related punctures or holes may be present. The original gloss may be entirely absent. Defects such as general wear, print defects, staining, paper loss and fading of original ink may be slightly more severe.',
    children: [{
      title: '2.5',
      value: 2.5,
      label: '(Good)',
      company: 'psa',
      name: 'psa',
      description: 'A PSA Good 2 must be close in nature to a VG 3, but up to two of the following additional defects may be present: two or more medium-to-heavy surface creases may be present, and this ticket may also show noticeable warping. Up to two folds or full creases may exist, and each one may extend or cross the entire ticket. Multiple and deep surface scratching may exist. Three non-game related punctures or holes may be present. The original gloss may be entirely absent. Defects such as general wear, print defects, staining, paper loss and fading of original ink may be slightly more severe.',
    }]
  },

  {
    title: '3',
    value: 3,
    label: '(VG)',
    company: 'psa',
    name: 'psa',
    description: 'Quality must be close in nature to a VG-EX4, but up to two of the following additional defects may be present: All of the ticket\'s corners, and in some instances the ticket\'s edges, may exhibit noticeable to pronounced wear. The surface quality may be subject to three or more light-to-medium surface creases; this ticket may also show some warping. A fold or full crease may exist and extend or cross over the entire ticket. Several surface scratches may exist. One or two non-game related punctures or holes may be present. Medium-to-heavy staining, and/or loss of print in one to two areas, on the front or back, may exist. Discoloration as in "yellowing" or fading of original ink color or very slight darkening in the case of a thermal-type paper stock ticket, over most or all of the front and/or back of the ticket may be observed. Original gloss may be entirely absent. Some surface paper loss may be visible on the front or back of the ticket. Defects such as general wear, print defects, and loss of original gloss may be slightly more severe.',
    children: [{
      title: '3.5',
      value: 3.5,
      label: '(VG)',
      company: 'psa',
      name: 'psa',
      description: 'Quality must be close in nature to a VG-EX4, but up to two of the following additional defects may be present: All of the ticket\'s corners, and in some instances the ticket\'s edges, may exhibit noticeable to pronounced wear. The surface quality may be subject to three or more light-to-medium surface creases; this ticket may also show some warping. A fold or full crease may exist and extend or cross over the entire ticket. Several surface scratches may exist. One or two non-game related punctures or holes may be present. Medium-to-heavy staining, and/or loss of print in one to two areas, on the front or back, may exist. Discoloration as in "yellowing" or fading of original ink color or very slight darkening in the case of a thermal-type paper stock ticket, over most or all of the front and/or back of the ticket may be observed. Original gloss may be entirely absent. Some surface paper loss may be visible on the front or back of the ticket. Defects such as general wear, print defects, and loss of original gloss may be slightly more severe.',
    }]
  },

  {
    title: '4',
    value: 4,
    label: '(VG-EX)',
    company: 'psa',
    name: 'psa',
    description: 'A ticket of this quality will appear to be an EX 5 at first glance, but up to two of the following additional defects may be present: Two light-to-medium surface creases may be present. A fold or full crease that does not cross the entire ticket may be present. The ticket may show some obvious, mid-range to dark staining in a limited area on the front or back. Defects such as general wear; print defects, loss of original gloss and paper loss on the reverse may be slightly more severe.',
    children: [{
      title: '4.5',
      value: 4.5,
      label: '(VG-EX)',
      company: 'psa',
      name: 'psa',
      description: 'A ticket of this quality will appear to be an EX 5 at first glance, but up to two of the following additional defects may be present: Two light-to-medium surface creases may be present. A fold or full crease that does not cross the entire ticket may be present. The ticket may show some obvious, mid-range to dark staining in a limited area on the front or back. Defects such as general wear; print defects, loss of original gloss and paper loss on the reverse may be slightly more severe.',
    }]
  },
  {
    title: '5',
    value: 5,
    label: '(EX)',
    company: 'psa',
    name: 'psa',
    description: 'A ticket of this quality may appear to be an EX-MT6 at first glance, but up to two of the following additional defects may be present: Fraying may be present on up to all four corners. One light-to-medium surface crease may be present. The ticket may show some obvious staining in a limited area on the front or back. Two to three surface abrasions may be present. One or two minor printing defects may be noted, such as a graphic element or text focus that is slightly out of register. Visible loss of original gloss may be acceptable. Some surface paper loss, resulting from prior gluing or taping of the ticket to an album page, for example, may be acceptable and is restricted to the back of the ticket.',
    children: [{
      title: '5.5',
      value: 5.5,
      label: '(EX)',
      company: 'psa',
      name: 'psa',
      description: 'A ticket of this quality may appear to be an EX-MT6 at first glance, but up to two of the following additional defects may be present: Fraying may be present on up to all four corners. One light-to-medium surface crease may be present. The ticket may show some obvious staining in a limited area on the front or back. Two to three surface abrasions may be present. One or two minor printing defects may be noted, such as a graphic element or text focus that is slightly out of register. Visible loss of original gloss may be acceptable. Some surface paper loss, resulting from prior gluing or taping of the ticket to an album page, for example, may be acceptable and is restricted to the back of the ticket.',
    }]
  },
  {
    title: '6',
    value: 6,
    label: '(EX-Mint)',
    company: 'psa',
    name: 'psa',
    description: 'Quality must be very close in nature to a NM 7, but up to two of the following allowances may be made: Fraying may be present on up to three corners. A very light surface crease may be present. Two or three light surface abrasions may be present. A minor printing defect is acceptable, such as "out of register" focus of graphic or text elements. Minor loss of original gloss may be acceptable. Centering, as in an EX-MT 6, must be 70/30 or better, on the front and back.',
    children: [{
      title: '6.5',
      value: 6.5,
      label: '(EX-Mint)',
      company: 'psa',
      name: 'psa',
      description: 'Quality must be very close in nature to a NM 7, but up to two of the following allowances may be made: Fraying may be present on up to three corners. A very light surface crease may be present. Two or three light surface abrasions may be present. A minor printing defect is acceptable, such as "out of register" focus of graphic or text elements. Minor loss of original gloss may be acceptable. Centering, as in an EX-MT 6, must be 70/30 or better, on the front and back.',
    }]
  },
  {
    title: '7',
    value: 7,
    label: '(NM)',
    company: 'psa',
    name: 'psa',
    description: 'A ticket of this quality will appear to be a NM-MT 8 at first glance but one of the following additional defects may be present. Beyond light corner "touches" evident at up to all four corners, minor fraying may be present at up to two corners as long as the integrity of the each corner remains intact. The ticket may show slight staining in a limited area. Two light surface abrasions may be present. Here, a minor printing defect may be acceptable. Graphic or text focus may be slightly out of register. A very slight loss of original gloss may be acceptable. Centering must fall within approximately 65/35 to 70/30 or better on the front and back',
    children: [{
      title: '7.5',
      value: 7.5,
      label: '(NM)',
      company: 'psa',
      name: 'psa',
      description: 'A ticket of this quality will appear to be a NM-MT 8 at first glance but one of the following additional defects may be present. Beyond light corner "touches" evident at up to all four corners, minor fraying may be present at up to two corners as long as the integrity of the each corner remains intact. The ticket may show slight staining in a limited area. Two light surface abrasions may be present. Here, a minor printing defect may be acceptable. Graphic or text focus may be slightly out of register. A very slight loss of original gloss may be acceptable. Centering must fall within approximately 65/35 to 70/30 or better on the front and back',
    }]
  },
  {
    title: '8',
    value: 8,
    label: '(NM-Mint)',
    company: 'psa',
    name: 'psa',
    description: 'A ticket of this quality will appear to be a Mint 9 at first glance but one of the following additional defects may be present. Very light "touches" at two corners, apparent to the naked eye, would be acceptable under this standard. A very slight surface abrasion or evidence of modest surface damage, on the front or back, may be acceptable if limited. For instance, a faint impression from a paper clip would be an example of this type of acceptable defect. "Medium to Medium/Dark" printing of crucial game information is required. Centering must fall within approximately 60/40 to 65/35 or better on the front and back',
    children: [{
      title: '8.5',
      value: 8.5,
      label: '(NM-Mint)',
      company: 'psa',
      name: 'psa',
      description: 'A ticket of this quality will appear to be a Mint 9 at first glance but one of the following additional defects may be present. Very light "touches" at two corners, apparent to the naked eye, would be acceptable under this standard. A very slight surface abrasion or evidence of modest surface damage, on the front or back, may be acceptable if limited. For instance, a faint impression from a paper clip would be an example of this type of acceptable defect. "Medium to Medium/Dark" printing of crucial game information is required. Centering must fall within approximately 60/40 to 65/35 or better on the front and back',
    }]
  },
  {
    title: '9',
    value: 9,
    label: '(Mint)',
    company: 'psa',
    name: 'psa',
    description: 'Quality must be very close in nature to a Gem Mint 10 but the following allowances may be made. Very light "touches" at one of the corners may be acceptable so long as the corner integrity remains intact. A slight surface "dimple" may be allowed if extremely limited.',
    children: [{
      title: '9.5',
      value: 9.5,
      label: '(Mint)',
      company: 'psa',
      name: 'psa',
      description: 'Quality must be very close in nature to a Gem Mint 10 but the following allowances may be made. Very light "touches" at one of the corners may be acceptable so long as the corner integrity remains intact. A slight surface "dimple" may be allowed if extremely limited.',
    }]
  },
  {
    title: '10',
    value: 10,
    label: '(Gem Mint)',
    company: 'psa',
    name: 'psa',
    description: 'Attributes include four sharp corners, centering of approximately 55/45 to 60/40 or better on the front and back of the ticket, sharp focus and virtually full original gloss. The ticket must be free of staining; however, an allowance may be made for a slight printing imperfection, if it doesn\'t impair the overall appeal of the ticket. No punctures or holes may be present with the exception of those that are obviously required on the ticket. Ticket printing quality of crucial game information must be in the "Medium/Dark to Dark" range in order to qualify for this grade. In other words, noticeable fading may prevent a ticket from reaching Gem Mint status',
    children: [{
      title: '10.5',
      value: 10.5,
      label: '(Gem Mint)',
      company: 'psa',
      name: 'psa',
      description: 'Attributes include four sharp corners, centering of approximately 55/45 to 60/40 or better on the front and back of the ticket, sharp focus and virtually full original gloss. The ticket must be free of staining; however, an allowance may be made for a slight printing imperfection, if it doesn\'t impair the overall appeal of the ticket. No punctures or holes may be present with the exception of those that are obviously required on the ticket. Ticket printing quality of crucial game information must be in the "Medium/Dark to Dark" range in order to qualify for this grade. In other words, noticeable fading may prevent a ticket from reaching Gem Mint status',
    }]
  },
  {
    company: 'cgc',
    name: 'cgc',
    title: '1',
    label: '(Fair)',
    value: 1,
    description: ' A very poorly handled collectible with a heavy accumulation of major defects.',
    children: [{
      company: 'cgc',
      name: 'cgc',
      title: '1.5',
      label: '(Fair-Good)',
      value: 1.5,
      description: ' A collectible that shows extensive evidence of handling with a heavy accumulation of major defects.',
    }],
  },
  {
    company: 'cgc',
    name: 'cgc',
    title: '2',
    label: '(Good)',
    value: 2,
    description: ' A collectible that shows extensive evidence of handling with numerous moderate-to-major defects.',
    children: [
      {
        company: 'cgc',
        name: 'cgc',
        title: 'G',
        label: '(Good Plus)',
        value: 2.5,
        description: ' A collectible that shows extensive evidence of handling with multiple moderate-to-major defects.',
      },
    ],
  },
  {
    company: 'cgc',
    name: 'cgc',
    title: '3',
    label: '(Good-VG)',
    value: 3,
    description: ' A collectible that shows significant evidence of handling with several moderate-to-major defects.',
    children: [{
      company: 'cgc',
      name: 'cgc',
      title: '3.5',
      label: '(Good-VG)',
      value: 3.5,
      description: ' A collectible that shows significant evidence of handling with several moderate-to-major defects.',
    },]
  },
  {
    company: 'cgc',
    name: 'cgc',
    title: '4',
    label: '(VG)',
    value: 4,
    description: ' A below-average collectible with multiple moderate defects.', children: [
      {
        company: 'cgc',
        name: 'cgc',
        title: '4.5',
        label: '(VG Plus)',
        value: 4.5,
        description: ' A slightly below-average collectible with multiple moderate defects.',
      },
    ],
  },
  {
    company: 'cgc',
    name: 'cgc',
    title: '5',
    label: '(VG-Fair)',
    value: 5,
    description: 'An average collectible with several moderate defects.', children: [{
      company: 'cgc',
      name: 'cgc',
      title: '5.5',
      label: '(VG-Fair)',
      value: 5,
      description: ' An average collectible with several moderate defects.',
    }]
  },
  {
    company: 'cgc',
    name: 'cgc',
    title: '6',
    label: '(Fair)',
    value: 6,
    description: ' A slightly above-average collectible with a major defect and some smaller defects, or a significant accumulation of small defects.',
    children: [
      {
        company: 'cgc',
        name: 'cgc',
        title: '6.5',
        label: '(Fair Plus)',
        value: 6.5,
        description: ' An above-average collectible with a major defect and some smaller defects, or a significant accumulation of small defects.',
      },
    ],
  },
  {
    company: 'cgc',
    name: 'cgc',
    title: '7',
    label: '(F-FV)',
    value: 7,
    description: ' An above-average collectible with a major defect or an accumulation of small defects.',
    children: [{
      company: 'cgc',
      name: 'cgc',
      title: '7.5',
      label: '(F-FV)',
      value: 7.5,
      description: ' An above-average collectible with a major defect or an accumulation of small defects.'
    }]
  },
  {
    company: 'cgc',
    name: 'cgc',
    title: '8',
    label: '(FV)',
    value: 8,
    description: ' An attractive collectible with a moderate defect or an accumulation of small defects.',
    children: [

      {
        company: 'cgc',
        name: 'cgc',
        title: '8.5',
        label: '(FV Plus)',
        value: 8.5,
        description: ' An attractive collectible with a moderate defect or a number of small defects.',
      },
    ],
  },
  {
    company: 'cgc',
    name: 'cgc',
    title: '9',
    label: '(VF-NM)',
    value: 9,
    description: ' A very well-preserved collectible with Good eye appeal. There will be a number of minor handling and/or manufacturing defects.',
    children: [
      {
        company: 'cgc',
        name: 'cgc',
        title: '9.5',
        label: '(NM)',
        value: 9.5,
        description: ' A very well-preserved collectible with minor wear and small manufacturing or handling defects.',
      },
    ]
  },
  {
    company: 'cgc',
    name: 'cgc',
    title: '10',
    label: '(Gem Mint)',
    value: 10,
    description: 'The highest grade assigned. The collectible must have no evidence of any manufacturing or handling defects.',
    children: [{
      company: 'cgc',
      name: 'cgc',
      title: '10',
      label: '(Gem Mint)',
      value: 10.5,
      description: 'The highest grade assigned. The collectible must have no evidence of any manufacturing or handling defects.'
    }],
  },

  {
    title: '1',
    company: 'sgc',
    name: 'sgc',
    active: false,
    value: 1,
    label: '(POOR)',
    description:
      'This card usually exhibits many of these characteristics: heavy print spots, heavy crease(s), pinhole(s), color or focus imperfections or discoloration, surface scuffing or tears, rounded and/or fraying corners, ink or pencil marking(s), and lack of all or some original gloss, small portions of the card may be missing.',
    children: [
      {
        title: '1.5',
        company: 'sgc',
        name: 'sgc',
        active: false,
        value: 1.5,
        label: '(FAIR)',
        description:
          'Centered 90/10 or better. This card usually exhibits several of these characteristics: heavy print spots, heavy crease(s), pinhole(s), color or focus imperfections or discoloration, surface scuffing or tears, rounded and/or fraying corners, ink or pencil marking(s), and lack of all or some original gloss, a small portion of the card may be missing.',
      },
    ],
  },

  {
    title: '2',
    company: 'sgc',
    name: 'sgc',
    active: false,
    value: 2,
    label: '(Good)',
    description:
      'Centered 90/10 or better. This card usually exhibits one or more of these characteristics: heavy print spots, heavy crease(s), pinhole(s), color or focus imperfections or discoloration, surface scuffing or tear, rounded and/or fraying corners, ink or pencil marking(s), and lack of all or some original gloss.',
    children: [
      {
        title: '2.5',
        company: 'sgc',
        name: 'sgc',
        active: false,
        value: 2.5,
        label: '(Good Plus)',
        description:
          'A Good card that exhibits high-end overall subtitle and eye appeal.',
      },
    ],
  },
  {
    title: '3',
    company: 'sgc',
    name: 'sgc',
    active: false,
    value: 3,
    label: '(VG)',
    description:
      '90/10 or better centering, corners more rounded--but not excessive, stronger creasing may exist. Poorer focus, registration, and discoloration, and staining are more noticeable.',
    children: [
      {
        title: '3.5',
        company: 'sgc',
        name: 'sgc',
        active: false,
        value: 3.5,
        label: '(VGPlus)',
        description:
          'A VG card that exhibits high-end overall subtitle and eye appeal.',
      },
    ],
  },
  {
    title: '4',
    company: 'sgc',
    name: 'sgc',
    active: false,
    value: 4,
    label: '(VG/EX)',
    description:
      '85/15 or better centering, corners are slightly rounded with modest surface wear. Light hairline crease may show on one or both sides. A light tear or surface break may exist.',
    children: [
      {
        title: '4.5',
        company: 'sgc',
        name: 'sgc',
        active: false,
        value: 4.5,
        label: '(VG/EXPlus)',
        description:
          'A VG/EX card that exhibits high-end overall subtitle and eye appeal.',
      },
    ],
  },
  {
    title: '5',
    company: 'sgc',
    name: 'sgc',
    active: false,
    value: 5,
    label: '(EX)',
    description:
      '80/20 or better centering, minor rounding or fuzzing of corners, roughness or chipping along edge (no layering), one VERY slight surface or "spider" crease may exist on one side of the card, gloss may be lost from surface with some scratching that does not detract from the aesthetics of the card.',
    children: [
      {
        title: '5.5',
        company: 'sgc',
        name: 'sgc',
        active: false,
        value: 5.5,
        label: '(EXPlus)',
        description:
          'An EX card that exhibits high-end overall subtitle and eye appeal.',
      },
    ],
  },
  {
    title: '6',
    company: 'sgc',
    name: 'sgc',
    active: false,
    value: 6,
    label: '(EX-NM)',
    description:
      '75/25 or better centering, slight fuzzing of corners may be evident, skewed cut may be more evident, focus or register may be off, and slight notching of edges may exist.',
    children: [
      {
        title: '6.5',
        company: 'sgc',
        name: 'sgc',
        active: false,
        value: 6.5,
        label: '(EX/Near MintPlus)',
        description:
          'An EX/Near Mint card that exhibits high-end overall subtitle and eye appeal.',
      },
    ],
  },
  {
    title: '7',
    company: 'sgc',
    name: 'sgc',
    active: false,
    value: 7,
    label: '(NM)',
    description:
      '70/30 or better centering, slight wear on some corners, minor scratching, some print spots or speckling, and print lines or refractor lines are acceptable. Card may exhibit a slightly skewed (diamond) cut.',
    children: [
      {
        title: '7.5',
        company: 'sgc',
        name: 'sgc',
        active: false,
        value: 7.5,
        label: '(NMPlus)',
        description:
          '70/30 or better centering, a few small flaws may exist upon close examination. A small flaw may be, but is not limited to: very minor wear on one corner, a gloss break or surface scratch, a print line or refractor line, a focus or color imperfection, or a print spot.',
      },
    ],
  },
  {
    title: '8',
    company: 'sgc',
    name: 'sgc',
    active: false,
    value: 8,
    label: '(NM-Mint)',
    description:
      '65/35 or better centering, corners sharp to the naked eye but may exhibit slight wear under closer examination. A few small flaws may exist upon close examination. A small flaw may be, but is not limited to: very minor wear on one corner, a gloss break or surface scratch, a print line or refractor line, a focus or color imperfection, or a print spot.',
    children: [
      {
        title: '8.5',
        company: 'sgc',
        name: 'sgc',
        active: false,
        value: 8.5,
        label: '(NM-MintPlus)',
        description:
          '65/35 or better centering, four sharp corners*. A few minor flaws may exist upon close examination. A minor flaw may be, but is not limited to: a slight nick to one corner, a small gloss break or surface scratch, a minor print line or minor refractor line, a minor focus or color imperfection, or a small print spot.',
      },
    ],
  },
  {
    title: '9',
    company: 'sgc',
    name: 'sgc',
    active: false,
    value: 9,
    label: '(Mint)',
    description:
      '60/40 or better centering, sharp focus and four sharp corners*. A minor flaw may exist upon close examination. A minor flaw may be, but is not limited to: a slight nick to one corner, a small gloss break or surface scratch, a minor print line or minor refractor line, a minor focus or color imperfection, or a small print spot.',
    children: [
      {
        title: '9.5',
        company: 'sgc',
        name: 'sgc',
        active: false,
        value: 9.5,
        label: '(MintPlus)',
        description:
          'Is a card that at first glance appears to be Gem Mint 10 upon close inspection it may have a tiny flaw(s) that keeps it from grading GEM Mint 10.',
      },
    ],
  },

  {
    title: '10',
    company: 'sgc',
    name: 'sgc',
    active: false,
    value: 10,
    label: '(Pristine)',
    description:
      'A "virtually flawless" card. 50/50 centering, crisp focus, four sharp corners*, free of stains, no breaks in surface gloss, no print or refractor lines, and no visible wear under magnification.',
    children: [
      {
        title: '10 Gem',
        company: 'sgc',
        name: 'sgc',
        active: false,
        value: 10.5,
        label: '(Gem)',
        description:
          '55/45 or better centering, sharp focus, four sharp corners*, free of stains, no breaks in surface gloss, no print or refractor lines, and no visible wear. A slight print spot visible under close scrutiny is allowable if it does not detract from the aesthetics of the card.',
      },
    ],
  },
];


export const selfAssessmentGrades: Grade[] = [
  {
    title: 'Damaged',
    value: 1,
    company: 'self-assess',
    name: SelfAssess,
    description:
      'Cards may have extreme edge wear, excessive corner wear, heavy scratching or scuffing, folds, creases or tears and or other damages that affect the structural integrity of the card.',
  },
  {
    title: 'Heavily Played',
    value: 2,
    company: 'self-assess',
    name: SelfAssess,
    description:
      'Heavily Played cards display a acute wear. Cards with less than a third of the surface being liquid damaged can be accepted. These cards can have one  instance of missing ink such as when removing a sticker and only part of the sticker comes away, along with major creasing, whitening and border wear if the card can still be sleeve playable. Vault Singles does not accept Heavily Played cards.',
  },

  {
    title: 'Moderately Played',
    value: 3,
    company: 'self-assess',
    name: SelfAssess,
    description:
      'Moderately Played cards can have border wear, corner wear, scratching or scuffing, creases or whitening or any combination of mild examples of these marks. It may have some form of a more major marking, such as creasing that doesnʼt affect card integrity, but not in combination with other issues such as scratches, scuffs or border/edge wear and not impacting a large area of the card.',
  },

  {
    title: 'Lightly Played',
    value: 4,
    company: 'self-assess',
    name: SelfAssess,
    description:
      'Lightly Played cards may have some minor border or corner wear, or even some light scuffs or scratches. Theres no major issues, like water damage, bends, or structural integrity problems.Noticeable imperfections are ok but none should be too severe or high in volume.The acceptable range of Lightly Played cards include both cards with a handful of minor imperfections and those with a few more noticeable imperfections.',
  },
  {
    title: 'Near Mint',
    value: 5,
    company: 'self-assess',
    name: SelfAssess,
    description:
      'Near mint cards have minimal or no wear from shuffling, play or handling and can have a nearly unmarked surface, crisp corners, and unblemished edges outside of a few minimal flaws. A Near Mint card may have a small nick or a few tiny scratches, but overall, they look unplayed. A Near Mint card will have no imperfections, or a few minor imperfections.',
  },
  {
    title: 'Mint',
    value: 6,
    company: 'self-assess',
    name: SelfAssess,
    description:
      'Zero Damage, this means,  corners, edges, fading, creases, scratches, focus, print quality colour qualirt etc. The card must be in perfect condition. A Mint card will have no imperfections.',
  }];

export const toGradeLabel = (value: number) => {
  const result = gradeLabels.find((grade) => +grade.value === +value)?.label;
  return result ?? value;
};