import { ReCaptchaAction } from '../../helpers/recaptcha';
import { IndexSearchResponse } from '../IAZSearch';
import { searchService } from '../service-config';
import { postAsync } from '../baseService';


const services = {
    listingAutoCompleteService: (searchQuery: string, filterString?: string) => {
        try {
            return postAsync<IndexSearchResponse, any>(
                searchService.listingAutocompleteURL,
                null,
                {
                    x_q_re: btoa(searchQuery || '*:*'),
                    x_q_f: btoa(filterString || '*:*')
                },
                ReCaptchaAction.api_listing_search,
                false
            );
        } catch (er) {
            // eslint-disable-next-line no-console
            console.error(er);
        }
    }

};


export const {

    listingAutoCompleteService
} = services;