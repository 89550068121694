import { useEffect } from 'react';
import { ListingStatusEnum } from '../../models/create-listing/iCardListing';
import { IListing } from '../../models/create-listing/iListing';
import { useAppDispatch, useAppSelector } from '../../hook';
import { logEvents } from '../../actions/logActions';
import {
    selectCard as SC,
    selectCurrentListing,
    selectLoadingStatus
} from '../../features/createListing/createListingSelectors';
import { useLocation, useNavigate } from 'react-router-dom';
import { ICard } from '../../features/createListing/models/iCard';
import { publishListing } from '../../features/listingSearch/searchActions';
import { setCurrentListingStep } from '../../features/createListing/store/createListingSlice';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import { StorageKey } from '../../stores/Storage';
import { useAuthHook } from '../../shared/hooks/useAuthHook';
import usePaymentService from '../../hooks/paymentService/usePaymentService';
import { useEffectOnce } from 'react-use';
import { useStateMech } from '../../features/createListing/StateMechanicContext';

export function useCreateListing() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const selectCard = useAppSelector<ICard | undefined>(SC);
    const location = useLocation();
    const { getOnboardingStatus } = usePaymentService();
    const { setModel, state, gotoState, moveNext, movePrevious } = useStateMech();
    const currentListing = useAppSelector(selectCurrentListing);
    const isLoading = useAppSelector(selectLoadingStatus);
    const { user } = useAuthHook();

    useSessionStorage(StorageKey.createListing, currentListing);

    const handleStep = (name: string) => () => {
        // eslint-disable-next-line no-debugger
        if (gotoState(name)) {
            dispatch(logEvents({ eventName: 'LISTING_GOTO', payload: selectCard }));
            navigate('' + name);
        }
    };


    const handleNext = async () => {

        setModel(currentListing);
        if (state?.name !== 'preview') {
            if (state?.next().name) {
                dispatch(setCurrentListingStep(state.next().name as string));
                dispatch(logEvents({ eventName: 'LISTING_NEXT', payload: selectCard }));
                navigate(state?.next().name ?? '/not-found');
            }
            moveNext();
        } else {
            onSubmit();
        }
    };

    const handleBack = async () => {
        setModel(currentListing);
        dispatch(setCurrentListingStep(state?.previous()?.name as string));
        navigate(state?.previous()?.name ?? '/not-found');
        dispatch(logEvents({ eventName: 'LISTING_BACK', payload: selectCard }));
        movePrevious();
    };

    const onSubmit = async () => {

        const { title, card } = currentListing;
        // eslint-disable-next-line no-extra-boolean-cast
        const t = encodeURIComponent(title ? title : `${card?.name}, ${card?.series}, ${card?.set}, ${card?.number}`);
        const listing = {
            ...currentListing,
            sellerAvatar: user?.picture,
            listingStatus: ListingStatusEnum.Published,
        } as IListing;

        if (currentListing) {
            const result = await dispatch(
                publishListing({ createListing: listing })
            );
            if (result.meta.requestStatus === 'fulfilled') {
                navigate(`/listing-complete/${listing.id}/${t}/${encodeURIComponent(listing.images?.front?.path || '')}/${listing.description}`);
            } else {
                navigate('/listing-failed');
            }

        }
    };


    useEffectOnce(() => {
        // if (currentListing?.canBePaid === undefined) {
        getOnboardingStatus();
        // }
    });

    useEffect(() => {

        setModel(currentListing);
    }, [currentListing]);


    useEffect(() => {
        const step = window.location.pathname.substring(
            location.pathname.lastIndexOf('/') + 1,
            100
        );
        gotoState(step);

    }, [location]);

    return {
        handleStep,
        handleNext,
        handleBack,
        onSubmit,
        currentListing,
        location,
        getOnboardingStatus,
        setModel,
        state,
        isLoading,
        gotoState,
        moveNext,
        movePrevious,
    };
}
