/* eslint-disable no-console */
import { useEffect, useRef } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from './stores/store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;





const address = 'wss://collectorspoke-websocket.azurewebsites.net/echo';


export const useWebSockets = () => {

    const socket = useRef<WebSocket | null>(null);

    useEffect(() => {
        const webSocket = new WebSocket(address);
        // webSocket.onopen = (event) => {
        //     // console.log('opened', event);
        // };

        // webSocket.onerror = (event: Event) => {
        //     // console.log('error', event);
        // };

        socket.current = webSocket;

        return () => {
            webSocket.close();
        };
    }, []);

};