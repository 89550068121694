import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
// import Collection from './Collections';
// import classes from './collection.module.scss';
// import { useAppDispatch } from 'src/hook';

const home = lazy(() => import('./Collections'));

export function CollectionRoutes() {
    return (
        <Route path="/collection" Component={home} />
    );

}

