import React from 'react';
import { Shipping } from '../../Shipping';




export function CardShippingPage() {


    return <Shipping />;
}
