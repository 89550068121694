/* eslint-disable no-console */
import React, { createContext, useContext, useEffect, useRef } from 'react';
import * as ReactDOMClient from 'react-dom/client';




interface ICommonMetaTagProps {
    title: string;
    description: string;
    keywords: Array<string | undefined>;
    canonicalUrl?: string;
    imageUrl: string;
    targetType: 'website' | 'product';
    structuredDataProps?: IStructuredDataProps;
    amount?: string;
    children?: React.ReactNode;
}


interface IStructuredDataProps {
    '@context': string;
    '@type': string;
    url?: string;
    name?: string;
    description?: string;
    image?: string;
    offers?: {
        '@type': string;
        price: string;
        priceCurrency: string;
        availability: string;
        url: string;
    };
    aggregateRating?: {
        '@type': string;
        ratingValue: string;
        reviewCount: string;
    };
    review?: {
        '@type': string;
    };
}

// export function HelmetWithCommonTags(props: ICommonMetaTagProps) {
//     return <Helmet title={props.title}>
//         {props.children}
//     </Helmet>;
// }



export interface ISEOContext {
    documentHead: HTMLHeadElement;
    domNode: HTMLDivElement;
    root?: ReactDOMClient.Root;
    render(children: React.ReactNode | React.ReactNode[]): void;
}


class SEOcontext implements ISEOContext {

    private _domNode: HTMLDivElement;
    private _root?: ReactDOMClient.Root;


    get domNode(): HTMLDivElement {
        return this._domNode;

    }

    get documentHead(): HTMLHeadElement {
        return document.head;
    }


    get root(): ReactDOMClient.Root {
        return this._root as ReactDOMClient.Root;
    }

    constructor () {

        // this._domNode = document.createDocumentFragment();
        this._domNode = document.createElement('div');
        this._root = ReactDOMClient.createRoot(this._domNode);
        this.render = this.render.bind(this);

    }

    public render(children: React.ReactNode | React.ReactNode[]) {
        this?._root?.render?.(children);
    }

}
const seoContext = new SEOcontext();

export const SEOContext = createContext<ISEOContext>(seoContext);

export function SEOProvider(props: { children?: React.ReactNode; position: number | 'start' | 'end'; }) {
    const context = useRef<ISEOContext>(seoContext);

    const Provider = SEOContext.Provider;




    return <Provider value={context.current} >
        {props.children}
    </Provider >;
}


export function CommonMetaTags(props: ICommonMetaTagProps) {

    const replaceWhiteSpaceRegex = /\s/g;
    const keywords = props.keywords.filter(k => k !== undefined).map(k => k?.toTitleCase().replace(replaceWhiteSpaceRegex, '-') ?? '').join(' ');

    const structuredData = JSON.stringify({
        '@context': 'https://schema.org',
        '@type': props.targetType,
        url: props.canonicalUrl,
        name: props.title,
        description: props.description,
        image: props.imageUrl,
        offers: {
            '@type': 'Offer',
            price: props.amount,
            priceCurrency: 'AUD',
            availability: 'https://schema.org/InStock',
            url: props.canonicalUrl

        }
    } as IStructuredDataProps);

    return <>
        <title key="title">{props.title}</title>
        <meta name="title" content={props.title ?? 'Midnight Merchant'} />
        <meta name="description" content={props.description} />
        <meta name="keywords" content={keywords} />
        <link rel="canonical" href={props.canonicalUrl ?? location.href} />

        {/* <!--FACEBOOK META */}
        <meta content="Midnight Merchant" property="og:title" />
        <meta property="og:site_name" content="Midnight Merchant" />
        <meta property="og:see_also" content={location.origin} />
        <meta content={location.href} property="og:url" />
        <meta content={props.imageUrl} property="og:image" />
        <meta content={props.description} property="og:description" />
        {/* <!--FACEBOOK END META-- > */}

        {/* < !--GOOGLE META-- > */}
        <meta itemProp="name" content="Midnight Merchant" />
        <meta itemProp="description" content={props.description} />
        <meta property="image" content={props.imageUrl} />
        {/* <!--GOOGLE END META-- > */}


        {/* < !--TWITTER META-- > */}
        <meta name="twitter:card" content={props.description} />
        <meta name="twitter:url" content={location.href} />
        <meta name="twitter:title" content={props.title} />
        <meta name="twitter:description" content={props.description} />
        <meta name="twitter:image" content={props.imageUrl} />
        {/* <!--Twitter END META-- >; */}


        {/* <!-- Google structured data --> */}
        <script type="application/ld+json">{structuredData}</script>

    </>;
}


export type HeaderTypes = HTMLMetaElement | HTMLLinkElement | HTMLScriptElement;



export function SEOTags(props: { children: React.ReactNode | React.ReactNode[], position?: number | 'end' | 'start'; }) {

    const context = useContext(SEOContext);


    useEffect(() => {
        context.render(props.children);

        const children = Array.from(context.domNode.children);

        document.querySelectorAll('[data-r-seo]').forEach(e => { e.remove(); });

        for (let i = 0; children.length > i; i++) {
            const child = children[i];
            if (child) {
                child.setAttribute('data-r-seo', '');
                child.setAttribute('id', `seo-tag-${i}`);

                appendToHead(child.cloneNode(true) as Element, i);
            }
        }

        function appendToHead(element: Element, offset: number) {

            if (props.position === 'end') {
                document.head.appendChild(element);
            }
            else if (props.position === 'start') {
                document.head.prepend(element);
            }
            else {
                const target = document.head.children[(props.position as number) + offset];
                document.head.insertBefore(element, target);
            }

        }

    }, [props.children]);








    // const [documentFragment, setTagFragment] = React.useState<DocumentFragment>({} as DocumentFragment);
    // useEffect(() => {

    //     if (!props.children) return;

    //     if (!domNode.current) {
    //         domNode.current = document.createDocumentFragment();
    //     }

    //     if (!root.current) {
    //         root.current = ReactDOMClient.createRoot(domNode.current);
    //     }

    //     // return () => {
    //     //     Array.from(document.querySelectorAll('[data-r-seo]')).forEach(e => {
    //     //         try {
    //     //             // e.remove();
    //     //         } catch (err) {
    //     //             // eslint-disable-next-line no-console
    //     //             console.info('Cannot remove SEO tags', err, e.id);
    //     //         }
    //     //     });
    //     // };
    // }, []);


    // useEffect(() => {


    //     if (props.children)
    //         root.current?.render(props.children);

    // }, [root.current]);



    // // insert seo tags 
    // useEffect(() => {

    //     const children = domNode.current?.children ?? [];

    //     if ((children.length) === 0 || !children || !domNode.current) return;


    //     function insertAt(position: number) {
    //         const target = documentHead.current.children[position];
    //         if (domNode.current)
    //             document.head.insertBefore(domNode.current, target);
    //     }


    //     for (let i = 0; i < children.length; i++) {
    //         children[i].setAttribute('data-r-seo', '');
    //         children[i].setAttribute('id', `seo-tag-${i}`);
    //     }

    //     insertAt(20);

    // }, [domNode, props]);

    // useEffect(() => {
    //     root.current?.render(props.children);

    // }, [props.children]);


    // useEffect(() => {
    //     const documentHead = useRef(document.head);
    //     const domNode = useRef<DocumentFragment>(document.createDocumentFragment());
    //     const root = useRef<ReactDOMClient.Root>();
    //     // root.current?.unmount();


    //     // if (!domNode.current) {
    //     //     // domNode.current = document.createDocumentFragment();
    //     // }
    //     if (!root.current) {
    //         root.current = ReactDOMClient.createRoot(domNode.current);
    //     }


    //     // console.log(domNode.current.children);

    //     // console.log(domNode.current.children.item(0));
    //     // const item = domNode.current.children.item(0);
    //     // const clone = domNode.current.cloneNode(true);


    //     // documentHead.current.prepend(domNode.current);

    //     // if (item)
    //     //     documentHead.current.appendChild(item);

    //     // function insertAt(position: number) {
    //     //     const target = documentHead.current.children[position];
    //     //     if (domNode.current)
    //     //         document.head.insertBefore(domNode.current, target);
    //     // }
    //     documentHead.current.prepend(domNode.current);


    // }, []);




    return null;
}

