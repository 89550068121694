import React, { useRef } from 'react';
import { useEffectOnce } from 'react-use';
import classes from '../../styles/address-search.module.scss';

window.mapInit = () => {
    // no op    
    // eslint-disable-next-line no-console
    console.warn('mapInit', 'no op');
};

const googleMapsApi = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAJIAOW58174QI8OP9_PdIJB1lRpHwwneA&libraries=places&callback=mapInit';

export interface AddressSearchResult {
    postcode?: string;
    country?: string;
    locality?: string;
    street?: string;
    streetNumber?: string;
    state?: string;
    fullAddress?: string;
    cityTown?: string;
    latitude?: number;
    longitude?: number;
    timeOffset?: number;
}


export const getFieldsFromGooglePlace = (place: google.maps.places.PlaceResult): AddressSearchResult => {
    const address: AddressSearchResult = {
        postcode: place.address_components?.find((v: any) => v.types.includes('postal_code'))?.long_name,
        country: place.address_components?.find((v: any) => v.types.includes('country'))?.long_name,
        locality: place.address_components?.find((v: any) => v.types.includes('locality'))?.long_name,
        cityTown: place.address_components?.find((v: any) => v.types.includes('administrative_area_level_2'))?.long_name,
        street: place.address_components?.find((v: any) => v.types.includes('route'))?.long_name,
        streetNumber: place.address_components?.find((v: any) => v.types.includes('street_number'))?.long_name,
        state: place.address_components?.find((v: any) => v.types.includes('administrative_area_level_1'))?.long_name,
        latitude: place.geometry?.location.lat(),
        longitude: place.geometry?.location.lng(),
        timeOffset: ((place.utc_offset_minutes ?? 0) / 60) // convert to hours
    };

    address.fullAddress = `${address.streetNumber} ${address.street}, ${address.locality}, ${address.state}, ${address.country}, ${address.postcode}`;
    return address;
};


export const AddressSearch = (props: {
    onSelected?: (value: AddressSearchResult) => void;
    value?: AddressSearchResult;
}) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const scriptRef = useRef<HTMLScriptElement>();
    const [selectedPlace, setSelectedPlace] = React.useState<AddressSearchResult | undefined>(props.value);
    useEffectOnce(() => {
        if (!scriptRef.current && !document.getElementById('googleApiScript')) {
            scriptRef.current = document.createElement('script');
            scriptRef.current.id = 'googleApiScript';
            scriptRef.current.src = googleMapsApi;
            document.head.insertBefore(scriptRef.current, document.head.firstChild);

            scriptRef.current.onload = (() => {
                const autocomplete = new google.maps.places.Autocomplete(inputRef.current as HTMLInputElement, {
                    fields: ['address_components', 'geometry', 'utc_offset'], types: ['address'], componentRestrictions: { country: 'au' }
                });

                autocomplete.addListener('place_changed', () => {
                    const place = autocomplete.getPlace();
                    const addressFields = getFieldsFromGooglePlace(place);
                    setSelectedPlace(addressFields);
                    props.onSelected?.(addressFields);

                });
            });

        }

        return () => {
            scriptRef.current?.remove();
        };
    });

    function clearInput() {

        setSelectedPlace(undefined);

        if (inputRef.current)
            inputRef.current.value = '';
    }

    const display = !!selectedPlace;

    return <>
        <span className={classes.selectedPlace} style={{ display: !display ? 'none' : 'initial' }}>
            <span>
                <a aria-label='link' href="#" onClick={clearInput}>{selectedPlace?.locality}, {selectedPlace?.country}, {selectedPlace?.postcode}</a>
            </span>
        </span>
        <div><input autoComplete="off" role="presentation" name="item-location" type="text" ref={inputRef} style={{ background: 'white', display: display ? 'none' : 'initial' }} className={classes.input} placeholder='Street Address' /></div>
    </>;


};