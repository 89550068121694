import React, { forwardRef, useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const CustomInputComponent = React.forwardRef<HTMLInputElement, any>((props: any, ref: any) => {
    const {
        component: Component,
        ...other
    } = props;

    return <Component {...other} ref={ref} />;
});

export const IInput = forwardRef((props: any, ref: React.ForwardedRef<unknown>) => {
    const [value, setValue] = React.useState(props.value ?? 0);
    const { ...other } = props;

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);


    const onChange = (e: any) => {
        // eslint-disable-next-line no-useless-escape
        e.target.value = e.target.value.replace(/[^\.\d]/gm, '');
        props.onChange?.(e);
    };

    const onBlur = (e: any) => {
        let n = e.target.value;
        try {
            n = new Intl.NumberFormat('en-AU', {
                minimumFractionDigits: 2,
                minimumIntegerDigits: 1,
            }).format(n);

            if (Number.isNaN(n)) {
                e.target.value = '';
            }
        } catch (error) {
            e.target.value = '#ERROR';
        }

        e.target.value = n;
        props.onBlur?.(n);
    };

    const onFocus = (e: any) => {
        // eslint-disable-next-line no-useless-escape
        e.target.value = e.target.value.replace(/\,/gm, '');
    };

    return <input  {...other} onBlur={onBlur} onFocus={onFocus} onChange={onChange} ref={ref} value={value} />;
});
