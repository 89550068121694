import React from 'react';
// import { SelectedFiltersPanel } from 'src/components/SelectedFiltersPanel';
import styles from '../../styles/App.module.scss';
import { ImagePanel } from 'src/components/ImagePanel';
import { InsightsSearchResults } from './InsightsSearchResults';

export function InsightsPage() {
    return (
        <>
            {/* <SelectedFiltersPanel className={styles.filterPanel} dialog /> */}
            <InsightsSearchResults />
            <ImagePanel className={styles.imagePanel} style={{ paddingTop: 200 }} />
        </>
    );
}


