import React, { Suspense } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { IOption } from '../shared/AutoComplete';
import { useAppDispatch } from '../../hook';
import { setListingSearchString } from '../../features/listingSearch/listingSearchSlice';
import { useListings } from '../../hooks/useListings';
import { mapAutocompleteItem, SearchBar } from './SearchBar';
import classes from './styles/ListingSearchBar.module.scss';
import useMedia from 'react-use/lib/useMedia';
import { RainbowButton } from '../shared/Button';

export interface IListingSearchBarProp {
    value?: string,
    options?: IOption[],
    placeholder?: string,
    searchTextPrompt?: string,
    className?: string,
    style?: React.CSSProperties,
    showSlidingActionBar?: () => void,
    onInputTextChanged?: (text: string) => void,
    onSelected?: (option: IOption) => void,
    onFocus?: () => void,
}

export function ListingSearchBar(props: IListingSearchBarProp) {
    const isMobile = useMedia('(max-width: 800px)');
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { autocomplete, autoCompleteResults, totalListingCount } = useListings(mapAutocompleteItem);


    const onInputTextChanged = async (text: string) => {
        await dispatch(setListingSearchString(text));
        autocomplete(text);
    };

    const onSelected = async (option: IOption) => {
        await dispatch(setListingSearchString(String(option.value)));
        autocomplete(option.label);
        navigate('/');
    };

    const showSlidingActionBar = () => {
        props.showSlidingActionBar?.();
    };

    return <listing-search-bar class={classes.root + ' ' + (props.className || '')} style={props.style}>
        <SearchBar
            {...props}
            value={props.value}
            onSelected={onSelected}
            onInputTextChanged={onInputTextChanged}
            searchTextPrompt='Search Midnight Market for listings'
            placeholder={`Search ${!totalListingCount ? ' . . . ' : totalListingCount} listings`}
            options={autoCompleteResults} />
        <div className={classes.searchBarBox}>
            {!isMobile ?
                <Link to="/create-listing/listing-type" style={{ all: 'unset' }}>
                    <Suspense fallback={<>waiting for button</>}>
                        <RainbowButton style={{ width: 120, height: 40 }}>Place Advert</RainbowButton>
                    </Suspense>
                </Link>
                : <Suspense fallback={<>waiting for search</>}>
                    {!isMobile &&
                        <RainbowButton
                            style={{ width: 40, height: 40 }}
                            onClick={() => showSlidingActionBar()} >+</RainbowButton>}
                </Suspense>}


        </div>

    </listing-search-bar>;

}
