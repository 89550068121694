import React, { useEffect, useState } from 'react';
import { Dialog } from './shared/dialog';

import { FilterPanel } from './FilterPanel';

import FAIcon from './shared/FAIcon';
import { useAppDispatch } from '../hook';
import { clearSelectedFilters, clearUncommittedFilters, setDirtyFilters } from '../features/listingSearch/listingSearchSlice';
import { searchListings } from '../features/listingSearch/searchActions';
import { Filter } from '../models/filter';
import { RainbowButton } from './shared/Button';
import { useTranslation } from 'react-i18next';


export function FilterDialog(props: {
  filters?: Filter;
  activeFilterGroup: Filter;
  activeFilterPath: string[];
  isLoadingFilters: boolean;
  label?: string;
  activeFilterChanged?: (activeFilterPath: string[]) => void;
  onClose: () => void;
  showDialog: boolean;
}) {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [showDialog, setShowDialog] = useState(props.showDialog);

  useEffect(() => {
    setShowDialog(props.showDialog);
  }, [props.showDialog]);

  const onApply = async () => {
    await dispatch(setDirtyFilters(false));
    dispatch(searchListings(0));
    props.onClose();
  };

  const onClear = () => {

    dispatch(clearSelectedFilters(props.activeFilterPath));
  };

  const navBack = () => {
    const filterLocation = [...props.activeFilterPath].splice(0, props.activeFilterPath.length - 2);
    props.activeFilterChanged?.(filterLocation);
  };

  const close = () => {
    dispatch(clearUncommittedFilters());
    props.onClose();
  };

  return (
    <Dialog show={showDialog} onClose={close}>
      <div
        style={{
          width: 445,
          padding: 20,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          zIndex: 100,
        }}
      >
        <FilterPanel {...props}></FilterPanel>
        <div
          style={{
            float: 'left',
            height: 50,
            padding: 36,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          {props?.activeFilterPath.length > 1 ? (
            <a
              style={{ color: 'white', textDecoration: 'none', cursor: 'pointer' }}
              onClick={() => {
                navBack();
              }}
            >
              <FAIcon uniCode="f104" style={{ fontSize: 20 }} />
            </a>
          ) : (
            <span></span>
          )}

          <a aria-label='link' onClick={() => onClear()} style={{ color: 'white', textDecoration: 'none', cursor: 'pointer', float: 'right', fontWeight: 700, fontSize: 12 }}>
            Clear Filter
          </a>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <RainbowButton
            style={{ height: 30, width: 100 }}
            onClick={onApply}
          >
            {t('filters.dialog.btnApply')}
          </RainbowButton>
        </div>
      </div>
    </Dialog>
  );
}
