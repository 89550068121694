import {
  CreateOrderActions,
  CreateOrderData,
  OnApproveActions,
  OnApproveData,
} from '@paypal/paypal-js/types/components/buttons';
import {
  approveOrderService,
  createOrderService,
  getClientTokenService,
  getMerchantIdService,
} from '../../services/payment-service/payment-service';
import { useAppDispatch } from '../../hook';
import { getSellerPaymentServiceOnboardingStatus } from '../../features/user/seller/sellerActions';
import { useAuthHook } from '../../shared/hooks/useAuthHook';

export { };

export type CreateOrderFunction = ((data: CreateOrderData, actions: CreateOrderActions) => Promise<string>) | undefined;

export type OnApproveFunction = ((data: OnApproveData, actions: OnApproveActions) => Promise<void>) | undefined;

const usePaymentService = () => {
  const { getAccessTokenSilently } = useAuthHook();
  const dispatch = useAppDispatch();

  const getClientToken = async () => {
    const response: any = await getClientTokenService();
    return response.client_token;
  };

  const getOnboardingStatus = async () => {
    const token = await getAccessTokenSilently();
    dispatch(getSellerPaymentServiceOnboardingStatus(token));
  };


  const getMerchantId = async (listingId: string) => {
    const response: any = await getMerchantIdService(listingId);
    return response;
  };

  const createOrder = async (listingId: string, countryCode: string) => {
    const response = await createOrderService({ listingId, countryCode });

    return response;
  };

  const approveOrder = async (orderId: string, listingId: string) => {
    return await approveOrderService(orderId, listingId);
  };

  return {
    createOrder,
    approveOrder,
    getClientToken,
    getMerchantId,
    getOnboardingStatus
  };
};

export default usePaymentService;
