import React from 'react';
import classes from './../../styles/listing-price.module.scss';
import { ReactComponent as Spinner } from '../../assets/svg/spinner1.svg';

export function PriceTiles(props: {
    newPrice: string;
    growthRate: string;
    label1: string;
    tooltip: string;
    desc1: string;
    desc2: string;
    isLoading?: boolean;
}) {
    return props.isLoading ? <Spinner style={{ fill: '#ffffff40', height: 30, paddingTop: 8 }} /> :
        <div className={classes.priceTileContainer}>
            <div className={classes.tooltip}>{props.tooltip} </div>
            <div className={classes.label1}>{props.label1}</div>
            <div className={classes.desc1}>{props.desc1}</div>
            <div className={classes.desc2}>{props.desc2}</div>
            <div className={classes.newPrice}>{props.newPrice}</div>
            <div className={classes.growthRate}>{props.growthRate}</div>
        </div>;


}
