import React from 'react';
import { Throbber } from '../../components/Throbber';
import { FileUpload } from './FileUpload';


export function ImagePicker(props: {
  title: string;
  placeholder?: string;
  name: string;
  onPreview?: (preview: File) => void;
  onRemoved?: (name: string, file: File) => void;
  selectedImageChanged?: (key: string, image: File) => void;
  uploadProgress: number;
  required?: boolean;
  value?: File;
  role?: string;
  objectUrl?: string;
  draftUrl?: string;
  busy: boolean;
  showTrash?: boolean;
}) {

  const createPreview = async (file: File) => {
    if (file) {
      props.onPreview?.(file);
      props.selectedImageChanged?.(props.name, file);
    }
  };


  return (
    <>
      <Throbber progress={props.uploadProgress} busy={props.busy}>
        <>
          <FileUpload
            showTrash={props.showTrash ?? false}
            value={props.value}
            objectUrl={props.objectUrl}
            name={props.name}
            title={props.title}
            placeholder={props.placeholder}
            fileTypes={['image/*', '.heic']}
            onSelected={createPreview}
            onRemoved={props.onRemoved}
            required={props.required}
            role={props.role}
          />
          <div
            style={{
              display: 'flex',
              columnGap: 13,
              width: '30%',
              justifyContent: 'space-evenly',
            }}
          ></div>
        </>
      </Throbber>
    </>
  );
}