import React, { useEffect, useState } from 'react';
import styles from '../../styles/SearchBar.module.scss';
import { Dialog } from '../shared/dialog';
import { Outlet } from 'react-router-dom';
import { AutoComplete, IOption } from '../shared/AutoComplete';
import { useDebounceCallback } from '../../hooks/useDebounce';
import { SEARCH_DEBOUNCE_TIME } from '../../config/appConfig';
import { IAutocompleteResult } from '../../models/iCardSearchState';

export const mapAutocompleteItem = (listings: Array<IAutocompleteResult>) => {
  return listings.map((i) => ({
    label: i.value,
    value: i.value,
    id: i.id,
    count: 0,
  }));
};

export interface ISearchBarProps {
  value?: string;
  options?: IOption[];
  placeholder?: string;
  searchTextPrompt?: string;
  className?: string;
  onInputTextChanged?: (text: string) => void;
  onSelected?: (option: IOption) => void;
  onFocus?: () => void;
  style?: React.CSSProperties;
}

export function SearchBar(props: ISearchBarProps) {
  const onInputChangeDebounce = useDebounceCallback(
    onInputTextChanged,
    +(SEARCH_DEBOUNCE_TIME || '200')
  );

  const [searchTerm, setSearchTerm] = useState<string>(props.value || '');

  useEffect(() => {
    setSearchTerm(props.value || '');
  }, [props.value]);

  const onSelected = async (option: IOption) => {
    setSearchTerm(option.label);
    props.onSelected && props.onSelected(option);
  };

  async function onInputTextChanged(value: string) {
    setSearchTerm(value);
    props?.onInputTextChanged && props.onInputTextChanged(value);
  }

  return (
    <div className={styles.root} style={props.style}>
      <AutoComplete
        onSelected={onSelected}
        placeholder={props.placeholder}
        selected={searchTerm}
        inputProps={{ onFocus: props.onFocus }}
        searchTextPrompt={props.searchTextPrompt}
        onInputTextChanged={onInputChangeDebounce}
        options={props.options || []}
      ></AutoComplete>
    </div>
  );
}

export function FilterPanelDialog(props: {
  show: boolean;
  onClose: () => void;
  children?: JSX.Element;
}) {
  return (
    <Dialog show={props.show} onClose={() => props.onClose()}>
      <Outlet />
    </Dialog>
  );
}
