import React from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hook';
import { IListing } from '../../../../models/create-listing/iListing';
import { Grade } from '../../../../models/create-listing/Grade';
import { CardGradingCompany } from '../../CardGradingCompany';
import { selectCurrentListing } from '../../createListingSelectors';
import { setCurrentListing } from '../../store/createListingSlice';


export function CardGradingCompanyPage() {
    const dispatch = useAppDispatch();

    const selected: IListing = useAppSelector(selectCurrentListing);

    const onSelected = (gradingCompany: string) => {
        const setListing = { ...selected, gradingCompany: gradingCompany };
        if (gradingCompany !== 'other' && setListing.grade) {
            setListing.grade.otherGradingCompany = '';
        }
        dispatch(setCurrentListing(setListing));
    };

    const onOtherSet = (other: string) => {
        dispatch(setCurrentListing({
            ...selected, grade: {
                grade: {
                    company: 'other',
                    name: other,
                } as Grade
            }
        }));
    };

    return <CardGradingCompany onSelected={onSelected} selected={selected.grade?.grade.company
    } onOtherSet={onOtherSet} other={selected.grade?.grade.name} />;
}
