import React from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import FAIcon from './components/shared/FAIcon';
import { setDirtyFilters } from './features/listingSearch/listingSearchSlice';
import { useAppDispatch } from './hook';
import { RainbowButton } from './components/shared/Button';
import { useTranslation } from 'react-i18next';
import { searchListings } from './features/listingSearch/searchActions';

export function FilterPage() {
  const navivate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const onApply = async () => {
    await dispatch(setDirtyFilters(false));
    dispatch(searchListings(0));
    // dispatch(searchListingsV2(0));
    navivate(-1);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div
        style={{
          float: 'left',
          height: 50,
          padding: 36,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Link to="" onClick={() => navivate(-1)}>
          <FAIcon uniCode="f104" style={{ fontSize: 20 }} />
        </Link>
        {/* <Link to={{}} onClick={() => dispatch(clearSelectedFilters)}>
          Clear
        </Link> */}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Outlet />
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 40 }}>

          <RainbowButton
            style={{ height: 30, width: 100 }}
            onClick={onApply}
          >
            {t('filters.dialog.btnApply')}
          </RainbowButton>
        </div>
      </div>
    </div >
  );
}
