import React, { useEffect } from 'react';
import classes from '../styles/throbber.module.scss';


const getCanvasGradient = (ctx: CanvasRenderingContext2D) => {
    const gradient = ctx.createLinearGradient(20, 0, 210, 0);
    gradient.addColorStop(0.1, '#F48265');
    gradient.addColorStop(0.2, '#FFF5B8');
    gradient.addColorStop(0.4, '#B8DCA2');
    gradient.addColorStop(0.5, '#B8E3EE');
    gradient.addColorStop(0.6, '#7F99D0');
    return gradient;
};


export function Throbber(props: { children: JSX.Element, progress: number, busy: boolean }): JSX.Element {
    const canvasRef = React.useRef<HTMLCanvasElement>(null);

    const canvasStyle = {
        width: '100%',
        height: 6,

    };

    useEffect(() => {
        if (canvasRef.current) {
            const ctx = canvasRef.current.getContext('2d') || new CanvasRenderingContext2D();

            ctx.fillStyle = getCanvasGradient(ctx);
            ctx.fillRect(0, 0, canvasRef.current.width * props.progress / 100, canvasRef.current.width);

            if (props.progress === 100) {
                ctx.fillStyle = '#1a2329';
                ctx.fillRect(0, 0, canvasRef.current.width, canvasRef.current.width);
            }
        }
    }, [props.progress]);

    return <div style={{ position: 'relative' }} className={props.busy ? classes.throbber : ''}>
        {props.children}
        <canvas style={{ ...canvasStyle }} ref={canvasRef}></canvas>
    </div>;
}
