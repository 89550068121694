import { useState } from 'react';
import { cardsAutoCompleteAction, searchCardsAction } from '../features';
import {
    selectAutocompleteResults,
    selectCardSearchParameters,
    selectCardSearchResults,
    selectIsLoadingCards,
    selectTotalCards,
    selectTotalSearchResults
}
    from '../features/cardSearch/cardSearchSelectors';

import { useAppDispatch, useAppSelector } from '../hook';
import { IAutocompleteResult } from '../models/iCardSearchState';


export function useCards(withMap?: (array: Array<IAutocompleteResult>) => any) {
    const dispatch = useAppDispatch();

    const totalCardCount = useAppSelector(selectTotalCards);
    const autoCompleteResults = useAppSelector(selectAutocompleteResults);
    const searchResults = useAppSelector(selectCardSearchResults);
    const totalSearchResults = useAppSelector(selectTotalSearchResults);

    const searchParmeters = useAppSelector(selectCardSearchParameters);
    const loading = useAppSelector(selectIsLoadingCards);
    const [currentPage, setCurrentPage] = useState<number>(0);


    const autocomplete = async (searchTerm: string) => {
        await dispatch(cardsAutoCompleteAction(searchTerm));
    };

    const getCards = async () => {
        await dispatch(searchCardsAction(searchParmeters));
    };

    return {
        totalCardCount,
        totalSearchResults,
        cards: searchResults,
        loading,
        searchParmeters,
        currentPage,
        getCards,
        setCurrentPage,
        autocomplete,
        autoCompleteResults: withMap ? withMap(autoCompleteResults) : autoCompleteResults
    };
}
