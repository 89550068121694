import React, { useEffect, useState } from 'react';
import classes from '../styles/mobile-footer.module.scss';
import { ReactComponent as Insight } from '../assets/svg/bottombar/data.svg';
import { ReactComponent as Logo } from '../assets/svg/avatar.svg';
import { ReactComponent as MarketPlace } from '../assets/svg/bottombar/marketplace.svg';
import { ReactComponent as PlusIcon } from '../assets/svg/bottombar/add.svg';
// import { ReactComponent as PlusIcon } from '../assets/svg/bottombar/CreateListing.svg';
import { ReactComponent as FAQ } from '../assets/svg/user-profile/faq.svg';
// import { ReactComponent as FAQ } from '../assets/svg/bottombar/FAQText.svg';
import { routeConfig } from '../routes/routeConfig';
import { Menu } from '@mui/icons-material/';
// import { ReactComponent as Bell } from '../assets/svg/bottombar/ActionsText.svg';

import { useAuthHook } from 'src/shared/hooks/useAuthHook';

export function MobileFooterBar(props: {
  onMenuToggle: () => void;
  toggleNotifications: () => void;
  toggleListingType: () => void;

}
) {

  const [
    isSettings
    , setIsSettings] = useState(false);
  const { user } = useAuthHook();

  useEffect(() => {
    setIsSettings(location.pathname.indexOf('user') > -1);
  }, [location.pathname]);

  return (
    <>
      <div className={classes.footer}>


        {isSettings && <TextLink text='Menu' onClick={props.onMenuToggle} Icon={() => <Menu />} />

          // : <a aria-label='link' style={{ fill: '#ffffff90' }} onClick={props.toggleNotifications}>
          //   <Bell />
          // </a>
        }
        <TextLink text="Market" to={routeConfig.searchResults.path} Icon={() => <MarketPlace style={{ height: 25 }} />} />
        <TextLink text="Insights" to={routeConfig.insights.path} Icon={() => <Insight style={{ height: 25 }} />} />
        <TextLink onClick={() => props.toggleListingType()} text="Place Ad" Icon={() => <PlusIcon style={{ height: 25 }} />} />
        <TextLink text="FAQ" to={routeConfig.faq.path} Icon={() => <FAQ style={{ height: 25 }} />} />

        {/* <a aria-label='link' href={routeConfig.searchResults.path} style={{ width: 40 }}>
          <MarketPlace />
        </a> */}
        {/* <a aria-label='link' style={{ fill: '#ffffff30' }} onClick={props.toggleListingType}>
          <PlusIcon className={classes.plusIcon} />
        </a> */}
        {/* <a aria-label='link' style={{ fill: '#ffffff30' }} href={routeConfig.faq.path}>
          <FAQ className={classes.plusIcon} />
        </a> */}

        <a aria-label='link' href={routeConfig.user.myListings}>
          {user?.picture
            ? < img src={user?.picture} alt='user' className={classes.avatar} />
            : <Logo style={{ height: 25 }} />
          }
        </a>
      </div >
    </>
  );
}

function TextLink(props: { to?: string; text?: string; Icon: (props: any) => JSX.Element; onClick?: () => void; }) {
  const { Icon } = props;

  const anchorStyle: React.CSSProperties = {
    width: 40,
    textDecoration: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    letterSpacing: 0.8,
    fontSize: 6,
  };

  return <a aria-label='link' href={props.to ?? '#'} style={anchorStyle} onClick={props.onClick}>
    <Icon style={{ height: 25, fill: 'white' }} />
    <span >{props.text}</span>
  </a>;
}


export function DiscordLink(props: { label?: string; }) {
  return <a aria-label='link' href={process.env.REACT_APP_DISCORD_URL} target='_blank' rel="noreferrer" >
    {props.label ? props.label : <FAQ />}
  </a>;
}
