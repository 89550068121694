import React, { useRef } from 'react';
import { load, ReCaptchaInstance } from 'recaptcha-v3';


export enum ReCaptchaAction {
  api_listing_search,
  api_card_search,
  api_get_cards,
  api_get_listing_categories,
  api_get,
  add_image,
  delete_image,
  delete_listing,
  create_listing,
  update_listing,
  get_filters,
  api_post,
  api_put,
  api_delete,
  api_patch,
  api_get_listing,
  api_get_listings,
  api_get_listings_count,
  api_get_listing_images,
  api_get_listing_image,
  api_get_listing_image_thumbnail,
  api_get_listing_image_original,
  api_get_listing_image_original_thumbnail,
  api_get_client_referer,
  api_user_rating,
  api_get_analytics_snapshot,
  api_get_analytics_summary,
  graphql
}
export let recaptcha: ReCaptchaInstance;

(async function loadRecaptcha() {
  const captchaSiteKey = process.env.REACT_APP_RECAPTCHA_V2;

  recaptcha = await load(captchaSiteKey ?? '');
})();

export async function getRecapToken(action: ReCaptchaAction) {

  // commented this out because it was causing recaptcha is failing on macos with cross domain tracking error --- apple f#ckers
  // if (process.env.NODE_ENV === 'development')
  return Promise.resolve('M0onC@ke1');

  return new Promise<string>((resolve, reject) => {
    try {
      let token = '';
      if (!recaptcha)
        setTimeout(async () => {
          // On a page refresh the getRecapToken is called again and the recaptcha is not ready yet so we wait for it to be ready.
          token = await recaptcha.execute(action.toString());
          return resolve(token);
        }, 1000);
      else return resolve(recaptcha.execute(action.toString()));
    } catch (ex) {
      reject(ex);
    }
  });
}


const recaptchaContext = React.createContext<React.MutableRefObject<Promise<ReCaptchaInstance> | null> | null>(null);

export function RecaptchaProvider(props: { children?: React.ReactNode; }) {
  const captchaSiteKey = process.env.REACT_APP_RECAPTCHA_V2;
  const Provider = recaptchaContext.Provider;

  if (captchaSiteKey === undefined) {
    // eslint-disable-next-line no-console
    console.warn('REACT_APP_RECAPTCHA_V2 is undefined');
    const Provider = recaptchaContext.Provider;
    return <Provider value={null}> {props.children} </Provider>;
  }
  const recap = useRef<Promise<ReCaptchaInstance> | null>(load(`${captchaSiteKey}`));
  return <Provider value={recap}>
    {props.children}
  </Provider>;
}

export function useRecaptcha() {
  const context = React.useContext(recaptchaContext);
  const [token, setToken] = React.useState<string>();

  if (context === undefined) {
    throw new Error('useRecaptcha must be used within a RecaptchaProvider');
  }

  const getToken = (action: ReCaptchaAction) => {
    return context?.current?.then((recap) => {
      return recap.execute(action.toString());

    }).then((token) => {
      setToken(token);
      return token;
    });

  };

  return {
    getToken,
    token
  };

}