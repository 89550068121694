import React from 'react';
import { useEffect } from 'react';
import { RainbowButton } from './Button';
import classes from '../../styles/sliding-action-bar.module.scss';

export function SlidingActionBar<TValueType>(props: {
    children?: JSX.Element;
    options?: {
        label: string,
        value: TValueType;
        onSelected?: (value: TValueType) => void;
        icon?: JSX.Element;
    }[];
    show: boolean;
    onClose: () => void;
}) {

    useEffect(() => {
        if (props.show) document.body.style.overflow = 'hidden';
        else document.body.style.overflow = 'initial';
    }, [props.show]);



    return props.show ? (
        <div
            className={classes.backdrop}
            onClick={(event) => {
                if (event.target === event.currentTarget) {
                    props.onClose();
                }
            }}>
            <div className={classes.buttonContent}>

                {props.options?.map((option, index) => (<RainbowButton className={classes.actionButton} key={index}
                    onClick={() => option.onSelected?.(option.value)}>
                    <span>{option.icon}</span><span>{option.label}</span>
                </RainbowButton>))
                }
            </div>
        </div>
    ) : (
        <></>
    );
}
