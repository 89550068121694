import React from 'react';
import { Heading } from '../../components/shared/Heading';
import { Instruction } from '../../components/shared/Instruction';
import { routeConfig } from '../../routes/routeConfig';
import { Link } from 'react-router-dom';

export function WIP(props: { text: string; }) {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Heading
        color="white"
        text={`We are knee deep in making the ${props.text.toTitleCase()} page happen ... `}
      />
      <br />
      <Instruction color="white">
        <div>
          <Link to={routeConfig.user.sellingHistory}>Selling History</Link>
        </div>
        <div>
          <Link to={routeConfig.user.sellerActions}>Seller Actions</Link>
        </div>
      </Instruction>
      <br />
      <Instruction>
        Delve into the depths of our{' '}
        <a aria-label='link' href={process.env.REACT_APP_DISCORD_URL} target="blank">
          Discord
        </a>
        server to find out more
      </Instruction>
    </div>
  );
}
