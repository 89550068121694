import React, { useState } from 'react';
import { MobileFooterBar } from './MobileFooterBar';
import { useAppDispatch } from '../hook';
import { SlidingActionBar } from '../components/shared/SlidingActionBar';
import { ListingType } from 'src/components/tiles/Tiles';
import { ReactComponent as SingleListingIcon } from '../assets/svg/actionSlider/mobile-list-single.svg';
import { ReactComponent as MixedBundleListingIcon } from '../assets/svg/actionSlider/mobile-list-bundle.svg';
import { ReactComponent as SealedListingIcon } from '../assets/svg/actionSlider/mobile-list-sealed.svg';
import { ReactComponent as Bell } from '../assets/svg/user-profile/bell.svg';
import { useNavigate } from 'react-router-dom';
import { routeConfig } from 'src/routes/routeConfig';
import { setCurrentListingStep, setListingType } from 'src/features';



// TODO: turn this into a component it doesnt need to be a hook
export const useMobileFooterBar = () => {

    const [showActionBar, setShowActionBar] = useState<boolean>(false);
    const [showNotificationbar, setShowNotificationBar] = useState<boolean>(false);
    const [mobileDrawerOpen, setMobileDrawerOpen] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();


    const onMenuToggle = () => {
        setMobileDrawerOpen(!mobileDrawerOpen);
    };


    const onListingTypeSelected = (listingType: ListingType) => {
        dispatch(setListingType(listingType));

        if (listingType === ListingType.singleCard) {
            dispatch(setCurrentListingStep('search'));
            navigate(routeConfig.createListing.search);
        }
        else if (listingType === ListingType.mixedBundle) {
            dispatch(setCurrentListingStep('title'));
            navigate(routeConfig.createListing.title);
        }
    };

    return {
        MobileFooterBar: <><MobileFooterBar
            onMenuToggle={onMenuToggle}
            toggleListingType={() => {
                setShowActionBar(!showActionBar);
                setShowNotificationBar(false);
            }}
            toggleNotifications={() => {
                setShowNotificationBar(!showNotificationbar);
                setShowActionBar(false);
            }} />
            <SlidingActionBar
                options={[
                    { icon: <SingleListingIcon />, label: 'List Single', value: ListingType.singleCard, onSelected: onListingTypeSelected },
                    { icon: <MixedBundleListingIcon />, label: 'List Mixed Bundle', value: ListingType.mixedBundle, onSelected: onListingTypeSelected },
                    { icon: <SealedListingIcon />, label: 'List Sealed Product', value: ListingType.sealedSingle, onSelected: onListingTypeSelected }
                ]}
                show={showActionBar}
                onClose={function (): void {
                    setShowActionBar(false);
                }} />
            <SlidingActionBar show={showNotificationbar}
                options={[
                    {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        icon: <Bell style={{ fill: 'white' }} />, label: 'Messages', value: 'messages', onSelected: (value) => {
                            navigate(routeConfig.user.messages);
                            setShowNotificationBar(false);
                        }
                    }
                ]}
                onClose={function (): void {
                    setShowNotificationBar(false);
                }} />

        </>,
        setShowActionBar,
        showActionBar,
        setShowNotificationBar,
        showNotificationbar,
        setMobileDrawerOpen,
        mobileDrawerOpen
    };


};
