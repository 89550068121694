import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IListingState } from '../../models/create-listing/iCardListing';
import { IListing } from '../../models/create-listing/iListing';
import { StorageKey } from '../../stores/Storage';
import { getListing } from './listingActions';
import { IAsyncPayload } from './IAsyncPayload';
import { IMarketDataSummary } from '../../services/listing-service/models/IMarketDataSummary';

const initialState: IListingState = {
    isLoading: false,
    isLoadingMarketData: false,
};

const listingSlice = createSlice({
    name: StorageKey.listing,
    initialState: initialState,
    reducers: {
        setListingLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setSelectedListing: (state, action: PayloadAction<IAsyncPayload<IListing>>) => {
            state.selectedListing = action.payload;
        },
        setIsLoadingMarketData: (state, action: PayloadAction<boolean>) => {
            state.isLoadingMarketData = action.payload;
        },
        setMarketAnalyticsGraphData: (state, action: PayloadAction<IAsyncPayload<any>>) => {
            state.marketAnalyticsGraphData = action.payload;
        },
        setMarketAnalyticsSummary: (state, action: PayloadAction<IAsyncPayload<IMarketDataSummary>>) => {
            state.marketAnalyticsSummary = action.payload;
        }
    },
    extraReducers: (builder) => {
        // move these handlers to middleware to conform to the standard
        builder.addCase(getListing.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getListing.rejected, (state) => {
            state.isLoading = false;
            state.selectedListing = undefined;
        });
        builder.addCase(getListing.fulfilled, (state, action: PayloadAction<IAsyncPayload<IListing>>) => {
            state.selectedListing = action.payload;
        });

    }
});

export const {
    setIsLoadingMarketData,
    setMarketAnalyticsGraphData,
    setMarketAnalyticsSummary,
    setSelectedListing
} = listingSlice.actions;

export default listingSlice.reducer;