import React, { useCallback, useEffect, useState } from 'react';
import classes from '../../styles/pay-now.module.scss';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import { useAppSelector } from '../../hook';
import { selectListing } from './listingSelectors';
import usePaymentService, {
  CreateOrderFunction,
  OnApproveFunction,
} from '../../hooks/paymentService/usePaymentService';

import { ErrorText, Heading } from '../../components/shared/Heading';
import { Information, Instruction } from '../../components/shared/Instruction';
import { toCurrency } from '../../utils/utilities';
import { LoadingWidget } from '../../components/shared/Loading';
import { useSnackbar } from '../../shared/hooks/snackbar';
import { error } from '../../actions/logActions';
import { useNavigate } from 'react-router-dom';
import { routeConfig } from '../../routes/routeConfig';
import { useShippingLocation } from 'src/components/shipping/ShippingCharge';

export function ListingBuyNow() {
  const listing = useAppSelector(selectListing);
  const [merchantId, setMerchantId] = useState<string>('');
  const [loadingMerchantId, setLoadingMerchantId] = useState<boolean>(false);
  const paymentService = usePaymentService();
  const navigate = useNavigate();
  const { snackbar, enqueue } = useSnackbar();
  const { shippingCharge, shippingChargeFormatted } = useShippingLocation(listing?.data?.country ?? '',
    listing?.data?.internationalShippingAmount ?? 0, listing?.data?.domesticShippingAmount ?? 0);

  const [errorText, setErrorText] = useState('Something went wrong with our paypal integration. please check back later or have a chat with us on Discord');
  const [paymentFailed, setPaymentFailed] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const createOrderHandler: CreateOrderFunction = async (data, actions) => {
    if (!listing) {
      throw new Error('No selected listing');
    }

    if (!listing.data?.id || !listing.data?.countryCode) {
      throw new Error('No listing id or country code');
    }

    const order = await paymentService.createOrder(listing.data?.id, listing.data?.country);

    if (order.error) enqueue('error', JSON.stringify(order.error));

    return order.id;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onApproveHandler: OnApproveFunction = async (data, actions) => {
    try {
      const result = await paymentService.approveOrder(data.orderID, listing?.data?.id ?? '');
      if (result.result?.status === 'COMPLETED') {
        navigate(routeConfig.listing.purchaseComplete);
      } else {
        setErrorText(result.result?.message?.details?.[0].description ?? 'Something went wrong with the payment');
        setPaymentFailed(true);
      }

    } catch (err: any) {
      error('PAYMENT_APPROVAL_ERROR', { errorMessage: err });
      navigate(routeConfig.listing.purchaseFailed);
    }
  };

  useEffect(() => {
    (async () => {
      if (!listing) {
        return;
      }
      setLoadingMerchantId(true);
      const m = await paymentService.getMerchantId(listing?.data?.id ?? '');
      setLoadingMerchantId(false);
      setMerchantId(m);
    })();
  }, [listing]);

  const PayButtons = useCallback(() => {
    return (
      <LoadingWidget isLoading={loadingMerchantId}>
        {merchantId ? (
          <PayPalScriptProvider
            options={{
              clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID ?? '',
              merchantId: merchantId,
              currency: 'AUD',
            }}
          >

            <PayPalButtons
              createOrder={createOrderHandler}
              onApprove={onApproveHandler}
              onError={(err) => {
                error('CREATE_ORDER_ERROR', err);
              }}
              style={{ shape: 'pill', height: 40 }}
              className={classes.payButton}
            />
          </PayPalScriptProvider>
        ) : (
          <ErrorText text={errorText} />
        )}
      </LoadingWidget>
    );
  }, [merchantId, loadingMerchantId]);

  return (
    <div className={classes.payNow}>
      <Heading text="Delivery Address" />
      <hr />
      <Instruction text="Currently your shipping address is handled by your PayPal account. Please ensure your PayPal shipping address is correct." />

      <Heading text="Choose payment method" />
      <hr />
      <div className="flex-column">
        <Information text="*International currency conversion fee might be charged via chosen payment method" italic />
        <Information
          text="**Paypal will impose their own fees when using their service, these fees are not shown below"
          italic
        />
      </div>
      <div className={classes.payButtonContainer}>
        <div className={classes.payButtons}>
          <PayButtons />
        </div>

        {
          paymentFailed &&
          <div className={classes.paymentFailed}>

            <ErrorText text={'Payment Failed: ' + errorText.replace('instrument', 'payment method')} />

          </div>
        }
      </div>
      <Heading text="Cost & Fees" />
      <hr />
      <div className={classes.feesAndCharges}>
        <div className={classes.col}>
          <div className={classes.feesAndChargesRow}>
            <span>Item/s</span>
          </div>
          <div className={classes.feesAndChargesRow}>
            <span>Shipping</span>
          </div>
          <div className={classes.feesAndChargesRow}>
            <span>GST</span>
          </div>
        </div>
        <div className={classes.col}>
          <div className={classes.feesAndChargesRow}>
            <span>{toCurrency(+(listing?.data?.buyItNowPrice ?? 0))}</span>
          </div>
          <div className={classes.feesAndChargesRow}>
            <span>
              {shippingChargeFormatted}
            </span>
          </div>
          <div className={classes.feesAndChargesRow}>
            <span className="subtle">Inclusive</span>
          </div>
        </div>
      </div>
      <div className="flex-column">
        <Information text="*International currency conversion fee might be charged via chosen payment method" italic />
        <Information
          text="**This fee as an estimate can be on average between 3% - 4% of total the total value"
          italic
        />
      </div>
      <hr />
      <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: 24 }}>
        <div className={classes.col}>
          <Heading text="Total" />
        </div>
        <div className={classes.col}>
          {toCurrency(+shippingCharge + +(listing?.data?.buyItNowPrice ?? 0))}
        </div>
      </div>
      {snackbar}
    </div>
  );
}
