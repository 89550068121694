
import React, { CSSProperties } from 'react';

interface IMultiCSSProps { [key: string]: CSSProperties; }

const styles: IMultiCSSProps = {
    bold: {
        fontWeight: 500,
    },
    normal: {
        fontWeight: 300,
    }
};

export function BoldedText(props: { searchStr: string; label: string; }) {

    try {
        const { searchStr, label } = props;
        const searchString = searchStr.toLowerCase().split(' ');
        const regExPattern = new RegExp(searchString.map(i => `(${i})`).join('|'), 'gm');
        const resultArray = label?.toLowerCase().split(regExPattern).filter(i => i);
        return <>
            {resultArray?.map((i, index) => <React.Fragment key={index}>{searchString.includes(i)
                ? <span style={styles.normal}>{i}</span>
                : <span style={styles.bold}>{i}</span>}</React.Fragment>)}</>;
    } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e);
    }

    return <>{props.label}</>;
}


