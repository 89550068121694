import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import classes from '../../styles/listing-tags.module.scss';
import { useAppDispatch, useAppSelector } from '../../hook';
import { setListingTags } from './store/createListingSlice';
import { getCardSeriesQuery } from '../../data/gql/queries/cardQueries';
import { LoadingWidget } from '../../components/shared/Loading';
import { Heading } from '../../components/shared/Heading';
import { useApolloQuery } from '../../useApolloQuery';
import { SelectedTags } from './SelectedTags';


export interface ITagItem {
    title: string;
    value: string;
    color?: string;
}

export interface ITagProps {
    items: ITagItem[];
    selectedItems?: ITagItem[];
    onChange?: (_: React.SyntheticEvent, item: ITagItem[]) => void;
}

export function ListingTagsPage() {

    const listingTags = useAppSelector(state => state.createListing.currentListing.tags ?? []);

    const { data, error, loading } = useApolloQuery(getCardSeriesQuery.query, getCardSeriesQuery.variables);

    const dispatch = useAppDispatch();

    const findTagItem = (tagValue: string) => {

        const rsult = tagOptions.find((series: any) => series.value === tagValue) ?? { title: 'UNKNOWN', value: 'UNKNOWN' } as ITagItem;

        return {
            title: rsult?.title,
            value: rsult?.value,
        };
    };


    const [tagOptions, setTagOptions] = React.useState<ITagItem[]>([]);

    React.useEffect(() => {
        if (data && data.cardCollection) {
            const series = data?.cardCollection?.[0].cardSeries.map((series: any) => series.name);
            const set = data?.cardCollection?.[0].cardSet.map((set: any) => set.name);

            const d: Array<string> = [...new Set(set?.concat(series).sort((a: string, b: string) => a.localeCompare(b)))] as Array<string>;

            setTagOptions(d.map(option => ({ title: option, value: option } as ITagItem)));

        }
    }, [data, loading]);

    const onChange = (_: React.SyntheticEvent, item: ITagItem[] | null) => {
        if (!item) return;
        dispatch(setListingTags(item.map((tag: ITagItem) => tag.value)));
    };

    if (error) return <div>Error</div>;
    if (loading) return <LoadingWidget isLoading={loading} />;

    return <div className={classes.tagsContainer}>
        <Heading>Tag Selection</Heading>
        <br></br>
        <br></br>
        <ListingTags selectedItems={listingTags.map(findTagItem)} items={tagOptions} onChange={onChange}></ListingTags>
    </div>;
}

export function ListingTags(props: ITagProps) {
    return <Tags items={props.items} onChange={props.onChange} selectedItems={props.selectedItems}></Tags>;
}


export default function Tags(props: ITagProps) {


    const deleteTag = (v: string) => {
        const t = props.selectedItems?.filter((tag: ITagItem) => tag.value !== v) ?? [];
        props.onChange?.({} as React.SyntheticEvent, t);
    };

    const onChange = (_: React.SyntheticEvent, items: ITagItem[]) => {
        props.onChange?.(_, items);
    };

    return (
        <Stack spacing={3}>
            <div className={classes.paper}>
                <div className={classes.innerPaper}>
                    <Autocomplete
                        size='small'
                        className={classes.autocomplete}
                        id="tags-filled"
                        options={props.items}
                        onChange={onChange}
                        multiple
                        getOptionLabel={(option: ITagItem) => option?.title}
                        value={props.selectedItems}
                        renderTags={(value: ITagItem[]) => {
                            return value.map(() => null);
                        }}
                        isOptionEqualToValue={(option: ITagItem, value: ITagItem) => {
                            return option.value === value.value;
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Tag Selection"
                                placeholder="Start Typing"
                            />
                        )} />

                    <SelectedTags selectedItems={props.selectedItems || []} deleteTag={deleteTag} />


                </div>
            </div>
            <div className={classes.counter}>
                {props.selectedItems?.length ?? 0} / 10
            </div>
        </Stack>
    );
}
