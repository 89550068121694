import React, { useEffect } from 'react';
import { Dialog } from './shared/dialog';
import { TextBox } from './shared/TextBox';
import classes from './messageDialog.module.scss';
import { createPortal } from 'react-dom';
import { Instruction } from './shared/Instruction';

export interface IMessageDialogProps<TMessageType = any> {
    show: boolean;
    onClose: () => void;
    onMessageSelected?: (message: TMessageType) => void;
    selectedMessage?: TMessageType;
}

export interface IMessage {
    id: string;
    listingId: string;
    sender: string;
    recipient: string;
    body: string;
}

export function MessageDialog(props: { show: boolean; onClose: () => void; onMessageChanged?: (message: IMessage) => void, message?: IMessage; onSubmit?: (message: IMessage) => void; }) {

    const [message, setMessage] = React.useState<IMessage>({} as IMessage);

    useEffect(() => {
        setMessage(props.message ?? {} as IMessage);
    }, [props.message]);

    function onChanged(text: string) {
        setMessage({ ...props.message, body: text } as IMessage);
        props.onMessageChanged?.({ ...props.message, body: text } as IMessage);
    }

    function submitMessage() {
        props.onSubmit?.(message);
        close();
    }

    function close() {
        props.onClose();
    }

    function cancel() {
        props.onClose();
    }


    return createPortal(<Dialog show={props.show} onClose={close} style={{ padding: 20 }} className={classes.dialog}>
        <listing-message-dialog>
            <div className={classes.heading}>
                <h4>Send a message</h4>
            </div>
            <Instruction text="Send a message to the seller to ask questions about the listing" />
            <TextBox
                inputProps={{
                    defaultValue: message.body,
                    onChange: (event) => onChanged(event.currentTarget.value),
                    maxLength: 255,
                    name: 'description',
                    required: true,
                    className: classes.comment
                }}
            ></TextBox>
            <div className={classes.actionButtons} >
                <a aria-label='link' className={classes.cancelButton} style={{ padding: 5 }} onClick={() => cancel()}>Cancel</a>
                <a aria-label='link' className={classes.postButton} style={{ padding: 5 }} onClick={() => submitMessage()}>Submit</a>
            </div>
        </listing-message-dialog>
    </Dialog>, document.body);
}

