import React from 'react';
import { MockFeatureIndicator } from '../../features/user/MockFeatureIndicator';
import classes from './comin-soon.module.scss';


type IContentProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export function ComingSoon(
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > & {
    message?: string;
    subtext?: string;
    className?: string,
    hideHelptext?: boolean,
    contentProps?: IContentProps;
  }
) {

  const { className, ...contentProps } = props.contentProps ?? { className: '' };

  return (
    <coming-soon class={classes.comingSoon}>
      {props.hideHelptext && <MockFeatureIndicator />}
      <div className={classes.innerContainer}>
        <span className={classes.message}>
          {props.message ?? 'Coming Soon'}
        </span>
        <span className={classes.subtext}>{props.subtext}</span>
      </div>
      <div className={`${classes.childrenContainer} ${className ?? ''}`} {...contentProps} >
        {props.children}
      </div>
    </coming-soon>
  );
}
