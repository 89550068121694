import { ISearchParameters } from '../../actions/iListingSearchParameters';
import { Filter } from '../../models/filter';
import { ISearchResultItem } from '../../models/iSearchResultItem';
import { RootState } from '../../stores/store';
import { IFilterParameters } from './IFilterParameters';
import { SearchType, toSolrFilterFormat } from '../../services/search-service/searchUtilities';
import { IAutocompleteResult } from '../../models/iCardSearchState';


const selectors = {

  selectFilterGroups: (state: RootState): Filter | undefined => state.listingSearch.filterData,
  selectActiveFilter: (state: RootState): Filter => {
    return state.listingSearch.activeFilter.reduce((p: Filter, c: string) => {
      return p.filters[c];
    }, state.listingSearch.filterData);
  },
  selectIsLoadingListings: (state: RootState): boolean => state.listingSearch.isLoadingCards,
  selectIsLoadingSearchFilters: (state: RootState): boolean => state.listingSearch.isLoadingSearchFilters,
  selectFilterParameters: (state: RootState): IFilterParameters => {
    const {
      listingSearchParameters,
      filterData,
      activeFilter } = state.listingSearch;
    return {
      query: listingSearchParameters?.autoCompleteSearchString ?? '',
      queryFilter: toSolrFilterFormat(filterData, activeFilter, SearchType.filters),
      activeFilter: activeFilter
    };
  },
  selectListingSearchParameters: (state: RootState) => state.listingSearch.listingSearchParameters || {} as ISearchParameters,
  selectListingSearchResults: (state: RootState) => state.listingSearch.listingSearchResults || [] as ISearchResultItem[],
  selectAutocompleteResults: (state: RootState) => state.listingSearch.autoCompleteResults || [] as IAutocompleteResult[],
  selectShowFilterDialog: (state: RootState) => state.listingSearch.showFilterDialog,
  selectTotalListings: (state: RootState) => state.listingSearch.totalCards,
  selectTotalSearchResults: (state: RootState) => state.listingSearch.totalSearchResults,
  selectFacets: (state: RootState) => state.listingSearch.facets,
};

export const {
  selectFilterGroups
  , selectActiveFilter
  , selectListingSearchParameters
  , selectListingSearchResults
  , selectShowFilterDialog
  , selectTotalListings
  , selectFacets
  , selectTotalSearchResults
  , selectIsLoadingListings
  , selectFilterParameters
  , selectIsLoadingSearchFilters
  , selectAutocompleteResults
} = selectors;
