import { useRef } from 'react';

interface IBaseValidator<T> {
    model: T | undefined;
    isValid: boolean;
    rules: Array<(model: T) => boolean>
    errors?: Array<IValidationResult<T>>
    touched: boolean;
    /**
     * This property determines whether you can proceed in your workflow
     */
    canProceed: boolean
}

export type ValidatorType<T> = (value?: T) => IValidationResult<T>

interface IValidationResult<T> {
    key: keyof T;
    isValid: boolean;
    description: string;
}

export type IValidItem<T> = IBaseValidator<T>

export function useValidator<T>(initialValue?: T, rules?: Array<ValidatorType<T>>) {
    const v = useRef(validator<T>(initialValue, rules));
    return {
        value: v.current.value,
        setValue: v.current.setValue
    };
}



export function validator<TModel>(initialValue?: TModel, rules?: Array<ValidatorType<TModel>>) {

    const _value = { isValid: true, touched: false, canProceed: false } as IValidItem<TModel>;

    const handler: ProxyHandler<any> = {
        get(target: any, property: string) {
            return target[property];
        },

        set(target: any, p: keyof IValidItem<TModel>, value: any) {
            target[p] = value;
            if (p === 'model') {
                _setModelState();
            }
            return true;
        }
    };

    const value: IValidItem<TModel> = new Proxy(_value, handler);

    setValue(initialValue);

    function _runValidationRules(): void {
        value.errors = rules?.map(i => i(value.model)).filter(i => !i.isValid);
    }

    function _setModelState() {
        if (!value.touched && !value.model)
            return;

        _runValidationRules();
        _setTouched();
        _setIsValid();
        _canProceed();

    }

    function setValue(v?: TModel): void {
        value.model = v;
    }

    function _setTouched(): void {
        if (!value.touched && !!value.model)
            value.touched = true;
    }

    function _canProceed(): void {
        value.canProceed = (!!value.touched && !!value.model && !!value.isValid);

    }

    function _setIsValid(): void {
        const invalid = (value.errors?.length ?? 0) > 0;
        value.isValid = value.touched === true && !!value.model && !invalid;
    }

    return {
        value: value,
        setValue,
    };

}





