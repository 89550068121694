import React, { useEffect } from 'react';
import { useEffectOnce } from 'react-use';
import { ReCaptchaAction, getRecapToken, useRecaptcha } from './helpers/recaptcha';
import { ApolloClient, DocumentNode, FetchPolicy, InMemoryCache, OperationVariables, getApolloContext } from '@apollo/client';
import { getAuthToken } from './services/baseService';


export const apolloClient = new ApolloClient({
    cache: new InMemoryCache(),
    uri: process.env.REACT_APP_GRAPHQL_URL,
});


export async function apolloQuery<TResultsType = any>(query: DocumentNode, variables?: OperationVariables, withAuth = false, fetchPolicy: FetchPolicy = 'cache-first') {
    let wAuth: { Authorization: string; } | undefined;

    const token = await getRecapToken(ReCaptchaAction.graphql);

    if (withAuth) {
        const authToken = await getAuthToken();
        wAuth = withAuth ? { 'Authorization': authToken } : undefined;
    }

    const data = await apolloClient?.query<TResultsType>({
        query,
        variables,
        context: {
            headers: {
                'x-cap-tok': token,
                ...wAuth
            }
        },
        fetchPolicy: fetchPolicy
    });

    return {
        data: data?.data,
        error: data?.error,
        loading: data?.loading,
    };
}


export function useApolloQuery<V>(query: DocumentNode, variables?: V) {

    const context = React.useContext(getApolloContext());
    const [data, setResults] = React.useState<any>();
    const { getToken, token } = useRecaptcha();

    useEffectOnce(() => {
        getToken(ReCaptchaAction.graphql);
    });

    useEffect(() => {
        let active = true;
        (async () => {
            try {
                // if (token) {
                const bearerToken = await getAuthToken();
                const queryResult = await context.client?.query({
                    query: query,
                    variables: { ...variables },
                    context: {
                        headers: {
                            'x-cap-tok': token ?? '',
                            'Authorization': `${bearerToken}`
                        }
                    },
                });
                if (active) {
                    setResults(queryResult);
                }
                // }
            } catch (e) {
                // eslint-disable-next-line no-console
                console.warn(e);
            }

        })();


        return () => {
            active = false;
        };

    }, [token]);

    return {
        data: data?.data,
        error: data?.error,
        loading: data?.loading,

    };
}
