import React from 'react';
import { useState } from 'react';
import styles from '../../styles/textArea.module.scss';

export function TextBox(props: {
  name?: string;
  inputProps?: React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  >;
  className?: string;
}) {
  const [currentLength, setCurrentLength] = useState<number>(0);

  return (
    <div>
      <textarea
        {...props.inputProps}
        value={props.inputProps?.value}
        className={`${styles.textarea} ${props.className} ${props.inputProps?.className}`}
        name={props.name || props.inputProps?.name}
        onKeyUp={(event) =>
          setCurrentLength(event?.currentTarget?.value.length)
        }
      ></textarea>
      <div className={styles.maxChar}>
        <span> {props.inputProps?.maxLength &&
          `Max characters: 
            ${currentLength} 
            /
            ${props.inputProps?.maxLength.toString()}`}</span>
      </div>
    </div>
  );
}
