import React from 'react';
import { CreateListingReview as CreateListingReview } from '../../ReviewListing';

import { StateMechanicProvider } from '../../StateMechanicContext';

export function CreateListingPreviewPage() {
    return <StateMechanicProvider>
        <CreateListingReview />
    </StateMechanicProvider >;
}
