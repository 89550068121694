import React, { useEffect } from 'react';
import { Section } from './Section';
import { TextBox } from '../../components/shared/TextBox';
import { IValidateEffect } from '../../hooks/IValidateEffect';
import { useValidator } from '../../hooks/useValididator';
import { useDebounceCallback } from '../../hooks/useDebounce';
import classes from '../../styles/card-description.module.scss';
import { Instruction } from '../../components/shared/Instruction';




export function CardDescription(props:
  {
    value?: string,
    onChange?: (value: string) => void,
    onValidate?: (x: IValidateEffect) => void;
  }) {

  const { value, setValue } = useValidator(props.value);
  const onChangeDebounce = useDebounceCallback(onChange, +(process.env.SEARCH_DEBOUNCE_TIME || '0'));

  function onChange(value: string) {
    setValue(value);
    props.onChange?.(value);
  }

  useEffect(() => {
    props.onValidate?.({ isValid: value?.isValid, canProceed: value?.canProceed });

  }, [value.canProceed, value.isValid]);

  return (
    <Section
      title="Card Description"
      subTitle="Tell your buyers more about your card"
      invalid={!value.isValid}
      className={classes.container}
    >
      <Instruction text='Extra information always gives buyers more confidence, share some facts about the card condition or additional steps you take when packaging cards! ' />
      <TextBox
        inputProps={{
          defaultValue: value.model,

          onChange: (event) => onChangeDebounce(event.currentTarget.value),
          maxLength: 255,
          name: 'description',
          required: true,
          className: classes.cardDescription

        }}
      ></TextBox>
    </Section >
  );
}
