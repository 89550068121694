import { PP_ORDER, PP_ORDER_CAPTURE } from '../../config/appConfig';
import { ReCaptchaAction } from '../../helpers/recaptcha';
import { IRegistrationLink } from '../../models/user/iRegistrationLinks';
import { IOptionalServiceResponse } from '../ServiceResponse';
import { getAsync, postAsync } from '../baseService';




export interface ApprovalResult {
  status: string,
  message: {
    name: string,
    details: [
      {
        issue: string,
        description: string;
      }
    ],
    message: string,
    debug_id: string,
    links: [
      {
        href: string,
        rel: string,
        method: string;
      },
      {
        href: string,
        rel: string,
        method: string;
      }
    ];
  };
}

export interface ICreateOrderRequest {
  listingId: string;
  countryCode: string;
}

export interface IPaymentServices {
  createOrderService: (listing: ICreateOrderRequest) => Promise<{ id: string; error: string; }>;
  approveOrderService: (orderId: string, listingId: string) => Promise<{ result?: ApprovalResult | undefined; err?: any; }>;
  getClientTokenService: () => Promise<string>;
  getMerchantIdService: (userId: string) => Promise<string>;
  getOnboardingStatusService: (authToken: string) => Promise<IOptionalServiceResponse<IRegistrationLink | undefined>>;
}

const paymentServices: IPaymentServices = {
  getOnboardingStatusService: async (authToken: string) => {
    try {
      const result = await getAsync<IRegistrationLink | undefined>(
        `${process.env.REACT_APP_PP_MERCHANT_REFERAL}?redirectUrl=${window.location.href}`,
        undefined,
        ReCaptchaAction.api_get_client_referer,
        true,
        authToken
      );

      return result as IOptionalServiceResponse<IRegistrationLink | undefined>;

    }
    catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      return {
        err: err
      } as IOptionalServiceResponse<IRegistrationLink | undefined>;
    }
  },
  getMerchantIdService: async (listingId: string) => {
    try {
      const response = await getAsync<{ merchant_id: string; }>(
        `${process.env.REACT_APP_PP_MERCHANT_ID?.replace('{listingId}', listingId)}`,
        undefined,
        ReCaptchaAction.api_get,
        true
      );

      const value = response.result?.merchant_id;
      return value || '';
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
    return '';
  },
  getClientTokenService: async () => {
    try {
      const t = await getAsync<{ client_token: string; }>(
        process.env.REACT_APP_PP_CLIENT_TOKEN_SERVICE || '',
        undefined,
        ReCaptchaAction.api_get,
        true
      )
        .then((d) => d.result)
        // eslint-disable-next-line no-console
        .catch((err) => console.error(err));
      return t?.client_token ?? '';
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
    return '';
  },
  createOrderService: async (listing: ICreateOrderRequest) => {
    if (!PP_ORDER) throw new Error('REACT_APP_PP_ORDER is not defined');

    const response = await postAsync<{ id: string; error: string; }, ICreateOrderRequest>(
      PP_ORDER + `?l=${listing.listingId}&p=${listing.countryCode}`,
      listing,
      undefined,
      ReCaptchaAction.api_post,
      true
    );

    if (response.err || !response.result) return { id: '', error: response.err.error || 'Error creating order' };

    return response.result;
  },
  approveOrderService: async (orderId: string, listingId: string) => {
    if (!PP_ORDER_CAPTURE) throw new Error('REACT_APP_PP_ORDER_CAPTURE is not defined');

    return postAsync<ApprovalResult, void>(
      PP_ORDER_CAPTURE.replace('{orderId}', orderId).replace('listingId', listingId),
      undefined,
      undefined,
      ReCaptchaAction.api_post,
      true
    );
  },
};

export const { approveOrderService, createOrderService, getClientTokenService, getMerchantIdService, getOnboardingStatusService } = paymentServices;
