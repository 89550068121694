/* eslint-disable no-useless-escape */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';

import { Filter } from '../../models/filter';

import {
  FilterGroups,
  ISetSelectedFilter,
} from '../../models/filterGroup';

import { IFacetMapGroup } from '../../actions/iFacet';
import { IGraphSearchResult } from '../../services/search-service/SolrSearchResults';
import { ISearchParameters } from '../../actions/iListingSearchParameters';
import { clearDirtyFilters, setFilterCounts } from '../../services/search-service/searchUtilities';
import { Slices } from '../../stores/slices';
import { IAutocompleteResult, ICardSearchState } from '../../models/iCardSearchState';
import { cardsAutoCompleteAction, searchCardsAction } from './cardActions';
import { ICard } from '../createListing/models/iCard';

const clearFilterThunk = (
  state: WritableDraft<ICardSearchState>,
  action: PayloadAction<string[]>
) => {
  const filterToClear = action.payload.reduce((p: Filter, c: string) => {
    return p.filters[c];
  }, state.filterData || {} as Filter);

  clearFilters(filterToClear);

  function clearFilters(filter: Filter) {
    filter.selected = false;

    Object.keys(filter.filters).forEach((i) => clearFilters(filter.filters[i]));
  }
};

const intitialState: ICardSearchState = {
  value: 0,
  cardSearchResults: [],
  autoCompleteResults: [],
  totalCards: 0,
  totalSearchResults: 0,
  showFilterDialog: false,
  filterData: { filters: {} } as Filter,
  activeFilter: [],
  facets: {} as IFacetMapGroup,
  isLoadingCards: false,
  isLoadingSearchFilters: false,
  isLoadingAutocomplete: false,
  cardSearchParameters: {} as ISearchParameters,
};

export const cardSearchSlice = createSlice({
  name: Slices.search,
  initialState: intitialState,
  reducers: {
    clearCardSearchResults: (state) => {
      state.cardSearchResults = [];
    },
    setIsLoadingCards: (state, action: PayloadAction<boolean>) => {
      state.isLoadingCards = action.payload;
    },
    setIsLoadingAutocomplete: (state, action: PayloadAction<boolean>) => {
      state.isLoadingAutocomplete = action.payload;
    },
    setIsLoadingFilters: (state, action: PayloadAction<boolean>) => {
      state.isLoadingSearchFilters = action.payload;
    },
    setActiveFilter: (state, action: PayloadAction<Array<string>>) => {
      state.activeFilter = action.payload;
    },
    setShowFilterDialog: (state, action: PayloadAction<boolean>) => {
      state.showFilterDialog = action.payload;
    },
    setAutoCompleteSearchResults: (state, action: PayloadAction<IAutocompleteResult[]>) => {
      state.autoCompleteResults = action.payload;
    },
    setCardsSearchString: (state, action: PayloadAction<string>) => {
      if (!state.cardSearchParameters)
        state.cardSearchParameters = {} as ISearchParameters;

      state.cardSearchParameters.autoCompleteSearchString = action.payload;
    },
    setCardsPageNumber: (state, action: PayloadAction<number>) => {
      if (!state.cardSearchParameters)
        state.cardSearchParameters = {} as ISearchParameters;

      state.cardSearchParameters.currentPage = action.payload;
    },
    setFilters: (state, action: PayloadAction<Filter>) => {
      state.filterData = action.payload;
    },
    setStateFilterCounts: (state, action: PayloadAction<Filter>) => {
      setFilterCounts(action.payload.filters, state.filterData.filters);
    },
    setSolrSearchFilter: (state, action: PayloadAction<string>) => {
      if (!state.cardSearchParameters)
        state.cardSearchParameters = {} as ISearchParameters;

      state.cardSearchParameters.filterString = action.payload;
    },
    setTopLevelCategoriesSelectedCount: (state, action: PayloadAction<Array<string>>) => {
      state.selectedCategories = action.payload;
    },
    setDirtyFilters: (state, action: PayloadAction<boolean>) => {
      state.filterData.isDirty = action.payload;
      const setIsDirty = (filter: Filter) => Object.keys(filter.filters)
        .forEach((key: string) => {
          filter.filters[key].isDirty = action.payload;
          setIsDirty(filter.filters[key]);
        });

      setIsDirty(state.filterData);

    },
    clearUncommittedFilters: (state) => {
      clearDirtyFilters(state.filterData);
    },
    setSelectedFilters: (state, action: PayloadAction<ISetSelectedFilter>) => {
      const isDirty = !action.payload.autoCommit;

      const filter = action.payload.path.reduce((p: WritableDraft<Filter>, c: string) => {
        if (p?.filters && action.payload.checked) {
          p.filters[c].selected = true;
          p.filters[c].isDirty = isDirty;
          return p.filters[c];
        }
        return p?.filters && p.filters[c];
      }, state.filterData || {} as Filter);

      if (filter) {
        filter.selected = action.payload.checked;
        if (filter.filters) {
          Object.keys(filter.filters).forEach((i) => {
            filter.filters[i].selected = false;
            filter.filters[i].isDirty = isDirty;

          });
        }
      }
    },

    clearSelectedFilters: (state, action: PayloadAction<string[]>) => clearFilterThunk(state, action),
    clearAllFilters: (state) => {

      const clearF = (filters: WritableDraft<FilterGroups>) => Object.keys(filters).reduce((acc, filter) => {
        acc[filter].selected = false;
        acc[filter].isDirty = false;
        if (acc[filter].filters) {
          clearF(acc[filter].filters);
        }
        return acc;
      }, filters);

      clearF(state.filterData.filters);

    },
    setSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
    },
    setSearchResults: (state, action: PayloadAction<ICard[]>) => {
      state.cardSearchResults = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(cardsAutoCompleteAction.fulfilled, (state, action: PayloadAction<IAutocompleteResult[]>) => {
      state.autoCompleteResults = action.payload;
    });
    builder.addCase(
      searchCardsAction.fulfilled,
      (state, action: PayloadAction<IGraphSearchResult>) => {
        state.cardSearchResults = action.payload.records || [];

        if (!state.totalSearchResults && !state.totalCards) {
          state.totalCards = action.payload.totalRecords || 0;
        }
        state.totalSearchResults = action.payload.totalRecords || 0;
      }
    );
    builder.addCase(
      searchCardsAction.rejected,
      (state) => {
        state.cardSearchResults = [];
      }
    );
  },
});

export const {
  setSelectedFilters,
  clearSelectedFilters,
  clearAllFilters,
  setShowFilterDialog,
  setSolrSearchFilter,
  setActiveFilter,
  setCardsSearchString,
  setSearchResults,
  setSearchTerm,
  setFilters,
  setDirtyFilters,
  setIsLoadingAutocomplete,
  setAutoCompleteSearchResults,
  setIsLoadingCards,
  setIsLoadingFilters,
  setTopLevelCategoriesSelectedCount,
  setStateFilterCounts,
  clearUncommittedFilters,
  setCardsPageNumber
} = cardSearchSlice.actions;

export default cardSearchSlice.reducer;


