import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { StorageKey } from '../stores/Storage';


const initialState = {
    snackbarMessage: undefined,
};

interface IGlobalState {
    snackbarMessage: ISnackbarMessage | undefined;
}


export interface ISnackbarMessage {
    snackbarMessage: string;
    variant: 'success' | 'error' | 'warning' | 'info';
}


export const globalSlice = createSlice({
    name: StorageKey.global,
    initialState: initialState,
    reducers: {
        setSnackbarMessage: (state: IGlobalState, action: PayloadAction<ISnackbarMessage | undefined>) => {
            state.snackbarMessage = action.payload;
        },
    }
});

export const { setSnackbarMessage } = globalSlice.actions;

export default globalSlice.reducer;
