import React from 'react';
import { createContext } from 'react';
import { StateMechanic } from 'state-mechanic';
import { stateConfigV2 } from '../../data/stateMachine/stateConfigV2';
import { IListing } from '../../models/create-listing/iListing';

const stateConfig = new StateMechanic<IListing>(stateConfigV2);
export const stateMechanicsContext = createContext(stateConfig);

export function StateMechanicProvider(props: { children?: React.ReactNode; }) {
    const Provider = stateMechanicsContext.Provider;
    return <Provider value={new StateMechanic<IListing>(stateConfigV2)}>
        {props.children}
    </Provider>;
}


export function useStateMech() {
    const context = React.useContext(stateMechanicsContext);
    const [state, setState] = React.useState(context.selectedState);

    const gotoState = (name: string) => {
        const canMove = context.gotoState(name);
        setState(context.selectedState);
        return canMove;
    };

    const moveNext = () => {
        context.moveNext();
        setState(context.selectedState);
    };

    const movePrevious = () => {
        context.movePrevious();
        setState(context.selectedState);
    };

    const setModel = (model: IListing) => {
        context.setModel(model);
        setState(context.selectedState);
    };

    return {
        context,
        state,
        gotoState,
        moveNext,
        movePrevious,
        setModel
    };

}