/* eslint-disable react/prop-types */
import TextField, { TextFieldProps } from '@mui/material/TextField';
import classes from '../../styles/Button.module.scss';
import React from 'react';

export const RainbowButton = React.forwardRef((
  props: React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > & {
    fullWidth?: boolean;
    variant?: 'no-outline' | 'outlined' | 'secondary-outline' | 'dangerous';
    borderColor?: string;
  }
  , ref: any) => {

  const { onClick, ...other } = props;
  return (
    <rainbow-button
      {...other}
      class={`${classes.buttonContainer} ${props.disabled ? classes.disabled : ''
        }`}
    >
      <div
        className={`${classes.button} ${props.className ?? ''} ${props.fullWidth ? classes.fullWidth : ''
          } ${classes[props.variant ?? 'outlined'] ?? ''}`}
        style={{ borderColor: props.borderColor, color: props.color }}
      >
        <button
          aria-label={props.name ?? 'button'}
          ref={ref}
          onClick={onClick}
          className={`${props.fullWidth ? classes.fullWidth : ''}`}
        >
          {props.children}
        </button>
      </div>
    </rainbow-button>
  );
});

export function RainbowAnchor(
  props: React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > & {
    fullWidth?: boolean;
    variant?: 'no-outline' | 'outlined' | 'secondary-outline';
    borderColor?: string;
    disabled?: boolean;
  }
) {
  const onClick = (e: any) => {
    props.onClick?.(e);
  };

  return (
    <rainbow-button class={`${classes.buttonContainer}`}>
      <div
        className={`${classes.button} ${props.className ?? ''} ${props.fullWidth ? classes.fullWidth : ''
          } ${classes[props.variant ?? 'outlined'] ?? ''}`}
        style={{ borderColor: props.borderColor, color: props.color }}
      >
        <a
          {...props}
          onClick={onClick}
          className={`${props.fullWidth ? classes.fullWidth : ''}`}
        >
          {props.children}
        </a>
      </div>
    </rainbow-button>
  );
}

export function RainbowInput(props: TextFieldProps) {
  return (
    <TextField {...props}>{props.children}</TextField>
  );
}
