import React from 'react';
import { RegisterPaymentService } from './RegisterPaymentService';
import { UserPageHeader } from './components/UserPageHeader';
import classes from './my-account.module.scss';
import { ReactComponent as Account } from '../../assets/svg/user-profile/account.svg';
import { useAuthHook } from '../../shared/hooks/useAuthHook';
export function MyAccount() {
  const { user } = useAuthHook();
  return (
    <div className={classes.myAccount}>
      <UserPageHeader title="" pageTitle="My Account" icon={<Account />} />
      <div className={classes.names}>
        <div className={classes.profilePic}>
          <img referrerPolicy="no-referrer" src={user?.picture} alt="profile" />
        </div>
        <div className={classes.nameDetails}>
          <div className={classes.name}>
            <label>Name: </label> <span>{user?.name}</span>
          </div>
          <div className={classes.name}>
            <label>Given name: </label> <span>{user?.given_name}</span>
          </div>
          <div className={classes.name}>
            <label>Family name: </label>
            <span>{user?.family_name}</span>
          </div>
          <div className={classes.email}>{user?.email}</div>
        </div>
      </div>
      <hr></hr>
      <h4>My Payment information</h4>
      <RegisterPaymentService />
    </div>
  );
}
