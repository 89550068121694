import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { IGetFeeTableServiceArgs, getFeeTableService } from '../../services/create-listing-service/create-listing-service';


interface ICreateListingActions {
    getFeeTable: AsyncThunk<any, IGetFeeTableServiceArgs, any>;
}

const createListingActions: ICreateListingActions = {
    getFeeTable: createAsyncThunk('createListing/getFeeTableService', async (args: IGetFeeTableServiceArgs, thunkApi: any) => {
        try {
            const response = await getFeeTableService(args);
            if (response) {
                return response;
            }

            thunkApi.rejectWithValue('Error fetching feetable for listing', args);
        } catch (ex: any) {
            thunkApi.rejectWithValue(ex.message);
        }
    })
};




export const { getFeeTable } = createListingActions;