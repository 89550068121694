import React from 'react';
import { CSSProperties, useEffect } from 'react';
import classes from './dialog.module.scss';

const backDrop: CSSProperties = {
  position: 'fixed',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  background: 'rgba(0, 0, 0, 0.5)',
  backdropFilter: 'blur(5px)',
  top: 0,
  left: 0,
  zIndex: 2,
};

const dialog: CSSProperties = {
  backgroundColor: '#1a2329',
  minWidth: 300,
  borderRadius: '15px',
  color: 'white',
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
};

export function Dialog(props: {
  children?: JSX.Element;
  show: boolean;
  style?: CSSProperties;
  className?: string;
  onClose: () => void;
}) {
  useEffect(() => {
    // prevent scrolling on body when the modal is open
    if (props.show) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'initial';
  }, [props.show]);

  return props.show ? (
    <modal-dialog
      style={{ ...backDrop, ...props.style, zIndex: 10 }}
      onClick={(event: React.MouseEvent<HTMLDivElement>) => {
        if (event.target === event.currentTarget) {
          props.onClose();
        }
      }}
    >
      <div style={dialog}
        className={`${classes.dialog}  ${props.className ?? ''}`}
      >
        {props.children}
      </div>
    </modal-dialog>
  ) : (
    <></>
  );
}
