
import classes from '../styles/ImagePanel.module.scss';
import React from 'react';
import { ReactComponent as MMLogo } from '../assets/svg/mm-logo-alpha.svg';
import image1 from '../assets/png/rightBar/mm1.png';
import image2 from '../assets/png/rightBar/mm2.png';
import image3 from '../assets/png/rightBar/mm3.png';
import image4 from '../assets/png/rightBar/mm4.png';

export function ImagePanel(props: { className?: string; style?: React.CSSProperties; }) {
  return (
    <div className={classes['right-bar'] + ' ' + props.className} style={props.style}>
      <div className={classes.image}>
        <MMLogo />
      </div>
      <div className={classes.image1}>
        <div className={classes.imgcontainer1}>
          <img src={image1} alt="any night" />
          <p className={classes.hidden1}>BOOTING...</p>
          <p className={classes.hidden2}>MARKETPLACE...</p>
          <p className={classes.hidden3}>Scanning listing...</p>
          <p className={classes.hidden4}>Scanning sellers...</p>
          <p className={classes.hidden5}>Scanning products...</p>
          <p className={classes.hidden6}>Products...Pokemon</p>
          <p className={classes.hidden7}>STATUS: </p>
          <span>MARKETPLACE EXECUTE%ALPHA1</span>
        </div>
      </div>
      <div className={classes.image2}>
        <div className={classes.imgcontainer2}>
          <img src={image2} alt="any day" />
          <p className={classes.hidden8}>BOOTING...</p>
          <p className={classes.hidden9}>ROADMAP...</p>
          <p className={classes.hidden10}>A:Seller Hub...</p>
          <p className={classes.hidden11}>B:Portfolio Hub...</p>
          <p className={classes.hidden12}>C:Insight Hub...</p>
          <p className={classes.hidden13}>D:Data\Products\OnePiece...</p>
          <p className={classes.hidden14}>Copyright 2024 Version: </p>
          <span>ROADMAP<br></br> EXECUTE%2024</span>
        </div>
      </div>
      <div className={classes.image3}>
        <div className={classes.imgcontainer3}>
          <img src={image3} alt="a merchant" />
          <p className={classes.hidden15}>BOOTING...</p>
          <p className={classes.hidden16}>FINANCIALS...</p>
          <p className={classes.hidden17}>Platform Fee: ...</p>
          <p className={classes.hidden18}>Running Cost: ...</p>
          <p className={classes.hidden19}>Revenue:...</p>
          <p className={classes.hidden20}>Margin:...</p>
          <p className={classes.hidden21}>Total Profit:</p>
          <span>FINANCIALS<br></br> EXECUTE%2024Q2</span>
        </div>
      </div>
      <div className={classes.image4}>
        <div className={classes.imgcontainer4}>
          <img src={image4} alt="is on the way" />
          <p className={classes.hidden22}>NOTICE</p>
          <p className={classes.hidden23}>Model...</p>
          <p className={classes.hidden24}>BOOT...</p>
          <p className={classes.hidden25}>CONTENT...</p>
          <span>ERROR<br></br>NO DISK FOUND!</span>
        </div>
      </div>
    </div >
  );
}
