/* eslint-disable no-console */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';

export const firebaseConfig = {
  apiKey: 'AIzaSyBZ9diDKo_aCqbw2dS-Hwcgr3MCAZ1vZEs',
  authDomain: 'collectorspoke-5a43c.firebaseapp.com',
  projectId: 'collectorspoke-5a43c',
  storageBucket: 'collectorspoke-5a43c.appspot.com',
  messagingSenderId: '568126066870',
  appId: '1:568126066870:web:0070fb37caec9aee691617',
  measurementId: 'G-42E8G2HV6R',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);




const EventLogger = {
  WarnTypes: {
    GET_SELLER_PAYMENT_STATUS_ERROR: 'GET_SELLER_PAYMENT_STATUS_ERROR',
  },
  ErrorTypes: {
    GET_SESSION_STORAGE_FAILED: 'GET_SESSION_STORAGE_ERROR',
    UPDATE_SELLER_MERCHANT_ID_ERROR: 'UPDATE_SELLER_MERCHANT_ID_ERROR',
    CARD_SEARCH_ERROR: 'CARD_SEARCH_ERROR',
    LISTING_DRAFT_ERROR: 'LISTING_DRAFT_ERROR',
    LISTING_PUBLISH_ERROR: 'LISTING_PUBLISH_ERROR',
    LISTING_SEARCH_ERROR: 'LISTING_SEARCH_ERROR',
    LISTING_GET_ERROR: 'LISTING_GET_ERROR',
    BOLDED_TEXT_ERROR: 'BOLDED_TEXT_ERROR',
    SET_SESSION_STORAGE_ERROR: 'SET_SESSION_STORAGE_ERROR',
    GET_SESSION_STORAGE_ERROR: 'GET_SESSION_STORAGE_ERROR',
    FILTER_SEARCH_ERROR: 'FILTER_SEARCH_ERROR',
    FILTER_LISTING_COUNTS: 'FILTER_LISTING_COUNT_ERROR',
    GET_MARKET_ANALYTICS_ERROR: 'GET_MARKET_ANALYTICS_ERROR',
    PAYMENT_SERVICE_ERROR: 'PAYMENT_SERVICE_ERROR',
    USER_GET_SELLER_LISTINGS_ERROR: 'USER_GET_SELLER_LISTINGS_ERROR',
    CREATE_ORDER_ERROR: 'CREATE_ORDER_ERROR',
    GET_FEE_TABLE_SERVICE_ERROR: 'GET_FEE_TABLE_SERVICE_ERROR',
    GET_PURCHASE_HISTORY_ERROR: 'GET_PURCHASE_HISTORY_ERROR',
    USER_SET_SELLER_RATING: 'USER_SET_SELLER_RATING',
    USER_SEND_MESSAGE_ERROR: 'USER_SEND_MESSAGE_ERROR',
    POST_ERROR: 'POST_ERROR',
    GET_ERROR: 'GET_ERROR',
    DELETE_LISTING_ERROR: 'DELETE_LISTING_ERROR',
    FAQ_LOAD_ERROR: 'FAQ_LOAD_ERROR',
    GET_SELLER_PAYMENT_STATUS_ERROR: 'GET_SELLER_PAYMENT_STATUS_ERROR',
    PAYMENT_APPROVAL_ERROR: 'PAYMENT_APPROVAL_ERROR',
    REPORT_LISTING_ERROR: 'REPORT_LISTING_ERROR',
    REPORT_SELLER_ERROR: 'REPORT_SELLER_ERROR',
    SET_FILTER_COUNTS_ERROR: 'SET_FILTER_COUNTS_ERROR',
    TRACKING_ID_NO_LISTING_TO_SUBMIT: 'TRACKING_ID_NO_LISTING_TO_SUBMIT',
    SET_LISTING_TRACKING_DETAILS: 'SET_LISTING_TRACKING_DETAILS',
    MAP_TO_SEARCH_ITEM: 'MAP_TO_SEARCH_ITEM',
  },

  InfoTypes: {
    LISTING_CARD_SEARCH: 'LISTING_CARD_SEARCH',
    CATALOG_UPLOAD_IMAGE: 'CATALOG_UPLOAD_IMAGE',
    CATALOG_REMOVE_IMAGE: 'CATALOG_REMOVE_IMAGE',
    CATALOG_CREATE: 'CATALOG_CREATE',
    LISTING_PUBLISHED_SUBMIT: 'LISTING_PUBLISHED_SUBMIT',
    LISTING_DRAFT_SUBMIT: 'LISTING_DRAFT_SUBMIT',
    LISTING_BACK: 'LISTING_BACK',
    LISTING_NEXT: 'LISTING_NEXT',
    LISTING_UPLOAD_IMAGE: 'LISTING_UPLOAD_IMAGE',
    LISTING_REMOVE_IMAGE: 'LISTING_REMOVE_IMAGE',
    LISTING_ADD_IMAGE: 'LISTING_ADD_IMAGE',
    LISTING_SHIPPING: 'LISTING_SHIPPING',
    LISTING_TRANSACTION: 'LISTING_TRANSACTION',
    LISTING_PREVIEW: 'LISTING_PREVIEW',
    LISTING_GOTO: 'LISTING_GOTO',
    LISTING_COUNT_ERROR: 'LISTING_COUNT_ERROR',
    LISTING_DRAFT_EDIT: 'LISTING_DRAFT_EDIT',
    FILTER_SEARCH: 'FILTER_SEARCH',
    SEARCH_TEXT: 'SEARCH_TEXT',
    SEARCH_CATEGORY: 'SEARCH_CATEGORY',
  },

  error(eventName: keyof typeof this.ErrorTypes, payload: ILogEventPayload) {
    if (process.env.NODE_ENV === 'production') {
      logEvent(analytics, eventName, payload);
    }
    else {
      console.error(eventName, payload);
    }
  },
  info(eventName: keyof typeof this.InfoTypes, payload: ILogEventPayload) {
    if (process.env.NODE_ENV === 'production') {
      logEvent(analytics, eventName, payload);
    } else {
      console.info(eventName, payload);
    }
  },
  warn(eventName: keyof typeof this.WarnTypes, payload: ILogEventPayload) {
    if (process.env.NODE_ENV === 'production') {
      logEvent(analytics, eventName, payload);
    } else {
      console.warn(eventName, payload);
    }
  },
};

interface ILogEventPayload {
  moduleName?: string;
  partialUserId?: string;
  errorMessage?: string;
  currentUrl?: string;
  exception?: any;
}

const genericLog = <TEventType extends keyof typeof EventLogger>(categoryType: TEventType) =>
  createAsyncThunk(
    'logging',
    async (logProps: { eventName: (typeof EventLogger)[typeof categoryType]; payload: ILogEventPayload; }) => {
      logProps.payload.currentUrl = window.location?.href;
      logEvent(analytics, logProps.eventName.toString(), logProps.payload);
    }
  );

const actions = {
  logEvents: createAsyncThunk(
    'logging',
    async (logProps: {
      eventName:
      | keyof typeof EventLogger.InfoTypes
      | keyof typeof EventLogger.ErrorTypes
      | keyof typeof EventLogger.WarnTypes;
      payload: any;
    }) => {
      logEvent(analytics, logProps.eventName, logProps.payload);
      if (logProps.eventName in EventLogger.ErrorTypes) {
        console.error(logProps.payload);
      }
    }
  ),
  logInfo: genericLog('InfoTypes'),
  logError: genericLog('ErrorTypes'),
  logWarn: genericLog('WarnTypes'),
};

export const { logEvents, logInfo, logWarn, logError } = actions;

export const { error, info, warn } = EventLogger;
