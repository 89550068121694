import React, { useEffect, useRef } from 'react';
// import Glide, { Controls, Breakpoints, Swipe } from '@glidejs/glide/dist/glide.modular.esm';
import Glide, { Controls, Breakpoints, Swipe } from '@glidejs/glide/dist/glide.modular.esm';
// import { ReactComponent as ArrowLeft } from '../../../assets/svg/arrow-left.svg';
// import { ReactComponent as ArrowRight } from '../../../assets/svg/arrow-right.svg';
import classes from './glide-styles.module.scss';
import { Img } from '../../../components/Image';
import noPhoto from '../../../assets/svg/no-photo.svg';

export interface ImageCarouselProps {
    images: { thumb: string, hiRes: string; }[];
    startAt?: number;
    perView?: number;
    showBullets?: boolean;
    hideArrows?: boolean;
    showThumbs?: boolean;
    isMobile?: boolean;
    onImageClick?: (index: number) => void;
}

export function ImageCarousel(props: ImageCarouselProps) {

    const glideRef = useRef<HTMLDivElement>(null);
    const glideThumbsRef = useRef<HTMLDivElement>(null);

    const [glide, setGlide] = React.useState<Glide | null>(null);
    const [glideThumbs, setGlideThumbs] = React.useState<Glide | null>(null);
    // load main image
    useEffect(() => {
        const g = new Glide('#glide', {
            type: 'carousel',
            startAt: 0,
            perView: 1,
            focusAt: 'center',
            gap: 10,
            autoplay: 3000,
            hoverpause: true,
            keyboard: true,
        })
            .mount({ Controls, Breakpoints, Swipe });

        g.on('move', () => {
            glideThumbs?.go(`=${g.index}`);
        });

        setGlide(g);
        return () => {
            g.destroy();
        };
    }, [props.images, glideRef, props.isMobile]);


    const onIndexChanged = (index: number) => {
        if (glide) {
            glide.go(`=${index}`);
            glideThumbs?.go(`=${index}`);
        }
        else throw new Error('Glide is not initialized');
    };


    // load thumbs
    useEffect(() => {
        if (props.showThumbs) {

            const g = new Glide('#glideThumbs', {
                type: 'slider',
                startAt: props.images.length / 2,
                perView: 5,
                focusAt: 'left',
                gap: 10,
                autoplay: 3000,
                hoverpause: true,
                keyboard: true,
            }).mount({ Controls, Breakpoints, Swipe });


            setGlideThumbs(g);

            return () => {
                g.destroy();
            };
        }
    }, [props.images, glideRef, props.isMobile]);

    // expand image
    const onImageClick = (index: number) => {
        // open image in a modal
        props.onImageClick?.(index);
    };


    return <div className={classes['glide-styles']} >
        <div className={classes.glide} id="glide" ref={glideRef}>
            <div className={classes.glide__track} data-glide-el="track">
                <ul className={classes.glide__slides} >
                    {(props.images ?? []).map((image, index) => {
                        return <li key={index} className={`${classes.glide__slide} ${classes.slide}`} onClick={() => onImageClick(index)}>
                            <Img className={classes.img} src={image.thumb} loading='eager' alt='main image' fallback={noPhoto} hires={image.hiRes} /></li>;
                    })}
                </ul>
            </div>
            {
                props.showBullets &&
                <div className={classes.glide__bullets} data-glide-el="controls[nav]">
                    {
                        props.images.map((image, index) => <button aria-label='image controls' key={`btn_${index}`} className={classes.glide__bullet} data-glide-dir={`=${index}`}></button>)
                    }
                </div>
            }
            {
                // !props.hideArrows && <div data-glide-el="controls">
                //     <button className={`${classes.glide__arrow} ${classes['glide__arrow--right']} ${classes.arrow} ${classes.right} `} data-glide-dir=">">
                //         <ArrowRight />
                //     </button>
                //     <button className={`${classes.glide__arrow} ${classes['glide__arrow--left']} ${classes.arrow} ${classes.left}`} data-glide-dir="<">
                //         <ArrowLeft />
                //     </button>
                // </div>
            }
        </div>

        {/* second instance of the glide control here to view thumbs */}
        {props.showThumbs &&
            <div className={classes.glide} id="glideThumbs" ref={glideThumbsRef}>
                <div className={classes.glide__track} data-glide-el="track">
                    <ul className={classes.glide__slides} >

                        {props.images.map((image, index) => {
                            return <li key={index} className={`${classes.glide__slide} ${classes.slide}`}
                                onClick={() => {
                                    onIndexChanged(index);
                                }}>
                                <Img style={{ width: '100%' }} src={image.thumb} loading='lazy' alt="thumbnail" fallback='https://dev.midnight-merchant.com/userImages/thumbnails/noPhoto.svg' /></li>;
                        })}
                    </ul>
                </div>
                {/* <div data-glide-el="controls">
                    <button className={`${classes.glide__arrow} ${classes['glide__arrow--right']} ${classes.arrow} ${classes.right} `} data-glide-dir=">">
                        <ArrowRight style={{ fill: '#ffffff80' }} />
                    </button>
                    <button className={`${classes.glide__arrow} ${classes['glide__arrow--left']} ${classes.arrow} ${classes.left}`} data-glide-dir="<">
                        <ArrowLeft fill='#ffffff80' />
                    </button>
                </div> */}
            </div>
        }
    </div >;
}