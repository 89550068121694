import React, { useEffect, useMemo } from 'react';
import { TumbleWeed } from '../../components/shared/TumbleWeed';
import { UserPageHeader } from './components/UserPageHeader';
import classes from './my-listings.module.scss';
import { UserAsset } from './UserAsset';
import { ReactComponent as SoldHistory } from '../../assets/svg/user-profile/sold-history.svg';
import { useAppDispatch, useAppSelector } from '../../hook';
import { getSellerSoldListings } from './seller/sellerActions';
import { LoadingWidget } from '../../components/shared/Loading';
import { useNavigate } from 'react-router-dom';
import { useAuthHook } from '../../shared/hooks/useAuthHook';
import { ITracking, TrackingDialog } from 'src/components/TrackingDialog';
import { IListing } from 'src/models/create-listing/iListing';
import { error } from 'src/actions/logActions';
import { setTrackingDetails } from '../listing/listingActions';
import { selectSellerSoldListings } from './seller/sellerSelector';
import { LoadMore } from 'src/components/LoadMore';

export function SoldItems() {
  const dispatch = useAppDispatch();
  const { user } = useAuthHook();
  const navigate = useNavigate();

  const [isTrackingDialogOpen, setIsTrackingDialogOpen] = React.useState(false);
  const onTrackingDialogClose = () => setIsTrackingDialogOpen(false);
  const [selectedTrackingDetails, setSelectedTrackingDetails] = React.useState<ITracking>();
  const [selectedListing, setSelectedListing] = React.useState<IListing>();
  const [listings, setListings] = React.useState<IListing[]>([]);
  const pageStart = React.useRef(0);
  const [searchText, setSearchText] = React.useState('');
  const { data, isLoading } = useAppSelector(selectSellerSoldListings);

  const onTrackingSubmit = (trackingDetails: ITracking) => {
    setIsTrackingDialogOpen(false);
    if (selectedListing) {
      dispatch(setTrackingDetails(trackingDetails));
    }
    else {
      error('TRACKING_ID_NO_LISTING_TO_SUBMIT', { currentUrl: window.location.href, errorMessage: 'No listing to submit tracking details', moduleName: 'SoldItems', partialUserId: user?.email });
    }
  };

  useEffect(() => {
    if (data?.records) {
      if (pageStart.current > 0) {
        setListings([...listings, ...data.records]);
      } else {
        setListings(data?.records || []);
      }
      pageStart.current = data.start + 20;
    }
  }, [data]);

  const resetPageCount = () => {
    pageStart.current = 0;
  };

  useEffect(() => {
    resetPageCount();
    dispatch(getSellerSoldListings({ page: 0, searchText: searchText }));
  }, [searchText]);


  const assets = useMemo(() => {
    return listings && listings.length > 0 && listings.map((listing) => {
      const img = listing.imageList?.[0];
      return {
        asset: {
          id: listing.id,
          imageUrl: `${process.env.REACT_APP_IMAGES_URL}/userImages/thumbnails/${img.name}`,
          line1: listing.name,
          line2: listing.set,
          line3: listing.rarity,
          line4: listing.number,
          price: +listing.buyItNowPrice,
          purchaseDate: listing.purchaseDate ? (new Date(listing.purchaseDate[0])).toLocaleDateString('en-au') : undefined,
        },
        actions: [{
          name: 'Buyer Details',
          disabled: true
        },
        {
          name: 'Confirm Postage',
          action: (listingId?: string) => {
            const selectedListing = data.records.find((listing: IListing) => listing.id === listingId);
            if (selectedListing) {
              setSelectedListing(selectedListing);
              setIsTrackingDialogOpen(true);
              setSelectedTrackingDetails({ trackingNumber: selectedListing.trackingNumber, trackingUrl: selectedListing.trackingUrl, listingId: listingId });
            } else {
              // eslint-disable-next-line no-console
              console.error('Listing not found');
            }

          },
        },
        {
          name: 'Contact Buyer',
          action: () => navigate(`/chat/${listing.id}`),
          disabled: true
        },
        {
          name: 'Leave Feedback',
          disabled: true
        },
        {
          name: 'View listing',
          action: () => navigate(`/listing/${listing.id}/product`),
        }],
      };
    }) || [];
  }, [listings]);

  const loadMore = () => {
    dispatch(getSellerSoldListings({ page: pageStart.current, searchText: searchText }));
  };

  return (
    <div className={classes.myListings} >
      <UserPageHeader
        showSearchBar
        searchBarPlaceholder='Search my sold items'
        onSearchTextChanged={setSearchText}
        title="Sold Item History"
        pageTitle="Sold Items"
        icon={< SoldHistory height={30} width={30} />} />
      <TrackingDialog show={isTrackingDialogOpen} onClose={onTrackingDialogClose} onSubmit={onTrackingSubmit} tracking={selectedTrackingDetails} />
      <LoadingWidget isLoading={isLoading && listings?.length <= 0} style={{ marginTop: 40, height: 100 }}>
        {!isLoading && !listings?.length && listings?.length <= 0 && <TumbleWeed message="List a collectible! Then edit it here!" />}
        {assets.map((asset, index) => (
          <React.Fragment key={index}>
            <UserAsset key={index} asset={asset.asset} actions={asset.actions}></UserAsset>
          </React.Fragment>
        ))}
      </LoadingWidget>
      {data.records?.length > 0 &&
        <div className={classes.loadMoreButtonContainer}>
          <LoadMore isLoading={isLoading} hasMore={true}
            loadMore={() => {
              loadMore();
            }} ></LoadMore>
        </div>
      }
    </div>
  );
}



