import React, { useEffect } from 'react';
import axios from 'axios';
export default function Sitemap() {
  const [sitemap, setSitemap] = React.useState<any>(null);

  useEffect(() => {
    let ignore = false;

    (async () => {
      if (window.location.origin.includes('localhost')) {
        setSitemap('<sitemapindex xmlns="http://www.sitemaps.org/schemas/sitemap/0.9"></sitemapindex>');
        return;
      }
      const sitemap = await (
        await axios.get(`sitemap.${window.location.origin}`, {
          headers: {
            'Content-Type': 'application/xml',
            Accept: 'application/xml',
          },
        })
      ).data();
      if (ignore) return;
      setSitemap(sitemap);
    })();

    return () => {
      ignore = true;
    };
  }, []);

  return <>{document.write(sitemap)}</>;
}
