import React from 'react';
import { Logo } from './Logo';
import classes from '../styles/HeaderBar.module.scss';
import { ReactComponent as Marketplace } from '../assets/svg/bottombar/marketplace.svg';
import { ReactComponent as Insight } from '../assets/svg/bottombar/data.svg';
import { ReactComponent as PlusIcon } from '../assets/svg/bottombar/add.svg';
import { ReactComponent as News } from '../assets/svg/bottombar/News.svg';
import { ReactComponent as Settings } from '../assets/svg/bottombar/Settings.svg';
import { ReactComponent as Discord } from '../assets/svg/bottombar/discord.svg';
import { Link, useNavigate } from 'react-router-dom';
import { ifDev } from '../CustomConsole';
import { printSession, StorageKey } from '../stores/Storage';
import { useAppDispatch } from '../hook';
import { initialiseCurrentListing } from '../features';
import { routeConfig } from '../routes/routeConfig';
import NavMenu, { NavOption } from './NavMenu';
import { getUserMenuOptions } from '../features/user/UserPage';
import { useAuthHook } from '../shared/hooks/useAuthHook';


export const HeaderBarCenterLinks = () => {

  return <>
    {/* {Environment()} */}
    <Link to={routeConfig.searchResults.path} aria-label='marketplace'>
      <Marketplace />
    </Link>
    <Link to={routeConfig.insights.path} aria-label='insights'>
      <Insight />
    </Link>
    <Link to={routeConfig.createListing.listingType} aria-label='place advert'>
      <PlusIcon />
    </Link>
    <Link to={routeConfig.faq.path} aria-label='FAQ'>
      <News />
    </Link>
    <Link to={process.env.REACT_APP_DISCORD_URL ?? ''} target='_blank' rel="noreferrer" aria-label='Discord'>
      <Discord />
    </Link>
    <Link to={routeConfig.user.myAccount} aria-label='Account'>
      <Settings />
    </Link>
    {/* {ClearSession()} */}
  </>;
};


export function HeaderBar(props: { className?: string; }) {
  return (<>
    <div className={classes.headerBar + ' ' + (props.className ?? '')}>
      <Logo></Logo>
      <div className={classes.actions}>
        <HeaderBarCenterLinks />
      </div>
      <UserMenu className={classes.userName} />
    </div >
  </>);
}


function UserMenu(props: { className?: string; }) {
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuthHook();

  const navigate = useNavigate();

  const onUserMenuOptionSelected = (option: NavOption) => {
    if (option.value === 'logout') {
      logout();
    } else {
      navigate(option.value);
    }
  };

  return (
    <div className={props.className}>
      {isAuthenticated ? (
        <user-name>
          <span className={classes.name}>{user?.name}</span>
          {user?.picture &&
            <div>
              <img
                src={user && user.picture}
                referrerPolicy="no-referrer"
                alt="Profile"
                className={classes.profilePic}
              ></img>
            </div>}
          <NavMenu
            options={getUserMenuOptions(['userName'])}
            onOptionSelected={onUserMenuOptionSelected}
          ></NavMenu>
        </user-name>
      ) : (
        <a
          onClick={() => {
            loginWithRedirect({
              authorizationParams: { redirectUri: window.location.origin },
            });
          }}
          style={{ cursor: 'pointer', color: '#b1b1b1' }}
        >
          sign-in
        </a>
      )}
    </div>
  );
}

export function Environment() {
  return ifDev(() => (
    <span style={{ color: '#FFFF0040' }}>
      {process.env.REACT_APP_ENVIRONMENT}
    </span>
  ))();
}

export function ClearSession() {
  const dispatch = useAppDispatch();
  return ifDev(() => (
    <>
      <span style={{ marginLeft: 20, cursor: 'pointer', color: '#ff000090' }}>
        <a
          onClick={async () => {
            await dispatch(initialiseCurrentListing);
            // eslint-disable-next-line no-console
            console.clear();
            sessionStorage.clear();
            location.reload();
            // eslint-disable-next-line no-console
            console.log('cleared storage');
            printSession(StorageKey.createListing);
          }}
        >
          Clear Session
        </a>
      </span>
    </>
  ))();
}




