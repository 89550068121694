/* eslint-disable no-console */

import { error } from '../actions/logActions';
// import { ifDev } from '../CustomConsole';

const BASE64ENCODED_EMPTY_OBJECT = 'e30=';

type GetSessionType = <T>(key: StorageKey) => T;
type SetSessionType = <T>(key: StorageKey, value: T) => void;

export enum StorageKey {
  createListing = 'createListing',
  auth0 = 'auth0',
  searchParams = 'searchParams',
  listing = 'listing',
  user = 'user',
  global = 'global',
}

export const setSession = <T>(key: StorageKey, value: T) => {
  try {
    sessionStorage.setItem(btoa(key), btoa(encodeURI(JSON.stringify(value))));
  } catch (ex) {
    error('SET_SESSION_STORAGE_ERROR', { moduleName: 'setSession', errorMessage: JSON.stringify(ex) });
    throw ex;
  }
};

export const removeSession = (key: StorageKey) => {
  try {
    sessionStorage.removeItem(btoa(key));
    console.log('session cleared');
  } catch (ex) {
    error('SET_SESSION_STORAGE_ERROR', { moduleName: 'removeSession', errorMessage: JSON.stringify(ex) });
    throw ex;
  }
};

export const printSession = (key: StorageKey) => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const sessionItem: string | null = sessionStorage.getItem(btoa(key)) || BASE64ENCODED_EMPTY_OBJECT;
    // ifDev(console.log)('GET_SESSION_STORAGE', JSON.parse(decodeURI(atob(sessionItem) || BASE64ENCODED_EMPTY_OBJECT)));
  } catch (ex: any) {
    error('SET_SESSION_STORAGE_ERROR', { moduleName: 'printSession', errorMessage: JSON.stringify(ex) });
    throw ex;
  }
};

export const mergeSession: SetSessionType = <T>(key: StorageKey, value: T) => {
  try {
    sessionStorage.getItem(btoa(key));
    sessionStorage.setItem(btoa(key), btoa(encodeURI(JSON.stringify(value))));
  } catch (ex) {
    error('SET_SESSION_STORAGE_ERROR', { moduleName: 'mergeSession', errorMessage: JSON.stringify(ex) });
    throw ex;
  }
};

export const getSession: GetSessionType = <T>(key: StorageKey) => {
  try {
    const sessionItem: string | null = sessionStorage.getItem(btoa(key)) || BASE64ENCODED_EMPTY_OBJECT;
    const decoded = decodeURI(atob(sessionItem) || BASE64ENCODED_EMPTY_OBJECT);
    return JSON.parse(decoded) as T;
  } catch (ex: any) {
    error('SET_SESSION_STORAGE_ERROR', { moduleName: 'mergeSession', errorMessage: JSON.stringify(ex) });
    throw ex;
  }
};
