/* eslint-disable @typescript-eslint/no-unused-vars */
import { createListenerMiddleware } from '@reduxjs/toolkit';
import { getPurchaseHistory } from '../../features/user/buyer/buyerActions';
import { getSellerListings, getSellerPaymentServiceOnboardingStatus, getSellerSoldListings } from '../../features/user/seller/sellerActions';
import {
  setFetchingSellerSoldListings,
  setPurchaseHistory,
  setSellerListings,
  setSellerPaymentServiceOnboardingStatus,
  setSellerSoldListings,
} from '../../features/user/userSlice';
import { setCanBePaid } from '../../features';
import { IGraphSearchResult } from 'src/services/search-service/SolrSearchResults';
export const userMiddleware = createListenerMiddleware();

const getSellerPaymentServiceOnboardingStatusPendingSubscription = userMiddleware.startListening({
  actionCreator: getSellerPaymentServiceOnboardingStatus.pending,
  effect: (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    listenerApi.dispatch(setSellerPaymentServiceOnboardingStatus({ data: undefined, isLoading: true }));
  },
});

const getSellerPaymentServiceOnboardingStatusFulfilledSubscription = userMiddleware.startListening({
  actionCreator: getSellerPaymentServiceOnboardingStatus.fulfilled,
  effect: (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    listenerApi.dispatch(setSellerPaymentServiceOnboardingStatus({ data: action.payload, isLoading: false }));
    listenerApi.dispatch(setCanBePaid(action.payload?.canBePayed ?? false));
  },
});

const getPurchaseHistoryPendingSubscription = userMiddleware.startListening({
  actionCreator: getPurchaseHistory.pending,
  effect: (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    listenerApi.dispatch(setPurchaseHistory({ data: [], isLoading: true }));
  },
});
const getPurchaseHistoryFulfilledSubscription = userMiddleware.startListening({
  actionCreator: getPurchaseHistory.fulfilled,
  effect: (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    listenerApi.dispatch(setPurchaseHistory({ data: action.payload }));
  },
});
const getSellerListingsPendingSubscription = userMiddleware.startListening({
  actionCreator: getSellerListings.pending,
  effect: (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    listenerApi.dispatch(setSellerListings({ data: {} as IGraphSearchResult, isLoading: true }));
  },
});
const getSellerListingsFulfilledSubscription = userMiddleware.startListening({
  actionCreator: getSellerListings.fulfilled,
  effect: (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    listenerApi.dispatch(setSellerListings({ data: action.payload, isLoading: false }));
  },
});
const getSellerSoldListingsPendingSubscription = userMiddleware.startListening({
  actionCreator: getSellerSoldListings.pending,
  effect: (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    listenerApi.dispatch(setFetchingSellerSoldListings({ data: [], isLoading: true }));
  },
});
const getSellerSoldListingsFulfilledSubscription = userMiddleware.startListening({
  actionCreator: getSellerSoldListings.fulfilled,
  effect: (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    listenerApi.dispatch(setSellerSoldListings({ data: action.payload }));
  },
});
