
import React from 'react';
import { useTranslation } from 'react-i18next';
import classesEB from './styles/expandingButtonWhite.module.scss';
import { ReactComponent as Email } from './assets/svg/user-profile/email.svg';


export function EmailButton(props: { onClick?: () => void, label?: string, expanded?: boolean; }) {
  const { t } = useTranslation();
  const click = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  return <div className={classesEB.expandingButton} onClick={click} >
    <div className={classesEB.textContainer}>
      <Email className={classesEB.plus}></Email>
      <span className={classesEB.text}>{t(props.label || '')}</span>
    </div>
  </div >;

}
