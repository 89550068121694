import React, { useEffect, useState } from 'react';
import classes from './../../styles/picture-upload.module.scss';
import mobileClass from './mobile-image-upload.module.scss';
import uploadIcon from '../../assets/svg/upload-photo.svg';
import { Section } from './Section';
import { ImagePicker } from './ImagePicker';
import { IValidateEffect } from '../../hooks/IValidateEffect';
import { Instruction } from '../../components/shared/Instruction';
import { FileUpload } from './FileUpload';
import { IImage, useImageUploader, imageKeys } from './ImageUploadWithPreview';
import { listing } from 'src/services/service-config';


export function MobileImageUpload(
    props: {
        onSelected?: (files: IImage) => void;
        onValidate?: (x: IValidateEffect) => void;
        selected?: IImage;
    }
) {


    const {
        selectedImageChanged,
        selectedImageRemoved,
        selectListing,
        uploadProgress,
        baseUrl } = useImageUploader(props.onSelected, props.onValidate, props.selected);

    const [imageList, setImageList] = useState(Object.keys(selectListing.images ?? {} as IImage).filter(i => selectListing.images?.[i]?.path));

    const imageRemoved = (key: string) => {
        selectedImageRemoved(key);
        resetImageList();
    };
    const mobileSelectedImage = (e: File) => {

        const remainder = imageKeys.filter(x => !imageList.includes(x.name));

        if (remainder.length === 0) {
            return;
        }

        const key = remainder[0].name;

        const newImageList = [
            ...imageList, key
        ];

        setImageList(newImageList);
        selectedImageChanged(key, e);
    };

    const resetImageList = () => {

        setImageList(Object.keys(selectListing.images ?? {} as IImage).filter(i => selectListing.images?.[i]?.path));
    };

    useEffect(() => {
        resetImageList();
    }, [listing]);

    return (
        <Section
            title="Photo Upload"
        >
            <div className={classes.pictureUploadContainer}>
                <div className={classes.uploadTitle}>
                    <Instruction text='The front image will be your display image for our marketplace, up to 8 images can be uploaded.' />
                </div>
                <div className={classes.fileSelectionContainer} role="figure" aria-label="Images that describe the condition of your card">

                    <div className={mobileClass.rainbowInputContainer} >
                        <FileUpload
                            name={'image-uploader'}
                            className={mobileClass.rainbowInput}
                            icon={<img src={uploadIcon} alt="upload" />}
                            onSelected={mobileSelectedImage}
                        ></FileUpload>
                    </div>
                    <Instruction text={`*${imageList.length}/8 uploaded`} className={mobileClass.imagesRemaining} />
                    <div className={mobileClass.uploadedFilesContainer}>
                        {imageKeys.filter(x => imageList.includes(x.name)).map((key, index) => {

                            return <ImagePicker
                                showTrash
                                key={index + key.name}
                                busy={uploadProgress?.[key.name]?.busy ?? false}
                                title={key.label}
                                name={key.name}
                                uploadProgress={uploadProgress?.[key.name]?.progress ?? 0}
                                placeholder={`${key.name}.png`}
                                selectedImageChanged={selectedImageChanged}
                                onRemoved={imageRemoved}
                                objectUrl={selectListing.images?.[key.name]?.path && baseUrl + selectListing.images?.[key.name]?.path}
                                value={selectListing.images?.[key.name]?.file}
                                role={`${key.name}-image-upload`} />;
                        })}
                    </div>
                </div>
            </div>
        </Section>
    );
}
