import React from 'react';
import classes from './tumbleweed.module.scss';
import tumbleweed from '../../assets/svg/tumbleweed.svg';

export function TumbleWeed(props: { message?: string }) {
  return (
    <div className={classes['empty-icon-container']}>
      <div className={classes['animation-container']}>
        <div
          className={classes['bounce']}
          style={{ backgroundImage: `url(${tumbleweed})` }}
        ></div>
        <div className={classes['pebble1']}></div>
        <div className={classes['pebble2']}></div>
        <div className={classes['pebble3']}></div>
      </div>
      <div>
        {/* <h2>0 results found</h2> */}
        {props.message ? (
          <p>{props.message}</p>
        ) : (
          <p>Sorry! We couldn&apos;t find any results.</p>
        )}
      </div>
    </div>
  );
}
