/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect } from 'react';


export function Img(props: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & { fallback?: string; name?: string; hires?: string; src?: string; }) {

    const imgRef = React.useRef<HTMLImageElement>(null);


    useEffect(() => {
        loadHighResImage();
    }, [props.hires]);


    function loadHighResImage() {

        if (props.hires) {
            const img = new Image();
            img.src = props.hires;

            img.onload = () => {
                if (img.complete && imgRef.current) {
                    imgRef.current!.src = props.hires ?? '';
                }
            };

            if (img.complete && imgRef.current) {
                imgRef.current!.src = props.hires ?? '';
            }
        }
    }

    function handleFallback(e: any) {
        e.target.onError = null;
        e.target.src = props.fallback;
    }

    return <img {...props} src={props.src} onError={handleFallback} ref={imgRef} />;
}
