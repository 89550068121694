import React from 'react';
import { Heading } from '../../../components/shared/Heading';
import { SearchBar } from 'src/components/SearchBar/SearchBar';

export function UserPageHeader(props: { className?: string; title: string; pageTitle: string; icon?: any; onSearchTextChanged?: (text: string) => void; showSearchBar?: boolean; searchBarPlaceholder?: string; }) {
  const Icon = () => props.icon;
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Heading color="#98FFF9">
        <div className="flex-row-left flex-column-gap-5">
          <Icon />
          {props.pageTitle}
        </div>
      </Heading>
      {
        props.showSearchBar && <SearchBar style={{ padding: '30px 0' }} onInputTextChanged={props.onSearchTextChanged} placeholder={props.searchBarPlaceholder ?? 'Search listings'}></SearchBar>
      }
      <div>
        <div style={{ padding: '0 0 10px 0', display: 'inline-block', lineHeight: 4 }}>
          <Heading color="white" text={props.title} />
        </div>
        <hr />
      </div>
    </div>
  );
}
