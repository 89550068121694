/* eslint-disable no-console */



export function initializeGlobals() {

    console.logIfDev = (...args: any[]) => ifDev(console.log)(...args);
    console.infoIfDev = (...args: any[]) => ifDev(console.info)(...args);
    console.tableIfDev = (...args: any[]) => ifDev(console.table)(...args);
    console.errIfDev = (...args: any[]) => ifDev(console.error)(...args);
    console.warnIfDev = (...args: any[]) => ifDev(console.warn)(...args);


}

export function ifDev(fnc: (...args: any[]) => void) {
    if (process.env.NODE_ENV !== 'production') {
        return fnc;
    }
    return () => {
        //no op
    };
}