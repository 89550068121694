// import Dexie, { Table } from 'dexie';

export interface Card {
  id: number;
  name: string;
  number: string;
  cardNumberValue: number;
  cardNumberValue2: number;
  series: string;
  set: string;
  rarity: string;
  elementType: string;
  cardType: string;
  releaseDate: Date;
  setCode: string;
  illustrator: string;
  abilityName1: string;
  abilityDescription1: string;
  abilityName2: string;
  abilityDescription2: string;
  weaknessType: string;
  weaknessAmount: number;
  resistType: string;
  resistAmount: number;
  evolutionStage: string;
  hitpoints: number;
  Pokemon: string;
}

export interface Manifest {
  id: number;
  name: string;
  syncDate: string;
  hasChanged: boolean;
}

// export class indexdbContext extends Dexie {
export class indexdbContext {
  // cards!: Table<Card>;
  // manifest!: Table<Manifest>;
  cards!: any;
  manifest!: any;

  constructor() {
    // super('MidnightMerchantDB');
    // this.version(1).stores({
    //   manifest: '++id',
    //   series: '++id, series', // Primary key and indexed props
    //   set: '++id, set', // Primary key and indexed props
    //   rarity: '++id, rarity', // Primary key and indexed props
    //   cardNumber: '++id, cardNumberValue', // Primary key and indexed props
    // });
  }
}

export const db = new indexdbContext();
