import React from 'react';
import { ReactComponent as Loading } from '../../assets/svg/loading.svg';
export function LoadingWidget(props: {
  isLoading: boolean;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}) {
  return props.isLoading ? (
    <item-loading>
      <Loading />
    </item-loading>
  ) : (
    <>{props.children}</>
  );
}
