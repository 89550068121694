import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Slices } from 'src/stores/slices';
import { IInsightsState } from './insightsState';

import { IAsyncPayload } from '../listing/IAsyncPayload';
import { ICardPagedResults } from './IPagedResults';
import { ICard } from '../createListing/models/iCard';



const initialState: IInsightsState = {
    searchResults: {
        isLoading: false,
        data: {} as ICardPagedResults
    },
    selectedCard: undefined,
    selectedCardDetails: undefined
};




export const cardSearchSlice = createSlice({
    name: Slices.insights,
    initialState: initialState,
    reducers: {
        /**
         *
         * @obsolete(2021-09-01) - This action is not used in the application 
         */
        setSelectedCardinsight: (state, action) => {
            state.selectedCard = action.payload;
        },
        setInsightCardSearchResults: (state, action: PayloadAction<IAsyncPayload<ICardPagedResults>>) => {
            state.searchResults = action.payload;
        },
        setInsightCardDetails: (state, action: PayloadAction<IAsyncPayload<ICard>>) => {
            state.selectedCardDetails = action.payload;
        }
    }
});

export default cardSearchSlice.reducer;
export const {
    setInsightCardSearchResults,
    setSelectedCardinsight,
    setInsightCardDetails
} = cardSearchSlice.actions;
