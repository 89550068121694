import React from 'react';
import { Information } from '../../components/shared/Instruction';
import { CustomElement } from '../../types/react-custom-tags';
import { InfoHelper } from './InfoHelper';

export function MockFeatureIndicator(props: CustomElement<JSX.Element>) {
  return (
    <mock-feature-indicator
      className={props.className}
      style={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: 20,
        ...props.style,
      }}
    >
      <InfoHelper
        anchor={
          <Information
            text="We dont have real data yet, so at the moment we show some test data to give you an idea of how it will look"
            style={{ color: 'yellow' }}
          />
        }
      >
        <Information
          text="*What? Some test data to show you a visual"
          color="white"
        />
        <br />
        <Information text="*Why? So you know whats coming!!" color="white" />
        <br />
        <Information
          text="*When? its complicated, it all depends on what the community has deemed as valuable, check out discord to get an idea of our roadmap "
          color="white"
        />
      </InfoHelper>
    </mock-feature-indicator>
  );
}
