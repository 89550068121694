import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import classes from '../../styles/listingComplete.module.scss';
import { Instruction } from 'src/components/shared/Instruction';
import { RainbowButton } from 'src/components/shared/Button';

export function ListingComplete() {

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [base, _, id, title, imageUrl, description] = location.pathname.split('/');
    const navigate = useNavigate();

    return <div className={classes.container}>
        <div className={classes.body}>
            <header className={classes.header}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', }}>
                    <Link to={'/'}>
                        < img src="http://img.mailinblue.com/4377656/images/65c32ffd4c5e6_1707290621.png"
                            alt="Midnight merchant logo" width="100px" />
                    </Link>
                    <div style={{ width: '100%', maxWidth: 800 }}>
                        <h1 >Your listing has been published</h1>
                        <Instruction>Your listing has been published but may take a few moments to appear on the Midnight Merchant website.</Instruction>
                        <Instruction>You can view your listing by clicking the button below.</Instruction>
                    </div>
                </div>
            </header >
            <section>
                <div className={classes.image}>
                    <img src={`${process.env.REACT_APP_IMAGES_URL}/${decodeURIComponent(imageUrl)}`} alt="listing image" style={{ height: 100 }} />
                </div>
                <div className={classes.content}>
                    <h2>{decodeURIComponent(title)}</h2>
                    {/* <p>{decodeURIComponent(description)} </p> */}
                    <p></p>
                    <div className={classes.buttonContainer}>
                        <RainbowButton variant='secondary-outline' className={classes.button} onClick={() =>
                            navigate(`/listing/${id}/product`)
                        }>
                            View Listing</RainbowButton>
                        <RainbowButton className={classes.button} onClick={() =>
                            navigate(`/edit/${id}`)
                        }

                        >Edit Listing</RainbowButton>
                        {/* <a aria-label='link' style={{ backgroundColor: '#00B1D8' }} href={`/listing/${id}/product`}>View listing</a>
                        <a aria-label='link' style={{ backgroundColor: '#EE8F00' }} href={`/edit/${id}`}>Edit listing</a> */}
                    </div >
                </div >
            </section >
        </div >
    </div>;

}


