import { debounce } from 'lodash';
import { error } from '../../actions/logActions';
import { IFeeTable } from '../../features/createListing/IFeeTable';
import { ReCaptchaAction } from '../../helpers/recaptcha';
import { getAsync } from '../baseService';

export interface IGetFeeTableServiceArgs {
    buyItNowPrice: number;
    intlShippingCharge?: number;
    domesticShippingCharge?: number;
    countryCode?: string;
}

const getFeeTable = debounce(async (args: IGetFeeTableServiceArgs) => {
    try {
        if (!process.env.REACT_APP_PP_FEE_TABLE)
            throw new Error('REACT_APP_PP_FEE_TABLE is not defined');

        const url = process.env.REACT_APP_PP_FEE_TABLE + `?amount=${args.buyItNowPrice}&localShipping=${args.domesticShippingCharge ?? 0}&intShipping=${args.intlShippingCharge ?? 0}&countryCode=${args.countryCode}`;
        const feeTable = await getAsync<{ feeTable: IFeeTable; }>(url, undefined, ReCaptchaAction.api_get, true);
        return feeTable.result?.feeTable as IFeeTable;
    } catch (er: any) {
        // eslint-disable-next-line no-console
        console.error(er);
        error('GET_FEE_TABLE_SERVICE_ERROR', { moduleName: 'getFeeTableService', currentUrl: window.location?.href, errorMessage: er?.message ?? er ?? 'Error getting fee table' });
    }

}, 100, { leading: true, trailing: false });


export async function getFeeTableService(args: IGetFeeTableServiceArgs) {
    return getFeeTable(args);
}