import React from 'react';
import classes from './Skeleton.module.scss';
import { randomQuotes } from '../../data/randomQuotes';
import { randomQuestions } from '../../data/randomQuestions';

const randomPhrases = [...randomQuotes, ...randomQuestions];

export function Skeleton(props: {
  skelType: 'btn' | 'txt' | 'shortTxt' | 'txtArea' | 'img' | 'lnk' | 'avatar';
  loading: boolean;
  children: JSX.Element;
  style?: React.CSSProperties;
  className?: string;
}) {
  const phrase = randomPhrases.shuffle();

  if (props.loading) {
    return (
      <div
        style={{
          ...props.style,
          animationDelay: `${[
            1, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000,
          ].shuffle()}ms`,
        }}
        className={`${classes.root} ${classes.shimmer} ${
          classes[props.skelType]
        } ${props.className ?? ''}`}
      >
        <span>{phrase}</span>
      </div>
    );
  } else {
    {
      return props.children;
    }
  }
}
