import React from 'react';
import classes from './../../styles/createListing.module.scss';
import { useAppDispatch, useAppSelector } from '../../hook';
import { Outlet, useNavigate } from 'react-router-dom';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import { StorageKey } from '../../stores/Storage';
import { StepperButtons } from '../../features/createListing/StepperButtons';

import { MobileHeaderBar } from '../../components/MobileHeaderBar';

import { CardSearchBar } from '../../components/SearchBar/CardSearchBar';
import { selectCardSearchParameters } from '../../features/cardSearch/cardSearchSelectors';
import { searchCardsAction } from '../../features';

import { routeConfig } from '../../routes/routeConfig';
import { useCreateListing } from './useCreateListing';
import { useMobileFooterBar } from '../useMobileFooterBar';

export default function CreateListingMobile() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    MobileFooterBar,
    // setShowActionBar,
    // setShowNotificationBar,
    // showActionBar,
    // showNotificationbar
  } = useMobileFooterBar();

  const {
    handleNext,
    handleBack,
    currentListing,
    state,
  } = useCreateListing();


  useSessionStorage(StorageKey.createListing, currentListing);
  const searchParameters = useAppSelector(selectCardSearchParameters);
  const onSearchBarCardSelected = async () => {
    if (searchParameters) dispatch(searchCardsAction());
  };



  return (
    <div className={classes.mobileRoot}>
      <MobileHeaderBar
        previousPageName={
          state?.previous().name === 'listing-type' ? 'Marketplace' :
            state?.previous()?.name
        }
        currentPage={state?.displayName}
        showTitle={state?.name !== 'search'}
        onBackClicked={async () => {
          state.previous()?.name === 'listing-type'
            ? navigate(routeConfig.searchResults.path)
            : await handleBack();
        }}
      >
        <div style={{ flexGrow: 1 }}>
          {state?.name === 'search' && (
            <CardSearchBar onSelected={onSearchBarCardSelected} />
          )}
        </div>
      </MobileHeaderBar>
      <div style={{ height: '100%', width: '100%', padding: 16 }}>
        <Outlet />
      </div>
      <div className={classes.stepperButtonContainer}>
        <StepperButtons
          previousProps={{
            isHidden: true,
          }}
          nextProps={{
            onClick: handleNext,
            label: 'Continue',
            disabled: !state?.validate?.(currentListing),
          }}
        ></StepperButtons>
      </div>
      {MobileFooterBar}
    </div >
  );
}
