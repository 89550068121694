import React from 'react';
import { HeaderBar } from './HeaderBar';

export function Main(props: { children?: JSX.Element | undefined; className?: string; }) {
  return (
    <div style={{ display: 'flex', height: '100%', flexDirection: 'column', alignItems: 'center' }}>
      <HeaderBar />
      <main-page class={props.className} style={{ width: '100%' }}>{props.children}</main-page>
    </div>
  );
}
