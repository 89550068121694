const mockImage1 =
  'https://images.collectorspoke.com.au/assets/pokemon/_Test%20Advert.png';

export const mockSellingAssets = [
  {
    asset: {
      id: '1',
      imageUrl: mockImage1,
      line1: 'Pikachu VMAX 1',
      line2: 'Scarlet and Violet',
      line3: 'Secret Rare',
      line4: '188/185',
      price: 10,
      purchaseDate: '2021-01-01',
    },
    actions: [{
      name: 'Leave Feedback',
      disabled: false
    }, {
      name: 'Contact Seller',
      disabled: true
    }
    ],
  },
  {
    asset: {
      id: '2',
      imageUrl: mockImage1,
      line1: 'Pikachu VMAX 2',
      line2: 'Scarlet and Violet',
      line3: 'Secret Rare',
      line4: '188/185',
      price: 10,
      purchaseDate: '2021-01-01',
    },
    actions: [{
      name: 'Leave Feedback',
      disabled: false
    }, {
      name: 'Contact Seller',
      disabled: true
    }
    ],
  },
  {
    asset: {
      id: '3',
      imageUrl: mockImage1,
      line1: 'Pikachu VMAX 3',
      line2: 'Scarlet and Violet',
      line3: 'Secret Rare',
      line4: '188/185',
      price: 10,
      purchaseDate: '2021-01-01',
    },
    actions: [{
      name: 'Leave Feedback',
      disabled: false
    }, {
      name: 'Contact Seller',
      disabled: true
    }
    ],
  },
  {
    asset: {
      id: '4',
      imageUrl: mockImage1,
      line1: 'Pikachu VMAX 4',
      line2: 'Scarlet and Violet',
      line3: 'Secret Rare',
      line4: '188/185',
      price: 10,
      purchaseDate: '2021-01-01',
    },
    actions: [{
      name: 'Leave Feedback',
      disabled: false
    }, {
      name: 'Contact Seller',
      disabled: true
    }
    ],
  },
];

export const mockPurchasedAssets = [
  {
    asset: {
      id: '1',
      imageUrl: mockImage1,
      line1: 'Pikachu VMAX 1',
      line2: 'Scarlet and Violet',
      line3: 'Secret Rare',
      line4: '188/185',
      price: 10,
      purchaseDate: '2021-01-01',
    },
    actions: ['Contact Seller'],
    trackingNumber: 'RSA109090938098',
    trackingCompany: 'StarTrack',
  },
  {
    asset: {
      id: '2',
      imageUrl: mockImage1,
      line1: 'Pikachu VMAX 2',
      line2: 'Scarlet and Violet',
      line3: 'Secret Rare',
      line4: '188/185',
      price: 10,
      purchaseDate: '2021-01-01',
    },
    actions: ['Contact Seller'],
    trackingNumber: 'RSA109090938098',
    trackingCompany: 'StarTrack',
  },
  {
    asset: {
      id: '3',
      imageUrl: mockImage1,
      line1: 'Pikachu VMAX 3',
      line2: 'Scarlet and Violet',
      line3: 'Secret Rare',
      line4: '188/185',
      price: 10,
      purchaseDate: '2021-01-01',
    },
    actions: ['Contact Seller'],
    trackingNumber: 'RSA109090938098',
    trackingCompany: 'StarTrack',
  },
  {
    asset: {
      id: '4',
      imageUrl: mockImage1,
      line1: 'Pikachu VMAX 4',
      line2: 'Scarlet and Violet',
      line3: 'Secret Rare',
      line4: '188/185',
      price: 10,
      purchaseDate: '2021-01-01',
    },
    actions: ['Contact Seller'],
    trackingNumber: 'RSA109090938098',
    trackingCompany: 'StarTrack',
  },
];
