import React from 'react';
import { useEffect, useState } from 'react';


class MockIntersectionObserver {
    root: Element | null;
    rootMargin: string;
    thresholds: number[];
    callback: IntersectionObserverCallback;
    observer: any;

    constructor (callback: IntersectionObserverCallback) {
        this.root = null;
        this.rootMargin = '';
        this.thresholds = [];
        this.callback = callback;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.observer = {
            root: null,
            rootMargin: '',
            thresholds: [],
            disconnect(): void { /* noop */ },
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            observe(target: Element): void { /* noop */ },
            takeRecords(): void { /* noop */ },
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            unobserve(target: Element): void {/* noop */ }

        };
    }

    observe(target: Element) {
        this.observer.observe(target);
    }

    unobserve(target: Element) {
        this.observer.unobserve(target);
    }
}

// mainly used for testing
function intersectionObserverMockReplacement(func: (entries: any) => void) {
    if (!('IntersectionObserver' in window)) {
        return new MockIntersectionObserver(func);
    }
    return new IntersectionObserver(func);
}


export function withIntersectionObserver(callback: () => void, isLoading: boolean) {

    return intersectionObserverMockReplacement(async (entries) => {
        for (const entry of entries) {
            if (entry.isIntersecting && !isLoading) {
                await callback();
            }
        }
    });
}

export function useIntersectionObserver(elementRef: React.RefObject<HTMLElement>, isLoading: boolean, callback: () => void) {

    const [itemRef] = useState(elementRef);

    useEffect(() => {
        if (itemRef.current) {
            const targetNode = itemRef.current;

            const intersectionObserver = withIntersectionObserver(callback, isLoading);
            intersectionObserver.observe(targetNode);

            return () => {
                intersectionObserver.unobserve(targetNode);
            };
        }
    }, [itemRef]);
}
