import React, { CSSProperties } from 'react';
import classes from '../../styles/LineGraph.module.scss';
import { ReactComponent as Spinner } from '../../assets/svg/spinner1.svg';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
  LineController,
  BarController,
  LineElement,
  BarElement,
  ChartData,
  ChartOptions,
  plugins,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { useAppSelector } from '../../hook';


ChartJS.register(
  LineController,
  BarController,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  BarElement,
  plugins
);

const chartAreaBorder = {
  id: 'chartAreaBorder',
  beforeDraw(chart: any) {
    const {
      ctx,
      chartArea: { left, right, top, bottom, width },
    } = chart;
    ctx.save();
    ctx.strokeStyle = 'white';
    ctx.lineWidth = 1;
    ctx.beginPath();
    ctx.moveTo(left, top);
    ctx.lineTo(left, bottom);
    ctx.lineTo(left + width, bottom);
    ctx.lineTo(right, top);
    ctx.stroke();
    ctx.restore();
  },
};

const legendSpacing = {
  id: 'legendSpacing',
  beforeInit(chart: any) {
    // Get reference to the original fit function
    const originalFit = chart.legend.fit;

    // Override the fit function
    chart.legend.fit = function fit() {
      // Call original function and bind scope in order to use `this` correctly inside it
      originalFit.bind(chart.legend)();
      // Change the height as suggested in another answers
      this.height += 10;
    };
  },
};

export const options: ChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
      labels: {
        boxWidth: 10,
        color: 'white',
        font: {
          family: 'Roboto',
          size: 12,
        },
      },
    },
  },
  scales: {
    y: {
      ticks: {
        callback: function (value) {
          return '$' + value;
        },
        color: 'white',
        font: {
          family: 'Roboto',
          size: 10,
        },
      },
      grid: {
        display: false,
      },
    },
    x: {
      ticks: {
        color: 'white',
        font: {
          family: 'Roboto',
          size: 8,
        },
        maxTicksLimit: 10,
        maxRotation: 0,
        minRotation: 0,
      },
      grid: {
        display: false,
      },
    },
  },
  interaction: {
    axis: 'x',
    intersect: false,
  },
};

export function LineGraph(props: { className?: string; style?: CSSProperties; data?: ChartData; isLoading?: boolean; }) {
  const graphData = useAppSelector((state) => state.listing.marketAnalyticsGraphData);

  const isLoading = graphData?.isLoading;
  return isLoading ? <div style={{ display: 'flex', height: '100%', justifyContent: 'center' }}><Spinner style={{ fill: '#ffffff40', height: 80 }} /></div>
    : <>
      {graphData?.data?.datasets?.filter((i: any) => i.data.length > 0).length > 0 ? (
        <div style={{ padding: '15px 0', height: '100%' }}>
          <Chart
            data={graphData?.data ?? []}

            type="line"
            options={options}
            className={`${props.className ?? ''} ${classes.root}`}
            plugins={[chartAreaBorder, legendSpacing]}
            style={
              props.style ?? {
                aspectRatio: 'auto',
                height: '100%',
                width: '100%',
              }
            }
            height="unset"
            width="unset"
          />
        </div>
      ) : (
        <div className={classes.noData}>No Market data available</div>
      )}
    </>;
}
