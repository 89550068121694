
import React from 'react';
import { useAppDispatch } from '../../hook';
import { setListingTitle } from './store/createListingSlice';
import { useSelector } from 'react-redux';
import { Heading } from '../../components/shared/Heading';
import classes from '../../styles/listing-title.module.scss';
import { Instruction } from '../../components/shared/Instruction';
import TextField from '@mui/material/TextField';
import { selectListingTitle } from './createListingSelectors';



export function ListingTitle() {
    const dispatch = useAppDispatch();
    const value = useSelector(selectListingTitle);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setListingTitle(e.target.value));
    };

    return (
        <div className={classes.title}>
            <Heading text='Create Listing Title' />
            <Instruction text='Enter a title for your listing' />
            <div className={classes.titleInput}>
                <TextField placeholder='' label="Title" onChange={onChange} value={value} size='small' fullWidth variant='outlined' />
            </div>
        </div>

    );
}