import React from 'react';
import { getMessageQuery } from '../../../data/gql/messageQueries';
import { useApolloQuery } from '../../../useApolloQuery';
import classes from '../../../styles/components/messages.module.scss';
import { UserPageHeader } from '../components/UserPageHeader';


import { RainbowButton, RainbowInput } from '../../../components/shared/Button';
import { timeAgo } from '../../../utils/utilities';
import { useAuthHook } from '../../../shared/hooks/useAuthHook';
import { useSnackbar } from 'src/shared/hooks/snackbar';
import { IMessage } from 'src/components/MessageDialog';
import { sendMessage } from '../buyer/buyerActions';
import { useAppDispatch } from 'src/hook';

export function Messages() {
    const { user } = useAuthHook();
    const [selectedMessageIndex, setSelectedMessageIndex] = React.useState<number>();
    const { data, error, loading } = useApolloQuery(getMessageQuery.query, { userId: btoa(user?.email ?? '') });
    const { enqueue, snackbar } = useSnackbar();
    const dispatch = useAppDispatch();
    if (loading)
        return <div className={classes.message}>Loading...</div>;

    if (error)
        return <div className={classes.error}>Error! {error.message}</div>;

    const isUserSender = (sender: string) => {
        try {
            return atob(sender ?? 'IA==') == user?.email ? true : false;
        } catch (e) {
            return false;
        }
    };

    const onMessageSubmit = async () => {

        const m = getSelectdMessage(selectedMessageIndex || 0);
        if (!m.listingId) {
            enqueue('error', 'We ran into a problem submitting your message, please try again later, or come chat to us on discord.');
        }

        const message: IMessage = {
            body: 'test',
            listingId: m?.listingId,
            recipient: atob(m.senderId),
            sender: btoa(user?.email ?? ''),
            id: m.listingId,
        };

        // console.log(m);
        const response = await dispatch(sendMessage(message));

        if (response.meta.requestStatus === 'fulfilled') {
            enqueue('success', 'Your message has left the building 👍🏛️');
        }

    };

    const alignMessage = (sender: string) => {
        return isUserSender(sender) ? classes.alignRight : classes.alignLeft;
    };

    const fromUser = (sender: string) => {
        try {
            return isUserSender(sender) ? 'me' : atob(sender);
        } catch (e) {
            return isUserSender(sender) ? 'me' : sender;
        }
    };

    const fromWhoClass = (sender: string) => {
        const d = classes.sender;
        try {
            return atob(sender ?? 'IA==') == user?.email ? classes.me : classes.sender;
        } catch (e) {
            return d;
        }
    };

    function handleBack() {
        setSelectedMessageIndex(undefined);
    }

    const getSelectdMessage = (index: number) => {
        return data?.listingMessages?.[index];
    };

    if (selectedMessageIndex == undefined)
        return <div className={classes.messages}>
            <UserPageHeader title="My Messages" pageTitle="Messages" />
            {data?.listingMessages?.map((message: any, index: number) =>
                <div key={index} className={classes.thread}>
                    <span className={classes.title} onClick={() => setSelectedMessageIndex(index)}>
                        <img src={`${process.env.REACT_APP_IMAGES_URL}/${message.imgUrl}`} alt='thread-image' />{message.title ?? 'Untitled'}</span>
                </div>)}

        </div>;

    else {
        const message = getSelectdMessage(selectedMessageIndex);
        return <div className={classes.messages}>
            <UserPageHeader title={`From: ${atob(message.senderId)}`} pageTitle="Messages" />
            <div className={classes.selectedMessage}>
                <div style={{ padding: '8px 0' }}>
                    <a aria-label='link' href="#" onClick={handleBack}>{'<'} Back to messages</a>
                </div>
                <div className={classes.thread}>
                    {message.messages?.map((m: any, i: number) =>
                        <div className={`${classes.messagesContainer} ${alignMessage(m.sender)}`} key={i}>
                            <div className={`${classes.message} ${fromWhoClass(m.sender)}`} >
                                <label>{fromUser(m.sender)}</label>
                                <div className={classes.text}>{m.body}</div>
                                <div className={classes.date}>{timeAgo((+m.timestamp))}</div>
                            </div>
                        </div>
                    )}
                </div>

                <div className={classes.inputTextContainer}>
                    <RainbowInput variant='outlined' placeholder='Start typing' multiline fullWidth className={classes.inputText} />
                    <RainbowButton className={classes.inputButton} onClick={() => onMessageSubmit()}> {' Send '} </RainbowButton>
                </div>
            </div>
            {snackbar}
        </div>;
    }

}