/* eslint-disable no-useless-escape */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';

import { Filter } from '../../models/filter';

import {
  FilterGroups,
  ISetSelectedFilter,
} from '../../models/filterGroup';

import { ISearchResultItem } from '../../models/iSearchResultItem';
import { IListingSearchState } from '../../models/iListingSearchState';
import { getFilters, searchListings } from './searchActions';

import { IFacetMapGroup } from '../../actions/iFacet';
import { IGraphSearchResult } from '../../services/search-service/SolrSearchResults';
import { ISearchParameters } from '../../actions/iListingSearchParameters';
import { clearDirtyFilters, setFilterCounts } from '../../services/search-service/searchUtilities';
import { Slices } from '../../stores/slices';
import { IAutocompleteResult } from '../../models/iCardSearchState';

const clearFilterThunk = (
  state: WritableDraft<IListingSearchState>,
  action: PayloadAction<string[]>
) => {
  const filterToClear = action.payload.reduce((p: Filter, c: string) => {
    return p.filters[c];
  }, state.filterData || {} as Filter);

  clearFilters(filterToClear);

  function clearFilters(filter: Filter) {
    filter.selected = false;

    Object.keys(filter.filters).forEach((i) => clearFilters(filter.filters[i]));
  }
};

const intitialState: IListingSearchState = {
  value: 0,
  listingSearchResults: [],
  autoCompleteResults: [],
  totalCards: 0,
  totalSearchResults: 0,
  showFilterDialog: false,
  filterData: { filters: {} } as Filter,
  activeFilter: [],
  facets: {} as IFacetMapGroup,
  isLoadingCards: false,
  isLoadingSearchFilters: false,
  isLoadingAutocomplete: false
};

export const listingSearchSlice = createSlice({
  name: Slices.listing,
  initialState: intitialState,
  reducers: {

    setIsLoadingListings: (state, action: PayloadAction<boolean>) => {
      state.isLoadingCards = action.payload;
    },
    setIsLoadingAutocomplete: (state, action: PayloadAction<boolean>) => {
      state.isLoadingAutocomplete = action.payload;
    },
    setIsLoadingFilters: (state, action: PayloadAction<boolean>) => {
      state.isLoadingSearchFilters = action.payload;
    },
    setActiveFilter: (state, action: PayloadAction<Array<string>>) => {
      state.activeFilter = action.payload;
    },
    setShowFilterDialog: (state, action: PayloadAction<boolean>) => {
      state.showFilterDialog = action.payload;
    },
    setAutoCompleteSearchResults: (state, action: PayloadAction<Array<IAutocompleteResult>>) => {
      state.autoCompleteResults = action.payload;
    },
    setListingSearchString: (state, action: PayloadAction<string>) => {
      if (!state.listingSearchParameters)
        state.listingSearchParameters = {} as ISearchParameters;

      state.listingSearchParameters.autoCompleteSearchString = action.payload;
    },
    setListingPageNumber: (state, action: PayloadAction<number>) => {
      if (!state.listingSearchParameters)
        state.listingSearchParameters = {} as ISearchParameters;

      state.listingSearchParameters.currentPage = action.payload;
    },
    setFilters: (state, action: PayloadAction<Filter>) => {
      state.filterData = action.payload;
    },
    setSolrSearchFilter: (state, action: PayloadAction<string>) => {
      if (!state.listingSearchParameters)
        state.listingSearchParameters = {} as ISearchParameters;

      state.listingSearchParameters.filterString = action.payload;
    },
    setTopLevelCategoriesSelectedCount: (state, action: PayloadAction<Array<string>>) => {
      state.selectedCategories = action.payload;
    },
    setStateFilterCounts: (state, action: PayloadAction<Filter>) => {
      setFilterCounts(action.payload.filters, state.filterData.filters);
      // console.log(JSON.stringify(state.filterData, null, 5));
    },
    setDirtyFilters: (state, action: PayloadAction<boolean>) => {
      state.filterData.isDirty = action.payload;
      const setIsDirty = (filter: Filter) => Object.keys(filter.filters)
        .forEach((key: string) => {
          filter.filters[key].isDirty = action.payload;
          setIsDirty(filter.filters[key]);
        });

      setIsDirty(state.filterData);

    },
    clearUncommittedFilters: (state) => {
      clearDirtyFilters(state.filterData);
    },
    setSelectedFilters: (state, action: PayloadAction<ISetSelectedFilter>) => {
      const isDirty = !action.payload.autoCommit;

      // if a child or nested child is true then the parent hierarchy must also be true
      // if all children and nested children are false then the parent must also be false.
      const nestedFilterIsSelected: (f: FilterGroups) => boolean = (f: FilterGroups) => {
        return Object.keys(f).some(i => f[i].selected || nestedFilterIsSelected(f[i].filters));
      };


      // Fetch the target filter and set the selected value of parent and children to true
      const filter = action.payload.path.reduce((p: WritableDraft<Filter>, c: string) => {
        if (p?.filters && action.payload.checked) {
          p.filters[c].selected = true;
          p.filters[c].isDirty = isDirty;
        } else {
          if (!nestedFilterIsSelected(p.filters[c].filters)) {
            p.filters[c].selected = false;
            p.filters[c].isDirty = isDirty;
          }
        }
        return p?.filters && p.filters[c];
      }, state.filterData || {} as Filter);


      if (filter) {
        filter.selected = action.payload.checked;
        if (filter.filters) {
          Object.keys(filter.filters).forEach((i) => {
            filter.filters[i].selected = false;
            filter.filters[i].isDirty = isDirty;
          });
        }
      }
      // console.log(JSON.stringify(filter, null, 5));

    },

    clearSelectedFilters: (state, action: PayloadAction<string[]>) => clearFilterThunk(state, action),
    clearAllFilters: (state) => {

      const clearF = (filters: WritableDraft<FilterGroups>) => Object.keys(filters).reduce((acc, filter) => {
        acc[filter].selected = false;
        acc[filter].isDirty = false;
        if (acc[filter].filters) {
          clearF(acc[filter].filters);
        }
        return acc;
      }, filters);

      clearF(state.filterData.filters);

    },
    setSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
    },
    setSearchResults: (state, action: PayloadAction<ISearchResultItem[]>) => {
      state.listingSearchResults = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getFilters.fulfilled, (state, action: PayloadAction<Filter>) => {
      state.filterData.filters = action.payload.filters;
    });
    builder.addCase(getFilters.rejected, (state, action: PayloadAction<any>) => {
      // eslint-disable-next-line no-console
      console.error(action.payload);
    });
    builder.addCase(
      searchListings.fulfilled,
      (state, action: PayloadAction<IGraphSearchResult>) => {
        state.listingSearchResults = action.payload?.records || [];

        if (!state.totalSearchResults && !state.totalCards) {
          state.totalCards = action.payload.totalRecords || 0;
        }
        state.totalSearchResults = action.payload.totalRecords || 0;
      }
    );
    builder.addCase(
      searchListings.rejected,
      (state) => {
        state.listingSearchResults = [];
      }
    );
  },
});

export const {
  setSelectedFilters,
  clearSelectedFilters,
  clearAllFilters,
  setShowFilterDialog,
  setSolrSearchFilter,
  setActiveFilter,
  setListingSearchString,
  setSearchResults,
  setSearchTerm,
  setFilters,
  setDirtyFilters,
  setIsLoadingAutocomplete,
  setAutoCompleteSearchResults,
  setIsLoadingListings,
  setIsLoadingFilters,
  setTopLevelCategoriesSelectedCount,
  clearUncommittedFilters,
  setListingPageNumber,
  setStateFilterCounts
} = listingSearchSlice.actions;

export default listingSearchSlice.reducer;


