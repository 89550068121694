import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ListingType } from '../../../../components/tiles/Tiles';
import { useAppDispatch, useAppSelector } from '../../../../hook';
import useMedia from 'react-use/lib/useMedia';
import { IListing } from '../../../../models/create-listing/iListing';
import { routeConfig } from '../../../../routes/routeConfig';
import { CardSearchMobile } from '../../../../views/createListing/CardSearchMobile';
import { CardSearch } from '../../../cardSearch/CardSearch';
import { selectCurrentListing } from '../../createListingSelectors';
import { ICard } from '../../models/iCard';
import { setCurrentListingStep, setSelectedCard } from '../../store/createListingSlice';


export function CardSearchPage() {
    const isMobile = useMedia('(max-width: 800px)');
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const selected: IListing = useAppSelector(selectCurrentListing);

    const onSelected = (card: ICard) => {
        dispatch(setSelectedCard(card));
        dispatch(setCurrentListingStep('condition'));
        navigate(routeConfig.createListing.condition);
    };

    return isMobile
        ? <CardSearchMobile advertType={ListingType.singleCard} onSelected={onSelected} selected={selected.card}></CardSearchMobile>
        : <CardSearch advertType={ListingType.singleCard} onSelected={onSelected} selected={selected.card}></CardSearch>;

}


