import React, { useEffect } from 'react';
import { useIntersectionObserver } from './useIntersectionObserver';

export interface ILoadMoreProps<TResultType> {
    load: () => TResultType;
    loadMore: () => TResultType;
    hasMore: boolean;
    isLoading: boolean;
}


export function LoadMore(props: { isLoading: boolean; hasMore: boolean; loadMore: () => void; }) {
    const element = React.useRef<HTMLDivElement>(null);
    const [isLoading, setIsLoading] = React.useState(props.isLoading);

    const [showElement, setShowElement] = React.useState(false);

    useEffect(() => {
        setIsLoading(props.isLoading);

        if (!props.isLoading) {
            setTimeout(() => {
                setShowElement(true);
            }, 1000);
        } else {
            setShowElement(false);
        }
    }, [props.isLoading]);

    useIntersectionObserver(element, isLoading, props.loadMore);

    return <>
        <div style={{ display: showElement ? 'initial' : 'none', marginTop: 'auto' }} ref={element}></div>
        {isLoading && <div>Loading more....</div>}
    </>;



}
