
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';

const reactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({
    config: {
        connectionString: process.env.REACT_APP_AI || '',
        enableAutoRouteTracking: true,
        extensions: [ reactPlugin ]
    }

});

appInsights.loadAppInsights();

export { appInsights, reactPlugin, withAITracking };