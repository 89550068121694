import { useState, useEffect, } from 'react';

type StateFunction = <TElementType extends HTMLElement>(callback: () => Promise<void>, ref?: React.RefObject<TElementType>) => void;

const useInfiniteScroll: StateFunction = <TElementType extends HTMLElement>(callback: () => Promise<void>, ref?: React.RefObject<TElementType>) => {
    const [isFetching, setIsFetching] = useState(false);
    useEffect(() => {
        if (!ref) {
            window.addEventListener('scroll', isScrolling);
        }
        else
            ref.current?.addEventListener('scroll', isScrolling);

        return () => {
            ref?.current?.removeEventListener('scroll', isScrolling);
            window.removeEventListener('scroll', isScrolling);
        };

    }, [ref?.current]);

    useEffect(() => {

        if (!isFetching) return;

        callback().
            finally(() => {
                setIsFetching(false);
            });

    }, [isFetching]);

    function isScrolling() {
        if (ref?.current) {
            if (ref?.current.scrollTop >= ref?.current.scrollHeight - ref?.current.offsetHeight - 200 || isFetching) {
                setIsFetching(true);
            }
        } else {
            if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.scrollHeight - document.documentElement.offsetHeight - 25 || isFetching) {
                setIsFetching(true);
            }
        }
    }
};

export default useInfiniteScroll;