import React, { useCallback } from 'react';
import classes from '../../styles/card-grading.module.scss';
import { gradesList, subGradeOptions } from '../../data/grades';
import { useState } from 'react';
import { ActiveRadioGroup } from '../../components/shared/RadioButton';
import { IOption } from '../../components/shared/AutoComplete';
import { Select } from '../../components/shared/Select';
import { ISubgrade } from '../../models/create-listing/ISubgrade';
import { Grade } from '../../models/create-listing/Grade';
import { Instruction } from '../../components/shared/Instruction';

export function CardGrading(props: {
  onGradeSelected?: (grade: Grade) => void;
  onSubgradeSelected: (key: string, option?: IOption) => void;
  onHasSubGradesSelected?: (hasSubGrades: string) => void;
  selectedGrade?: Grade;
  selectedGradingCompany?: string;
  selectedSubgrade?: ISubgrade;
  hasSubgrades?: string;
}) {

  const [grades] = useState(gradesList);

  const onHasSubgradeSelected = (hasSubGrades: string) => {
    props.onHasSubGradesSelected?.(hasSubGrades);
  };

  const onSubgradeSelected = (key: string, option?: IOption) => {
    props.onSubgradeSelected?.(key, option);
  };

  const getSelectedSubGrade = (key: string) => {
    const option = subGradeOptions.find(i => i.value === props.selectedSubgrade?.[key as keyof ISubgrade]);

    if (option)
      return ({ count: 0, id: `${option.value}`, label: option.title, value: option.value });

  };

  const onGradeSelected = (grade: Grade) => {
    const newG = { ...grade, children: undefined };

    props.onGradeSelected?.(newG);
  };


  const SubGrades = useCallback(() => {
    return props.hasSubgrades ?
      <>
        <Instruction text="Select your subgrades" color={'FFFFFF40'} />
        <div style={{ display: 'flex', columnGap: 50, fontSize: 14, letterSpacing: 0.5 }}>
          <Select selected={getSelectedSubGrade('Centering')} onChange={(option?: IOption) => onSubgradeSelected('Centering', option)} title="Centering" options={subGradeOptions.map(i => ({ count: 0, id: `${i.value}`, label: i.title, value: i.value }))} ></Select>
          <Select selected={getSelectedSubGrade('Surface')} onChange={(option?: IOption) => onSubgradeSelected('Surface', option)} title="Surface" options={subGradeOptions.map(i => ({ count: 0, id: `${i.value}`, label: i.title, value: i.value }))} ></Select>
          <Select selected={getSelectedSubGrade('Corners')} onChange={(option?: IOption) => onSubgradeSelected('Corners', option)} title="Corners" options={subGradeOptions.map(i => ({ count: 0, id: `${i.value}`, label: i.title, value: i.value }))} ></Select>
          <Select selected={getSelectedSubGrade('Edges')} onChange={(option?: IOption) => onSubgradeSelected('Edges', option)} title="Edges" options={subGradeOptions.map(i => ({ count: 0, id: `${i.value}`, label: i.title, value: i.value }))} ></Select>
        </div>
      </> : <></>;
  }, [subGradeOptions, props.hasSubgrades, props.selectedSubgrade]);


  return <>
    <Instruction text="Select your card grade" color="white" italic={false} />
    <GradingMenu selected={props.selectedGrade} onSelected={onGradeSelected} options={grades.filter((i) => i.company === props.selectedGradingCompany)} />
    <br></br>
    <br></br>
    <br></br>

    {props.selectedGrade && <>
      <Instruction text="Does your graded card have subgrades?" color="white" italic={false} />
      <ActiveRadioGroup
        row
        value={props.hasSubgrades}
        onChange={(e) => onHasSubgradeSelected(e.target.value)}
        options={[
          { id: 'yes', value: 'true', label: 'Yes' } as IOption,
          { id: 'no', value: 'false', label: 'No' } as IOption
        ]
        } />
    </>}
    {props.hasSubgrades && <SubGrades />}

    <br></br>
    <br></br>
    <br></br>

  </>;

}

export function GradingMenu(props: {
  onSelected?: (item: Grade) => void;
  selected?: Grade;
  options: Grade[];
}) {



  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selected, setSelected] = useState<Grade | undefined>(props.selected);

  const onSelected = async (_: React.MouseEvent<HTMLElement>, grade: Grade) => {
    setSelected(grade);
    props.onSelected?.(grade);
  };

  const isSelectedAndOpen = (grade: Grade) => {

    if (grade.value == selected?.value) {
      return classes.selected;
    }

    if (grade.children && grade.children.some(i => i.value == selected?.value)) {
      return classes.selected;
    }

    return '';
  };

  const isSelected = (grade: Grade) => {
    if (grade.value == selected?.value) {
      return classes.selected;
    }
    return '';
  };


  return <div className={classes.gradesContainer}>
    <div className={classes.gradesHalfgrades}>
      {props.options
        .map((i, index) =>
          <div key={`${i.value}-${index}`} className={`${classes.dropdown} ${isSelectedAndOpen(i)}`}>
            <div className={classes.gradeContainer}>
              <div className={`${classes.grade} ${classes.text} ${isSelected(i)}`} onClick={(e) => onSelected(e, i)}>{i.title}</div>
              <div className={classes.halfGrades}>
                {i.children?.map((c, index) => <span key={index} className={`${classes.text} ${isSelected(c)}`} onClick={(e) => onSelected(e, c)}>{c.title}</span>)}
              </div>
            </div>
          </div>)}
    </div>
  </div>;
}
