import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getLocalManifest, getRemoteManifest } from './dataActions';

import { Card, Manifest } from './db';

export interface IDataState {
  activeManifest: Manifest[];
  remoteManifest: Manifest[];
  cards?: Card[];
}

const intitialState: IDataState = {
  activeManifest: [{ id: 0, name: '', syncDate: '', hasChanged: false }], //TODO get manifest from database
  remoteManifest: [],
};

export const dataSlice = createSlice({
  name: 'data',
  initialState: intitialState,
  reducers: {
    selectActiveManifest: (state, action) => {
      state.activeManifest = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getRemoteManifest.fulfilled,
      (state, action: PayloadAction<Manifest[]>) => {
        state.remoteManifest = action.payload;
      }
    );
    builder.addCase(
      getLocalManifest.fulfilled,
      (state, action: PayloadAction<Manifest[]>) => {
        state.activeManifest = action.payload;
      }
    );
  },
});


export default dataSlice.reducer;
