import React from 'react';
import { CSSProperties } from 'react';
import { Translation } from '../../locales/translation';
import styles from '../../styles/sectionheader.module.scss';



export function Section(props: {
  title: string | JSX.Element | Translation;
  subTitle?: string | JSX.Element | Translation;
  className?: string;
  titleClassName?: string;
  subTitleClassName?: string;
  titleStyle?: CSSProperties;
  subTitleStyle?: CSSProperties;
  children?: JSX.Element | JSX.Element[];
  hide?: boolean;
  invalid?: boolean;
}) {
  return !props?.hide ? (
    <section
      className={`${styles.section} ${props.className ? props.className : ''} ${props.invalid ? styles.invalid : ''}`}
    >
      <div
        style={props.titleStyle}
        className={`${styles.titleClassName} ${props.titleClassName ? props.titleClassName : ''
          }`}
      >
        {props.title as any}
      </div>
      <div
        style={props.subTitleStyle}
        className={`${styles.subTitleClassName} ${props.subTitleClassName ? props.subTitleClassName : ''
          }`}
      >
        {props.subTitle as any}
      </div>
      {props.children}
    </section>
  ) : (
    <></>
  );
}
