import React, { ReactNode, useEffect, useState } from 'react';
import { ReactComponent as QuestionMarkIcon } from '../../assets/svg/question-mark.svg';

export function InfoHelper(props: { children: ReactNode; anchor: ReactNode }) {
  const [showContent, setShowContent] = useState<boolean>(false);
  useEffect(() => {
    document.removeEventListener('click', () => setShowContent(false));
    document.addEventListener('click', () => setShowContent(false));
  }, []);

  const onShowContent = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    setShowContent(!showContent);
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        columnGap: 10,
        alignItems: 'flex-start',
        position: 'relative',
        width: '600px',
        marginLeft: 10,
        paddingLeft: 5,
      }}
    >
      <div
        onClick={onShowContent}
        style={{
          border: '1px solid #98FFF9',
          borderRadius: '50%',
          padding: 3,
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <QuestionMarkIcon style={{ height: 12, width: 12, fill: '#98FFF9' }} />
      </div>
      <span style={{ maxWidth: 500 }}>{props.anchor}</span>
      {showContent && (
        <div
          style={{
            lineHeight: 3,
            position: 'absolute',
            top: 60,
            backgroundColor: '#000000',
            zIndex: 1,
            padding: 15,
            borderRadius: 10,
          }}
        >
          {props.children}
        </div>
      )}
    </div>
  );
}
