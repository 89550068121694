import React from 'react';
import { ImageUploadWithPreview } from '../../ImageUploadWithPreview';
import { MobileImageUpload } from '../../MobileImageUpload';
import { useMedia } from 'react-use';



export function CardImageUploadPage() {
    const isMobile = useMedia('(max-width: 768px)');

    const ImageUpload = isMobile ? MobileImageUpload : ImageUploadWithPreview;

    return <ImageUpload />;
}
