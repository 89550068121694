import { useAuth0 } from '@auth0/auth0-react';

export function useAuthHook() {
  const {
    logout,
    user,
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();

  return {
    logout,
    user,
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently,
  };
}
