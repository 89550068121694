/* eslint-disable no-useless-escape */
import { IFacet } from '../actions/iFacet';
import { IFacetGroup } from '../actions/iFacetGroup';
import { Filter, IFilterCategoryMap } from '../models/filter';
import { FilterGroups } from '../models/filterGroup';
import Moment from 'moment';

type ToFilter = (facet?: IFacet, categories?: IFilterCategoryMap) => Filter;

export type Nullable<T> = {
    [P in keyof T]: T[P] | null;
};

interface IMappers {
    toFilter: ToFilter;
}

const mappers: IMappers = {
    toFilter: (facet?: IFacet, map?: IFilterCategoryMap) => {

        if (!facet)
            return {} as Filter;

        try {
            const path: string[] = [];
            const filter = mapFacetToFilter(facet, 'filters', 'filters', 'filters', path, map ?? {} as IFilterCategoryMap);
            return filter;
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
        }
        return {} as Filter;
    }
};
type MapFaceteToDAO = (facet: IFacet, type: string, groupName: string, key: string, path: string[], categories: IFilterCategoryMap) => Filter;


function getKeys(facet: IFacet, childType: string[], path: string[], map: IFilterCategoryMap) {

    const reduceFilters = (p: FilterGroups, c: string) => {
        const propertyName = formatters.fromSpaceToUnderscore(c);
        const facetGroup = facet[c] as IFacetGroup;
        const filteredIndex = getFilterIndex(c, facetGroup, c, propertyName, [...path, c], facetGroup.buckets.length, map);
        childType.push(c);

        if (Object.keys(filteredIndex.filters).length > 0)
            filteredIndex.isRoot = true;

        p[c] = filteredIndex;
        return p;
    };

    if (!facet) {
        throw new Error('facet is undefined');
    }

    return Object.keys(facet)
        .filter(k => !['val', 'count'].includes(k))
        .reduce(reduceFilters, {} as FilterGroups);
}


// This nested function is a bit of a mess, but it works for now.
function getFilterIndex(facetValue: string, facetGroup: IFacetGroup, facetType: string, facetPropertyName: string, path: string[], count: number, map: IFilterCategoryMap): Filter {
    const filter: Filter = mapFacetToFilter({ val: facetValue || 'not provided', count } as IFacet, facetType, facetPropertyName, facetPropertyName, [...path], map);

    filter.filters = facetGroup.buckets.reduce((p: FilterGroups, f: IFacet) => {
        const propertyName = formatters.fromSpaceToUnderscore(f.val);
        p[propertyName] = mapFacetToFilter(f, facetType, facetPropertyName, propertyName, [...path, propertyName], map);
        return p;
    }, {} as FilterGroups);

    return filter;
}

const mapFacetToFilter: MapFaceteToDAO = (facet: IFacet, facetType: string, groupName: string, facetPropertyName: string, path: string[], map: IFilterCategoryMap) => {
    const childType: string[] = [];
    const filters = getKeys(facet, childType, path, map);

    map = map ?? [];

    const m = map.categories.find(i => i.name?.toLowerCase() === facet.val?.toLowerCase())?.category ?? '';
    const category = map.meta[m];

    return {
        filters: filters,
        isRoot: Object.keys(filters).length > 0,
        label: facet.val?.toTitleCase(),
        value: facetPropertyName,
        childrenType: childType.join(','),
        type: facetType,
        groupName,
        path: path,
        altLabel: facet.val,
        id: '',
        selected: false,
        count: facet.count,
        searchCount: 0,
        category: category
    } as Filter;
};



const formatters = {
    toTitleCase(this: string) {
        if (!this) return '';
        return this.replace(/([a-z])([A-Z])/g, '$1 $2')
            .replace(/\w\S*/g, (txt) => {
                return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
            });
    },
    fromSpaceToUnderscore: (value: string) => value.replace(/\s/g, '_'),
    toAutoCompleteString: <T>(listing: T, params: (keyof T)[]) => {
        return params.map(i => listing[i]).join('  ');
    },

    toCurrency: (value: number | undefined, locale = 'en', defaultValue?: string) => {

        if (defaultValue && !value)
            return defaultValue;

        return `$${Number(value ?? 0).toLocaleString(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
    }

};

export const flatMapSearch = <T>(item: T[], childPropertyName: keyof T, match: (i: T) => boolean) => {

    const array = item.flatMap(i => {
        if (i[childPropertyName] instanceof Array)
            return [i, ...i[childPropertyName] as unknown as Array<T>];
        else
            return i;
    });

    return array.find(i => match(i as T));
};

export const recursiveSearch = <T>(item: T[], childPropertyName: keyof T, match: (i: T) => boolean) => {

    for (const i of item) {
        if (match(i))
            return i;

        if (i instanceof Array) {
            const result: T = recursiveSearch(i[childPropertyName], childPropertyName, match) as T;
            if (result)
                return result;
        }
    }
    return null;
};


// set some prototypes here // beware 3rd party libs who try to do the same thing.
Array.prototype.groupBy = function <T>(this: T[], predicate: (value: T, index: number, array: T[]) => string) {
    return this.reduce((acc, value, index, array) => {
        (acc[predicate(value, index, array)] ||= []).push(value);
        return acc;
    }, {} as { [key: string]: T[]; });
};

Array.prototype.shuffle = function <T>(this: T[]) {
    const value = this[Math.floor(Math.random() * this.length)];
    return value;
};


String.prototype.toTitleCase = formatters.toTitleCase;




export const { toCurrency, toAutoCompleteString } = formatters;
export const { toFilter } = mappers;

export const classEvaluator = (className: string, func: () => boolean) => {
    if (func()) {
        return className;
    }
    return '';
};




export function timeAgo(input: number) {
    return Moment(input).fromNow();
}


export const between = (x: number, y: number, n: number) => (x - n) * (y - n) <= 0;