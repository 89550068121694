import React, { useEffect, useRef } from 'react';
import { useBarCodeReader } from 'src/hooks/BarcodeReader';
import classes from './barcode-scanner.module.scss';

export function BarcodeScanner(props: { onDetected?: (result: string) => void; }) {

    const canvasRef = useRef<HTMLCanvasElement>(null);

    const { decodedBarcode } = useBarCodeReader(canvasRef);

    useEffect(() => {
        if (decodedBarcode) {
            props.onDetected?.(decodedBarcode);

        }
    }, [decodedBarcode]);

    return (
        <div className={classes.videoContainer} >
            <canvas id="canvas" width="320" height="240" ref={canvasRef}></canvas>
        </div >
    );
}
