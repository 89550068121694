import React, { useCallback } from 'react';
import { useState } from 'react';
import classes from '../styles/FilterDialog.module.scss';
import { useAppDispatch } from '../hook';
import { setActiveFilter, setSelectedFilters } from '../features/listingSearch/listingSearchSlice';
import { Filter } from '../models/filter';
import { ISetSelectedFilter } from '../models/filterGroup';
import { Link } from 'react-router-dom';
import { sortLabelStr } from '../helpers/sorting';
import { t } from 'i18next';
import { LoadingWidget } from './shared/Loading';

export function FilterPanel(props: {
  activeFilterGroup: Filter;
  activeFilterPath: string[];
  isLoadingFilters: boolean;
  activeFilterChanged?: (path: string[]) => void;
  route?: any;
}) {
  const dispatch = useAppDispatch();
  const [textFilter, setTextFilter] = useState('');

  const filterList = useCallback(() => {
    const retVal =
      props.activeFilterGroup &&
      Object.keys(props.activeFilterGroup.filters)
        .sort(sortLabelStr)
        .map((i: string) => {
          return props.activeFilterGroup?.filters[i];
        })
        .filter(
          (f: Filter) =>
            ((f.searchCount ?? 0) > 0 || f.selected || f.isDirty) &&
            f?.label.toLowerCase()?.includes(textFilter.toLowerCase().trim())
        );

    return retVal;
  }, [props.activeFilterGroup, textFilter]);

  const onChecked = (checked: boolean, path: string[]) => {
    const setSelectedFilter: ISetSelectedFilter = {
      path: path,
      checked,
    };

    dispatch(setSelectedFilters(setSelectedFilter));
  };

  return (
    <div className={classes.filterContainerMain}>
      <div className={classes.filterList}>
        <div className={classes.header}>
          <div className={classes.title}>{props.activeFilterPath.join(' / ').replaceAll('_', ' ')}</div>
          <div className={classes.rainbowBorder}>
            <input
              type="text"
              placeholder="Filter"
              className={classes.filter}
              value={textFilter}
              onChange={(event) => {
                setTextFilter(event.currentTarget.value?.toLowerCase());
              }}
            />
          </div>
        </div>
        <div className={classes.filterContainer}>
          <LoadingWidget isLoading={props.isLoadingFilters} className={classes.loading}>
            {filterList().length ? (
              filterList().map((f: Filter, index?) => (
                <div key={`filter_${index}`} className={classes.filterItem}>
                  <label className="checkbox">
                    <input
                      disabled={f.count === 0 && !f.selected}
                      type="checkbox"
                      name="facets"
                      checked={f?.selected}
                      onChange={(event) => onChecked(event.currentTarget.checked, f.path)}
                    />

                    {f.label}
                  </label>

                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#C6C8C9' }}>
                    <div>({f.searchCount})</div>
                    <div className={f.childrenType && f.selected ? classes.childFilter : classes.hideChildFilter}>
                      <Link
                        to={{}}
                        onClick={() => {
                          setTextFilter('');
                          props.activeFilterChanged?.([...props.activeFilterPath, f.value, f.childrenType]);
                          dispatch(setActiveFilter([...props.activeFilterPath, f.value, f.childrenType]));
                        }}
                      >
                        {t(`filters.${f?.childrenType}`)} {'>'}
                      </Link>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <span style={{ color: 'lightgray' }}>{t('filters.noResults')}</span>
            )}
          </LoadingWidget>
        </div>
      </div>
    </div>
  );
}
