/* eslint-disable no-console */
import React from 'react';
import classes from './../../styles/picture-upload.module.scss';
import { Section } from './Section';
import { ImagePicker } from './ImagePicker';
import { useEffect, useState } from 'react';
import { useValidator } from '../../hooks/useValididator';
import { IValidateEffect } from '../../hooks/IValidateEffect';
import { useUploadImage } from '../../helpers/fileUpload';
import { useAppDispatch, useAppSelector } from '../../hook';
import { selectCurrentListing } from './createListingSelectors';
import { Instruction } from '../../components/shared/Instruction';
import { setTemporaryImages } from './store/createListingSlice';


export interface CSFile {
  file?: File;
  name?: string;
  path?: string;
}
export interface IImage {
  [key: string]: CSFile;
}

export const imageKeys = [
  { name: 'front', label: 'Front' },
  { name: 'back', label: 'Back' },
  { name: 'whiting', label: 'Whiting' },
  { name: 'edges', label: 'Edges' },
  { name: 'centering', label: 'Centering' },
  { name: 'surface', label: 'Surface' },
  { name: 'corners1', label: 'Corners' },
  { name: 'corners2', label: 'Corners' }];


export const useImageUploader = (onSelected?: (files: IImage) => void,
  onValidate?: (x: IValidateEffect) => void,
  selected?: IImage) => {

  const dispatch = useAppDispatch();
  const baseUrl = process.env.REACT_APP_IMAGES_URL || '';
  const selectListing = useAppSelector(selectCurrentListing);
  const [imageList, setImageList] = useState<IImage>(selectListing.images || {});
  const [uploadProgress, setUploadProgress] = useState<{ [key: string]: { progress: number, busy: boolean; }; }>({});

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { value, setValue } = useValidator(selected,
    [
      (model?: IImage) => {
        return {
          description: 'contain at least one image',
          isValid: Object.keys(model ?? {}).filter(i => model && model[i] !== undefined).length > 0,
          key: 'imagelist'
        };
      }
    ]);

  const setPickerBusy = (name: string) => {
    setUploadProgress({ ...uploadProgress, [name]: { progress: 0, busy: true } });
  };

  const setPickerAvailable = (name: string) => {
    setUploadProgress({ ...uploadProgress, [name]: { progress: 100, busy: false } });
  };

  const progress = (val: number, name?: string) => {
    if (name)
      setUploadProgress({ ...uploadProgress, [name]: { busy: true, progress: val } });
  };

  const { upload } = useUploadImage(process.env.REACT_APP_UPLOAD_IMAGE_URL || '', progress);

  const uploadImageAsync = async (name: string, image: File) => {
    setPickerBusy(name);
    const url = await upload(image, name, selectListing.id);
    setPickerAvailable(name);
    return url;
  };

  const selectedImageChanged = async (key: string, file: File) => {
    const filePath = await uploadImageAsync(key, file);
    const name = filePath.substring(filePath.lastIndexOf('/') + 1);

    const newImageList = {
      ...imageList, [key]: { name: name, path: filePath }
    };
    setImageList(newImageList);
  };

  const selectedImageRemoved = async (key: string) => {
    const newImageList = {
      ...imageList, [key]: {}
    };
    setImageList(newImageList);
  };


  useEffect(() => {

    setValue(imageList);
    dispatch(setTemporaryImages(imageList));

  }, [imageList]);


  return {
    selectedImageChanged,
    selectedImageRemoved,
    uploadProgress,
    imageList,
    progress,
    selectListing,
    setPickerAvailable,
    setPickerBusy,
    value,
    setValue,
    baseUrl
  };


};




export function ImageUploadWithPreview(props: {
  onSelected?: (files: IImage) => void;
  onValidate?: (x: IValidateEffect) => void;
  selected?: IImage;
}) {

  const {
    selectedImageChanged,
    selectedImageRemoved,
    selectListing,
    uploadProgress,
    baseUrl } = useImageUploader(props.onSelected, props.onValidate, props.selected);


  return (
    <Section
      title="Photo Upload"
    >
      <div className={classes.pictureUploadContainer}>
        <div className={classes.uploadTitle}>
          <Instruction text='The front image will be your display image for our marketplace, up to 8 images can be uploaded.' />
        </div>
        <div className={classes.fileSelectionContainer} role="figure" aria-label="Images that describe the condition of your card">


          {imageKeys.map((key, index) => {

            return <ImagePicker
              key={index + key.name}
              busy={uploadProgress?.[key.name]?.busy ?? false}
              title={key.label}
              name={key.name}
              uploadProgress={uploadProgress?.[key.name]?.progress ?? 0}
              placeholder={`${key.name}.png`}
              selectedImageChanged={selectedImageChanged}
              onRemoved={selectedImageRemoved}
              objectUrl={selectListing.images?.[key.name]?.path && baseUrl + selectListing.images?.[key.name]?.path}
              value={selectListing.images?.[key.name]?.file}
              role={`${key.name}-image-upload`} />;
          })}
        </div>
      </div>
    </Section>
  );
}

