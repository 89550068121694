export const {
  REACT_APP_RECAPTCHA_V3: RECAPTCHA_V3,
  REACT_APP_RECAPTCHA_V2: RECAPTCHA_V2,
  REACT_APP_AUTH0_DOMAIN: AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID: AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_USE_REFRESH_TOKENS: AUTH0_USE_REFRESH_TOKENS,
  REACT_APP_AUTH0_AUDIENCE: AUTH0_AUDIENCE,
  REACT_APP_AUTH0_SCOPE: AUTH0_SCOPE,
  REACT_APP_SEARCH_CARDS_URL: SEARCH_CARDS_URL,
  REACT_APP_CREATE_LISTING_URL: CREATE_LISTING_URL,
  REACT_APP_SEARCH_ADVERT_URL: SEARCH_ADVERT_URL,
  REACT_APP_MARKET_ANALYTICS: MARKET_ANALYTICS,
  REACT_APP_LISTING_FILTERS: LISTING_FILTERS,
  REACT_APP_LISTING_FILTERS_COUNT: LISTING_FILTERS_COUNT,
  REACT_APP_LISTING_AUTOCOMPLETE: LISTING_AUTOCOMPLETE,
  REACT_APP_CARD_AUTOCOMPLETE: CARD_AUTOCOMPLETE,
  REACT_APP_LISTING_COUNT: LISTING_COUNT,
  REACT_APP_UPLOAD_IMAGE_URL: UPLOAD_IMAGE_URL,
  REACT_APP_IMAGES_URL: IMAGES_URL,
  REACT_APP_GET_LISTING: GET_LISTING,
  REACT_APP_ANTIFORGERY_TOKEN: ANTIFORGERY_TOKEN,
  REACT_APP_REMOTE_ASSETS: REMOTE_ASSETS,
  REACT_APP_SEARCH_DEBOUNCE_TIME: SEARCH_DEBOUNCE_TIME,
  REACT_APP_PP_CLIENT_TOKEN_SERVICE: PP_CLIENT_TOKEN_SERVICE,
  REACT_APP_PP_MERCHANT_REFERAL: PP_MERCHANT_REFERAL,
  REACT_APP_AI: AI,
  REACT_APP_LOCALES: LOCALES,
  REACT_APP_PP_ORDER: PP_ORDER,
  REACT_APP_PP_ORDER_CAPTURE: PP_ORDER_CAPTURE,
  REACT_APP_LISTINGS_BY_MERCHANT: LISTINGS_BY_MERCHANT,
} = process.env;

// export const {
//   paymentService: { order: process.env.REACT_APP_PP_ORDER },
//};
