
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hook';
import { IListing } from '../../../../models/create-listing/iListing';
import { ITransactionDetails, CardTransaction } from '../../CardTransaction';
import { selectCurrentListing } from '../../createListingSelectors';
import { setCurrentListing } from '../../store/createListingSlice';
import { getMarketAnalyticsSnapshot, getMarketAnalyticsSummary } from '../../../listing/listingActions';
import { ListingType } from '../../../../components/tiles/Tiles';
import { ConditionType } from '../../models/ConditionType';



export function CardTransactionPage() {
    const dispatch = useAppDispatch();
    const currentListing: IListing = useAppSelector(selectCurrentListing);

    const onSelected = (transactionDetails?: ITransactionDetails) => {
        dispatch(setCurrentListing({ ...currentListing, offer: transactionDetails?.offer || '', buyItNowPrice: transactionDetails?.buyItNowPrice || '' }));
    };

    const getCardGrade = () => {
        if (currentListing.graded != ConditionType.Graded)
            return 0;
        else
            return currentListing.grade?.grade.value ?? 0;
    };


    useEffect(() => {
        if (currentListing.listingType === ListingType.singleCard) {
            if (currentListing.card?.cardId && currentListing.grade?.grade.value) {
                dispatch(getMarketAnalyticsSummary({
                    cardGrade: getCardGrade(),
                    cardId: currentListing.card?.cardId,
                    period: 'snapshot'
                }));

                dispatch(getMarketAnalyticsSnapshot({
                    cardGrade: getCardGrade(),
                    cardId: currentListing.card?.cardId,
                    period: 'snapshot'
                }));
            } else {
                // eslint-disable-next-line no-console
                console.warn(`CardTransactionPage: cardId (${currentListing.card?.cardId}) or grade (${currentListing.card?.cardId}) is not set`);
            }
        }
    }, [currentListing.card?.cardId, currentListing.grade?.grade.value, currentListing.listingType]);

    return <CardTransaction
        showMarketPrices={currentListing.listingType === ListingType.singleCard}
        transaction={{
            cardGrade: currentListing.grade?.grade.value,
            cardId: currentListing.card?.cardId,
            buyItNowPrice: currentListing.buyItNowPrice,
            offer: currentListing.offer
        }} onChange={onSelected} />;
}
