import React, { Suspense } from 'react';
import { Link } from 'react-router-dom';
import { IOption } from '../shared/AutoComplete';
import { useAppDispatch } from '../../hook';
import { setCardsSearchString, setSearchTerm } from '../../features/cardSearch/cardSearchSlice';
import { mapAutocompleteItem, SearchBar } from './SearchBar';
import classes from './styles/ListingSearchBar.module.scss';
import useMedia from 'react-use/lib/useMedia';
import { RainbowButton } from '../shared/Button';
import { useCards } from 'src/hooks/useCards';
import { getInsightsCardList } from 'src/features';

export interface IInsightsSearchBar {
    value?: string,
    options?: IOption[],
    placeholder?: string,
    searchTextPrompt?: string,
    className?: string,
    style?: React.CSSProperties,
    showSlidingActionBar?: () => void,
    onInputTextChanged?: (text: string) => void,
    onSelected?: (option: IOption) => void,
    onFocus?: () => void,
}

export function InsightsSearchBar(props: IInsightsSearchBar) {
    const isMobile = useMedia('(max-width: 800px)');
    const dispatch = useAppDispatch();
    const { autocomplete, autoCompleteResults } = useCards(mapAutocompleteItem);


    const onInputTextChanged = async (text: string) => {
        await dispatch(setSearchTerm(text));
        autocomplete(text);
    };

    const onSelected = async (option: IOption) => {
        await dispatch(setSearchTerm(String(option.value)));
        await dispatch(setCardsSearchString(String(option.value)));
        dispatch(getInsightsCardList({ searchTerm: option.value?.toString(), currentPage: 0 }));
        autocomplete(option.label);
        props.onSelected?.(option);
    };

    const showSlidingActionBar = () => {
        props.showSlidingActionBar?.();
    };

    return <insight-search-bar class={classes.root + ' ' + (props.className || '')} style={props.style}>
        <SearchBar
            {...props}
            value={props.value}
            onSelected={onSelected}
            onInputTextChanged={onInputTextChanged}
            searchTextPrompt='Search Midnight Market for listings'
            placeholder={'Search Insights'}
            options={autoCompleteResults} />
        <div className={classes.searchBarBox}>
            {!isMobile ?
                <Link to="/create-listing/listing-type" style={{ all: 'unset' }}>
                    <Suspense fallback={<>waiting for button</>}>
                        <RainbowButton style={{ width: 120, height: 40 }}>Place Advert</RainbowButton>
                    </Suspense>
                </Link>
                : <Suspense fallback={<>waiting for search</>}>
                    {!isMobile &&
                        <RainbowButton
                            style={{ width: 40, height: 40 }}
                            onClick={() => showSlidingActionBar()} >+</RainbowButton>}
                </Suspense>}
        </div>
    </insight-search-bar>;

}
