import React, { useEffect, useState } from 'react';
import { IOption } from '../shared/AutoComplete';
import { useAppDispatch } from '../../hook';

import { useCards } from '../../hooks/useCards';
import { ISearchBarProps, mapAutocompleteItem, SearchBar } from './SearchBar';
import classes from './styles/ListingSearchBar.module.scss';
import { setCardsSearchString } from '../../features/cardSearch/cardSearchSlice';
import { useEffectOnce } from 'react-use';

export function CardSearchBar(props: ISearchBarProps) {

    const { updateRecentSearch } = useRecentSearch();
    const dispatch = useAppDispatch();
    const { autocomplete, autoCompleteResults, totalCardCount } = useCards(mapAutocompleteItem);

    const onInputTextChanged = async (text: string) => {
        await dispatch(setCardsSearchString(text));
        autocomplete(text);
    };

    const onSelected = async (option: IOption) => {
        await dispatch(setCardsSearchString(String(option.value)));
        autocomplete(option.label);
        props.onSelected?.(option);

        updateRecentSearch(option.label);
    };


    return <div className={classes.root + ' ' + (props.className || '')}>
        <SearchBar
            {...props}
            value={props.value}
            onSelected={onSelected}
            onInputTextChanged={onInputTextChanged}
            searchTextPrompt='Search Midnight Market for listings'
            placeholder={`Search ${!totalCardCount ? ' . . . ' : totalCardCount} Cards`}
            options={autoCompleteResults} />
    </div >;
}


export const useRecentSearch = () => {
    const [searchItems, setSearchItems] = useState<Array<string>>([]);

    useEffectOnce(() => {
        const recentSearch = localStorage.getItem('recent-search') ?? '[]';
        let json = JSON.parse(recentSearch);

        if (!Array.isArray(json)) {
            json = [];
        }
        setSearchItems(json);
    });

    useEffect(() => {
        if (searchItems.length > 0) {
            // console.log('stored', searchItems);
            localStorage.setItem('recent-search', JSON.stringify(searchItems));
        }

    }, [searchItems]);

    // stick this in a useRecentSearch
    const updateRecentSearch = (searchTerm: string) => {
        searchItems.unshift(searchTerm);
        setSearchItems([...searchItems]);
    };

    const removeRecentSearchItem = (searchTerm: string) => {
        searchItems.splice(searchItems.indexOf(searchTerm), 1);
        setSearchItems([...searchItems]);
    };

    return {
        searchItems,
        updateRecentSearch,
        removeRecentSearchItem,
        clearRecentSearch: () => localStorage.setItem('recent-search', '[]'),
    };

};