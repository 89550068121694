import { ISearchParameters } from 'src/actions/iListingSearchParameters';
import { ICard } from '../createListing/models/iCard';
import { ISimpleCard } from './ISimpleCard';
import { RootState } from 'src/stores/store';
import { IAsyncPayload } from '../listing/IAsyncPayload';




export interface IInsightSelector {
    selectSelectedCard: (state: RootState) => ISimpleCard;
    selectSelectedCardDetails: (state: RootState) => IAsyncPayload<ICard>;
    selectListingSearchParameters: (state: RootState) => ISearchParameters;
}



const insightSelectors: IInsightSelector = {
    selectSelectedCard: (state: RootState) => state.insights.selectedCard ?? {} as ISimpleCard,
    selectSelectedCardDetails: (state: RootState) => state.insights.selectedCardDetails ?? {} as IAsyncPayload<ICard>,
    selectListingSearchParameters: (state: RootState) => state.cardSearch.cardSearchParameters || {} as ISearchParameters,
};

export const { selectSelectedCard, selectSelectedCardDetails } = insightSelectors;