
import React from 'react';
import classes from '../../styles/createListing.module.scss';
import { Divider } from '@mui/material';

import { useEffect } from 'react';
import { useAppSelector } from '../../hook';

import { useValidator as useValididatorState } from '../../hooks/useValididator';
import { IValidateEffect } from '../../hooks/IValidateEffect';
import { ListingType } from '../../components/tiles/Tiles';


import { ICard } from '../../features/createListing/models/iCard';

import { CardSearchResults } from '../../features/cardSearch/CardSearchResults';
import { selectCardSearchResults } from '../../features/cardSearch/cardSearchSelectors';


export function CardSearchMobile(props: {
    onSelected: (card: ICard) => void;
    selected: ICard | undefined;
    onValidate?: (x: IValidateEffect) => void,
    advertType: ListingType | undefined;
}) {

    const searchResults = useAppSelector(selectCardSearchResults);
    const { value: selectedCard, setValue: setSelectedCard } = useValididatorState<ICard>(props.selected);

    useEffect(() => {
        props.onValidate?.({ isValid: selectedCard?.isValid, canProceed: selectedCard?.canProceed });
    }, [ selectedCard?.canProceed, selectedCard.isValid ]);

    const onCardSelected = (card: ICard) => {
        setSelectedCard(card);
        props.onSelected?.(card);
    };


    return (
        <div className={classes.mobileRoot}>
            <Divider />
            <CardSearchResults
                searchResults={searchResults}
                selected={selectedCard.model}
                onCardAdded={onCardSelected}
                className={classes.searchResults}
            />
        </div>
    );
}



