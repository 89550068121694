import React, { useEffect, useRef, useState } from 'react';
import classes from '../../styles/SearchResults.module.scss';
import useInfiniteScroll from 'src/components/LoadScroll';
import { ISearchResultItem } from 'src/models/iSearchResultItem';
import spinnerUrl from '../../assets/svg/loading.svg';
import { CommonMetaTags, SEOTags } from 'src/HelmetSEO';
import { CardSearchResultItem } from 'src/components/CardSearchResultItem';
import { RainbowButton } from 'src/components/shared/Button';
import { LoadingWidget } from 'src/components/shared/Loading';
import { getInsightCardDetails, getInsightsCardList } from '../cardSearch/cardActions';
import { useAppDispatch, useAppSelector } from 'src/hook';
import { selectCardSearchParameters } from '../cardSearch/cardSearchSelectors';
import { ISimpleCard } from './ISimpleCard';
import { InsightsSearchBar } from 'src/components/SearchBar/InsightsSearchBar';
import { useMedia } from 'react-use';

export function InsightsSearchResults() {


  const [currentPage, setCurrentPage] = useState(0);
  const scrollRef = useRef<HTMLDivElement>(document.getElementById('main-insight-center') as HTMLDivElement);
  const [cachedCards, setCachedCards] = useState<ISimpleCard[]>([]);
  const [pageScroll, setPageScroll] = useState(false);
  const spinner = useRef<HTMLDivElement>(null);
  const ptr = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const cards = useAppSelector((state) => state.insights.searchResults);

  const selectSearchTerm = useAppSelector(selectCardSearchParameters);

  useEffect(() => {
    dispatch(getInsightsCardList({ searchTerm: selectSearchTerm?.autoCompleteSearchString, currentPage: 0 }));
  }, [selectSearchTerm?.searchParams]);


  // usePullToRefresh(onTouchStart, onTouchMove, onTouchEnd);

  // scroll function below
  // there have been several iterations of this because of ux changes. 
  // right now there is a bit of a code smell.

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  useInfiniteScroll<HTMLDivElement>(loadMoreSearchResults, scrollRef);
  // scroll function end.


  useEffect(() => {
    if (!cards.data) return;

    if (pageScroll) {
      setCachedCards([...cachedCards, ...cards.data?.cards ?? []]);
      setPageScroll(false);
    }
    else {
      setCurrentPage(0);
      setCachedCards(cards.data?.cards ?? []);
    }
  }, [cards.data]);

  const [imageLoadingClass, setImageLoadingClass] = useState<string>('');
  const isMobile = useMedia('(max-width: 800px)');

  useEffect(() => {
    if (cards.isLoading) setImageLoadingClass(classes.shake);
    else setImageLoadingClass('');
  }, [cards.isLoading]);

  async function loadMoreSearchResults() {

    if (cards.isLoading) return;

    if (cards?.data?.numFound ?? 0 > currentPage * 20) {
      setPageScroll(true);
      await dispatch(getInsightsCardList({ searchTerm: selectSearchTerm?.autoCompleteSearchString, currentPage: currentPage + 1 }));
      setCurrentPage(currentPage + 1);
    }
  }

  return (
    <div id="search-results-container">
      {/* <HelmetWithCommonTags
        title={'Midnight Merchant - Search Results'}
        description="Search for your favorite cards and get the latest market analysis"
        keywords={['cards', 'market analysis', 'insights', 'card search']}
        targetType='website'
        imageUrl={'/favicon_io/android-chrome-192x192.png'} /> */}


      <SEOTags >
        <CommonMetaTags
          title={'Midnight Merchant Marketplace'}
          description={'Search for your favorite items to see the latest insights'}
          keywords={[
            'cards',
            'market analysis',
            'insights',
            'card search',
            'marketplace',
            'buy',
            'sell',
            'trade',
            'cards',
            'collectibles',
            'collectors',
            'card games',
            'magic the gathering',
            'pokemon',
            'yugioh',
            'digimon',
            'flesh and blood',
            'sports cards',
            'nft',
            'non-fungible tokens',
            'digital collectibles',
            'digital cards'
          ]}
          imageUrl={'/favicon_io/android-chrome-192x192.png'}
          targetType={'website'} />
      </SEOTags>

      {!isMobile && <InsightsSearchBar
        value={selectSearchTerm?.autoCompleteSearchString}
        style={{ padding: '0 12px', height: 200 }}
      />}

      <div ref={spinner} className={classes.spinner}>
        <img src={spinnerUrl} />
      </div>
      <div className={classes.searchResults} ref={ptr}>

        {cachedCards.length === 0 && !cards.isLoading &&
          (<div className={classes.noResultsFound}>Not seeing what you expect, try the filter panel</div>)
        }
        <LoadingWidget
          isLoading={
            cachedCards.length === 0 &&
            cards.isLoading}
          style={{ display: 'flex', justifyContent: 'center', width: 100, height: 100 }}
          className={classes.loading}
        >
          {cachedCards.map((result: any, index: number) =>
            <CardSearchResultItem
              onSelected={(item: ISearchResultItem) => {
                dispatch(getInsightCardDetails(item.id));
              }}
              item={{
                name: result.name,
                heading: result.heading,
                subHeading: result.subHeading,
                footer: result.footer,
                footer2: result.footer2,
                series: result.series,
                set: result.set,
                id: result.id,
                imageSrc: result.imageSrc,
                imageList: result.imageList,
                marketAnalysis: result.marketAnalysis,
                marketSummary: result.marketSummary,
                rarity: result.rarity,
                number: result.number,
              } as ISearchResultItem}
              imageProps={{ className: imageLoadingClass, style: { maxHeight: 267, minWidth: 'unset', borderRadius: 3 } }}
              key={`result-${index}`}
            ></CardSearchResultItem>
          )}
        </LoadingWidget>

        {(cards.data?.numFound ?? 0) > currentPage * 20 && !cards.isLoading &&
          <div className={classes.loadMoreContainer}>
            <RainbowButton variant='secondary-outline' onClick={loadMoreSearchResults} style={{ width: 150, }}>Load more...</RainbowButton>
          </div>
        }
      </div>

    </div>
  );
}



