
import React, { useEffect } from 'react';
import { isArray } from 'lodash';
import { HeaderAndContent } from '../user/components/HeaderAndContent';
import axios from 'axios';


export function RenderFAQ(props: { url: string; }) {

    const [data, setData] = React.useState<any>(null);

    async function getFAQ() {

        return (await axios.get(props.url)).data;
    }

    async function getData() {
        const data = await getFAQ();
        setData(data);
    }

    function Render(items: Array<any>) {

        const data = items?.map((item: any, index: number) =>
            <div key={index}>
                <HeaderAndContent key={`faq-${index}`} title={item.title}>
                    {<div>{
                        isArray(item.content)
                            ? item.content?.map((item: any, index: number) =>
                                <div key={index}>
                                    {item.paragraph}
                                    <ul>
                                        {item.bullets?.map((bullet: any, index: number) =>
                                            <li key={index}>{bullet}</li>
                                        )}
                                    </ul>
                                </div>)
                            : item.content}</div>}
                    <div>
                        <ol>
                            {item.questions?.map((question: any, index: number) =>
                                <li key={index}>
                                    <span className='f-w-700'>{question.question}</span>

                                    {question.answers?.map((answer: any, index: number) =>
                                        <div key={index}>
                                            <p>{answer.answer}</p>
                                            <ul>
                                                {answer.bullets?.map((bullet: any, index: number) =>
                                                    <li key={index}>{bullet}</li>
                                                )}
                                            </ul>
                                        </div>
                                    )}

                                </li>
                            )}
                        </ol>
                    </div>
                </HeaderAndContent>
            </div>

        );
        return data;
    }


    useEffect(() => {
        getData();
    }, [props.url]);

    useEffect(() => {
        Render(data);
    }, [data]);

    return <>{Render(data)}</>;
}