import React from 'react';
import { Link } from 'react-router-dom';
import { routeConfig } from '../../routes/routeConfig';
import classes from './purchase-complete.module.scss';

export function PurchaseComplete() {
  return <div className={classes.purchaseCompletePage}>
    <div>
      Purchase Complete
    </div>
    <br />
    <div>
      <Link to={routeConfig.home}> Home</Link>
    </div>
  </div>;
}
