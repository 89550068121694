import React from 'react';
import classes from '../../styles/tiles.module.scss';
import linkTypeClasses from '../../styles/link-type.module.scss';
import charizard from '../../assets/png/Charizard.png';
import charrare from '../../assets/png/Charizard-GX Rare.png';
import gyra from '../../assets/png/gyrados-gx-rare-holo.png';
import sealed from '../../assets/png/sealedCards.jpg';

import fs from '../../assets/png/fusionstrike-sealed.jpg';
import es from '../../assets/png/evolving-skies-sealed.jpg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


export enum ListingType {
  singleCard = 'singleCard',
  mixedBundle = 'bundleSingle',
  bundleSealed = 'bundleSealed',
  sealedSingle = 'sealedSingle',
  unknown = 'unknown'
}

export function Tile(props: {
  variant: ListingType;
  label: string;
  isSelected: boolean;
  onClick?: (value: ListingType) => any;
}) {
  const onClick = (value: ListingType) => {
    props.onClick?.(value);
  };

  switch (props.variant) {
    case ListingType.singleCard:
      return (
        <div onClick={() => onClick(props.variant)} className={`${classes.singleCard}  ${props.isSelected ? classes.selected : ''}`} >
          <div className={classes.innerSingle}>
            <img src={charizard} alt="Charizard"></img>
          </div>
          <label style={{ pointerEvents: 'none' }}>{props.label}</label>
        </div>
      );
    case ListingType.mixedBundle:
      return (
        <div
          onClick={() => onClick(props.variant)}
          className={`${classes.multipleSingle}  ${props.isSelected ? classes.selected : ''}`}
        >
          <div className={classes.left}>
            <img src={charrare} alt="Charizard"></img>
          </div>
          <div className={classes.center}>
            <img src={gyra} alt="Gyrados"></img>
          </div>
          <div className={classes.right}>
            <img src={charizard} alt="Charizard rare"></img>
          </div>
          <label style={{ pointerEvents: 'none' }}>{props.label}</label>
        </div>
      );
    case ListingType.sealedSingle:
      return (
        <div
          onClick={() => onClick(props.variant)}
          className={`${classes.sealedSingle}  ${props.isSelected ? classes.selected : ''}`}
        >
          <div className={classes.center}>
            <img src={sealed} alt="shadow rider"></img>
          </div>
          <label style={{ pointerEvents: 'none' }}>{props.label}</label>
        </div>
      );
    case ListingType.bundleSealed:
      return (
        <div
          onClick={() => onClick(props.variant)}
          className={`${classes.sealedMultiple}  ${props.isSelected ? classes.selected : ''}`}
        >
          <div className={classes.big}>
            <img src={charizard} alt="Charizard"></img>
          </div>
          <div className={classes.outside}>
            <img src={fs} alt="Charizard"></img>
          </div>
          <div className={classes.inside}>
            <img src={es} alt="Charizard"></img>
          </div>

          <label style={{ pointerEvents: 'none' }}>{props.label}</label>
        </div>
      );
    case ListingType.unknown:
    default:
      return <></>;

  }
}

export function ListingTypeLink(props: {
  img: React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
  label?: string,
  onClick?: (value?: ListingType) => void,
  listingType?: ListingType,
  to?: string,
  isSelected?: boolean,
  imgProps?: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
  disabled?: boolean;
  className?: string;
}) {

  const LinkImage = props.img;
  const { t } = useTranslation();

  return <div className={`${linkTypeClasses.createListingType} ${props.className ?? ''} ${props.isSelected ? linkTypeClasses.selected : ''}`} style={props.disabled ? { pointerEvents: 'none' } : undefined}>
    <div style={{ height: 330 }}>
      <Link to={props.to || ''} onClick={() => props.onClick?.(props.listingType)}>
        <LinkImage />
      </Link>
    </div>
    <div style={{ height: 50 }}>
      <label className={`${linkTypeClasses.label}`}>
        <div className={linkTypeClasses.dot}></div>
        <div className={linkTypeClasses.text}>{t(props.label || 'createListing.listingType.default')}</div>
      </label>
    </div>
  </div >;
}
