import React, { useEffect } from 'react';
import { ReactComponent as PayPal } from '../../assets/svg/user-profile/paypal.svg';
import { RainbowAnchor } from '../../components/shared/Button';
import { Information, Instruction } from '../../components/shared/Instruction';
import { useAuthHook } from '../../shared/hooks/useAuthHook';
import { useAppDispatch, useAppSelector } from '../../hook';
import { selectSellerPaymentServiceOnboardingStatus } from './seller/sellerSelector';
import { LoadingWidget } from '../../components/shared/Loading';
import { useLocation } from 'react-router-dom';
import { updateSellerMId } from './seller/sellerActions';


export function RegisterPaymentService() {

  const { data, isLoading } = useAppSelector(selectSellerPaymentServiceOnboardingStatus);
  const { user } = useAuthHook();
  const { search } = useLocation();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (search != '') {
      const matchFirstEqualSign = /merchantIdInPayPal=([^&#]*)/;
      const mId = search.match(matchFirstEqualSign)?.[1];
      if (mId)
        dispatch(updateSellerMId({ mId, userId: btoa(user?.email ?? '') }));
    }
  }, [search]);


  const Disconnect = () => {
    return <RainbowAnchor href={'https://www.paypal.com/businessmanage/account/accountAccess'} title='Disconnect your paypal account' style={{ minWidth: 150, width: '100%' }} variant={data?.canBePayed ? 'secondary-outline' : undefined}>
      Disconnect
    </RainbowAnchor>;
  };

  const ChangeAccount = () => {
    return <RainbowAnchor href={data?.links?.[1]?.href} style={{ minWidth: 150, width: '100%' }} title="Connect or change your paypal account" >
      {data?.canBePayed ? 'Change Account' : 'Connect'}
    </RainbowAnchor>;
  };



  const HowToDisconnect = () => <>
    <Information>
      <br />
      How can I revoke permissions to my paypal account?
      <ol>
        <li>Log in to your PayPal account.</li>
        <li>Hover over your name in the top right and select Account Settings</li>
        <li>Under `Account & Security` in the left pane ensure Account access is highlighted</li>
        <li>Under `Account access` in the main window select `Permissions given to other apps and sites`</li>
        <li>Click remove next to Midnight Merchant</li>
        <li>Confirm your action</li>

      </ol>
    </Information>
  </>;


  return (
    <link-seller-to-payment-service>
      <>
        <Instruction
          style={{ marginBottom: 20 }}
          text="Link your paypal account to sell on Midnight Merchant"
        ></Instruction>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            flexWrap: 'wrap',
          }}
        >
          <div>
            <div><PayPal /></div>
            <span className="f-s-xsmall" style={{ color: '#98fff9', fontSize: '1rem', fontWeight: '500', lineHeight: '1.5rem', }} >{user?.name}</span>
          </div>
          <LoadingWidget isLoading={isLoading} >
            {!data?.canBePayed ?
              <ChangeAccount />
              : <div style={{ display: 'flex', gap: 8, justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}><Disconnect /><ChangeAccount /></div>

            }
          </LoadingWidget>
        </div>
        {data?.canBePayed && <HowToDisconnect />}
      </>
    </link-seller-to-payment-service>
  );
}

