import React, { useEffect } from 'react';

export function useCloseDialogListener(element: React.RefObject<HTMLDivElement>, onClose: () => void) {

    const keyBoardEventListener = (event: KeyboardEvent) =>
        commonCloseFunction(event, (event: KeyboardEvent) => event.key === 'Escape');


    const mouseEventListener = (event: MouseEvent) => commonCloseFunction(event);
    const withinBoundaries = <T extends Event>(event: T) => event.composedPath().includes(element?.current as HTMLDivElement);

    useEffect(() => {

        document.addEventListener('keydown', keyBoardEventListener);
        document.addEventListener('click', mouseEventListener);

        return () => { // cleanup listeners;
            document.removeEventListener('click', mouseEventListener);
            document.removeEventListener('keydown', keyBoardEventListener);
        };

    }, []);


    const commonCloseFunction = <T extends Event>(event: T, shouldClose?: (event: T) => boolean) => {
        const sClose = shouldClose ? shouldClose(event) : false;
        if (!withinBoundaries(event) || sClose) {
            onClose();
        }
    };

}