import React from 'react';
import styles from './../../styles/createListing.module.scss';
import classes from './../../styles/listing-price.module.scss';
import { Section } from './Section';
import { IValidateEffect } from '../../hooks/IValidateEffect';
import { Instruction } from '../../components/shared/Instruction';

import { IInput, CustomInputComponent } from '../../components/input/IInput';
import { MarketAnalysis } from '../listing/MarketAnalysis';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

export interface ITransactionDetails {
  cardId?: string;
  cardGrade?: number;
  offer?: string;
  buyItNowPrice?: string;
  postCode?: string;
}

export function CardTransaction(props: {
  transaction?: ITransactionDetails;
  showMarketPrices?: boolean;
  onChange?: (value?: ITransactionDetails) => void;
  onValidate?: (x: IValidateEffect) => void;
}) {

  return (
    <Section title="Listing Price" className={classes.container}>
      <Instruction
        style={{ maxWidth: 500 }}
        text="Use our Market Analyser tool below to help indicate the best price to list your product based on estimated market values.">
      </Instruction>
      <Instruction text="Enter your sale value" color="white"></Instruction>
      <div className={styles.transaction}>
        <TextField
          type="text"
          label="Listing Price"
          variant="outlined"
          value={props.transaction?.buyItNowPrice ?? 0}
          placeholder={' --.--'}
          className={classes.buyItNowPrice}
          onChange={(e) => {
            props.onChange &&
              props.onChange({
                buyItNowPrice: e.target.value,
              } as ITransactionDetails);
          }
          }
          InputLabelProps={{ style: { color: '#ffffff40' } }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <span style={{ color: '#ffffff40' }}>$</span>
              </InputAdornment>
            ),
            inputComponent: CustomInputComponent,
            inputProps: {
              component: IInput,
            },
          }}
        />
      </div>
      {props.showMarketPrices ? <MarketAnalysis /> : <></>}
    </Section>
  );
}
