import { useAuthHook } from '../shared/hooks/useAuthHook';

import { getRecapToken, ReCaptchaAction } from './recaptcha';

export type Throbber = HTMLCanvasElement & {
  ctx: CanvasRenderingContext2D | null;
  update: (value: number) => void;
};

export const useUploadImage = (
  url: string,
  onProgress: (percent: number, name?: string) => void
) => {
  const { getAccessTokenSilently } = useAuthHook();

  const upload = async (file: File, name: string, listingId: string) => {
    const accessToken = await getAccessTokenSilently();

    const { exec } = fileUploadWithProgressAsync(
      url,
      file,
      name,
      listingId,
      {
        authorization: `Bearer ${accessToken}`,
        captchaToken: await getRecapToken(ReCaptchaAction.add_image),
      },
      (val: number) => {
        onProgress(val, name);
      }
    );
    return exec();
  };
  return { upload };
};

export function fileUploadWithProgressAsync(
  url: string,
  file: File,
  name: string,
  listingId: string,
  headers: any,
  progressCallback?: (percent: number) => void
): { exec: () => Promise<string>; progress: number; } {
  const xhr = new XMLHttpRequest();
  let percent = 0;

  xhr.upload.addEventListener(
    'progress',
    (e) => {
      if (e.lengthComputable) {
        percent = Math.round((e.loaded * 100) / e.total);
        progressCallback && progressCallback(percent);
      }
    },
    false
  );

  xhr.upload.addEventListener(
    'load',
    () => {
      progressCallback && progressCallback(100);
    },
    false
  );

  const exec: () => Promise<string> = () => {
    // eslint-disable-next-line no-async-promise-executor, no-debugger

    // eslint-disable-next-line no-async-promise-executor
    return new Promise<string>(async (resolve, reject) => {
      xhr.open('POST', url);
      xhr.responseType = 'arraybuffer';

      xhr.setRequestHeader('Authorization', `${headers.authorization}`);
      xhr.setRequestHeader('x-cap-tok', headers.captchaToken);
      xhr.overrideMimeType('text/plain; charset=x-user-defined-binary');

      const binary: string = await toBase64(file);

      const fileResult = {
        listingId: listingId,
        name: name,
        type: file.type,
        size: file.size,
        file: binary,
      };

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const decode = new TextDecoder('utf-8');
          const response = decode.decode(new Uint8Array(xhr.response));
          resolve(response);
          return response;
        }
      };

      xhr.onerror = (ev) => {
        reject(ev);
      };

      xhr.send(JSON.stringify(fileResult));
    });
  };

  return {
    exec,
    progress: percent,
  };
}

function toBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let encoded = reader.result?.toString().replace(/^data:(.*,)?/, '');
      if ((encoded?.length || 0) % 4 > 0) {
        encoded += '='.repeat(4 - ((encoded?.length || 0) % 4));
      }
      resolve(encoded || '');
    };
    reader.onerror = (error) => reject(error);
  });
}
