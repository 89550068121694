import React, { useState } from 'react';
import { useAppDispatch } from '../../hook';
import { useEffectOnce } from 'react-use';
import { getListing } from '../listing/listingActions';
import { IAsyncPayload } from '../listing/IAsyncPayload';
import { LoadingWidget } from '../../components/shared/Loading';
import { Navigate } from 'react-router-dom';
import { setCurrentListing } from '../createListing/store/createListingSlice';
import { IListing } from '../../models/create-listing/iListing';
import { ListingType } from 'src/components/tiles/Tiles';

export function EditListing() {
    const dispatch = useAppDispatch();
    const [isLoading, setLoading] = useState(true);
    const [listing, setListing] = useState<IAsyncPayload<IListing>>();

    useEffectOnce(() => {
        const [listingId, countryCode] = window.location.pathname.split('/').filter((i, index) => index > 1);

        setTimeout(async () => {
            setLoading(true);
            const listing = await dispatch<any>(getListing({ listingId, countryCode }));
            setListing(listing.payload);
            if (listing.payload) {
                await dispatch(setCurrentListing(listing.payload.data));
            }
            setLoading(false);
        }, 1500);
    });

    const getFlowRoute = (listing: IListing) => {

        switch (listing.listingType) {
            case ListingType.singleCard:
                return <Navigate to='/create-listing/condition' replace />;
            case ListingType.mixedBundle:
                return <Navigate to='/create-listing/title' replace />;
            default:
                return '/not-found';
        }
    };


    return <LoadingWidget isLoading={isLoading} >
        {!listing?.data ? <Navigate to={'/not-found'} replace /> :
            getFlowRoute(listing.data)
        }
    </LoadingWidget>;

}