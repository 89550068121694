

import { IAsyncPayload } from 'src/features/listing/IAsyncPayload';
import { IMarketDataSummary } from '../../services/listing-service/models/IMarketDataSummary';
import { IListing } from './iListing';


export enum ListingStatusEnum {
  Unknown = 0,
  Draft = 1,
  Published = 2
}

export interface IListingState {
  selectedListing?: IAsyncPayload<IListing>;
  isLoading: boolean;
  isLoadingMarketData: boolean;
  marketAnalyticsGraphData?: IAsyncPayload<any>;
  marketAnalyticsSummary?: IAsyncPayload<IMarketDataSummary>;
}



