import React from 'react';
import classes from '../../styles/instruction-text.module.scss';
import { InstructionProps } from './InstructionProps';


export function Instruction(props: InstructionProps) {

    const { italic, ...other } = props;

    return <instruction-text {...other} class={classes.subheading2 + ' ' + classes[props.size || 'medium'] + ' ' + (props.className ?? '')}
        style={{
            color: props.color,
            fontStyle: italic ? 'italic' : 'normal',
            display: 'inline-block',
            ...props.style
        }}>
        {props.text ?? props.children}
    </instruction-text>;

}



export function Information(props: InstructionProps) {

    const { italic, ...other } = props;

    return <information-text {...other} class={classes.subheading2 + ' ' + classes[props.size || 'medium'] + ' ' + (props.className ?? '')}
        style={{
            color: props.color,
            fontStyle: italic ? 'italic' : 'normal',
            display: 'inline-block',
            ...props.style
        }}>
        {props.render ? props.render() : props.text ?? props.children}
    </information-text>;

}



