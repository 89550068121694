import {RootState} from '../../stores/store';

const selectors = {
  selectListing: (state: RootState) => state.listing.selectedListing,
  selectIsListingLoading: (state: RootState) => state.listing.isLoading,
  selectIsLoadingMarketData: (state: RootState) => state.listing.isLoadingMarketData,
  selectMarketSummary: (state: RootState) => state.listing.marketAnalyticsSummary,
  selectMarketData: (state: RootState) => state.listing.marketAnalyticsGraphData,
};

export const {selectListing, selectIsListingLoading, selectIsLoadingMarketData} = selectors;
