import { Route, Routes } from 'react-router-dom';
import React, { lazy, Suspense } from 'react';
import classes from './styles/App.module.scss';
import { Marketplace } from './views/Marketplace';
import { ListingComplete } from './features/createListing/ListingComplete';
import { ListingFailed } from './features/createListing/ListingFailed';

import { useAppSelector, useWebSockets } from './hook';
import { ListingRoutes } from './views/listing/routes';
import { CreateListingRoutes } from './views/createListing/routes';
import { FAQRoutes, userRoutes } from './features/user/Routes';
import Sites from './views/Sites';
import Sitemap from './views/Sitemap';
import { LoadingWidget } from './components/shared/Loading';
import { EditListing } from './features/editListing/edit';
import { FilterPage } from './FilterPage';
import { FilterPanel } from './components/FilterPanel';
import { SelectedFiltersPanel } from './components/SelectedFiltersPanel';
import { routeConfig } from './routes/routeConfig';
import CookieConsent from 'react-cookie-consent';
import { RainbowButton } from './components/shared/Button';
import { CollectionRoutes } from './features/collection/CollectionHome';
import { InsightsRoutes } from './features/insights/InsightsRoutes';

export interface NavigationState {
  selectedFilter?: string;
}

// const Home = lazy(() => import('./components/Home'));
const ListingSearchResultsPage = lazy(() => import('./features/listingSearch/ListingSearchResultsPage'));
const TermsOfService = lazy(() => import('./terms'));
const ContactUs = lazy(() => import('./views/ContactUs'));
const Privacy = lazy(() => import('./Privacy'));
const Company = lazy(() => import('./views/Company'));
const ListingManager = lazy(() => import('./views/ListingManagerPage'));

export function App() {
  useWebSockets();



  return (
    <div className={classes.app}>
      <CookieConsent
        style={{ height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        buttonStyle={{ backgroundColor: 'transparent', color: 'white', width: 150, height: 50 }}
        ButtonComponent={RainbowButton}
        buttonText="Accept"
      >
        This website uses cookies to enhance the user experience
      </CookieConsent>
      <AppRoutes />

    </div>

  );
}


function AppRoutes() {
  const createListingRoutes = CreateListingRoutes();
  const marketPlaceSearchResultsRoutes = MarketPlaceSearchResultsRoutes();
  const localeResourceRoutes = LocaleResourceRoutes();
  const listingRoutes = ListingRoutes();
  const usrRoutes = userRoutes();
  const faqRoutes = FAQRoutes();
  // const locales = useLocales();
  const [activeFilter, setActiveFilter] = React.useState<string[]>([]);
  const selectActiveFilterGroup = useAppSelector((state) => state.listingSearch.filterData);
  const insightsRoutes = InsightsRoutes();


  return (
    <Routes>
      {marketPlaceSearchResultsRoutes}
      {insightsRoutes}
      {createListingRoutes}
      {faqRoutes}
      <Route path="/listing-manager" element={<ListingManager />} />
      <Route path="/search/:query" element={<MarketPlaceSuspense jsxElement={<ListingSearchResultsPage location={location.pathname} />} />} />
      <Route path="/login" element={<div></div>}></Route>
      <Route path="listing-complete?/:id/:title/:imageUrl/:description" element={<MarketPlaceSuspense hideSearchBar jsxElement={<ListingComplete />} />} />
      <Route
        path="listing-failed"
        element={
          <Marketplace>
            <ListingFailed />
          </Marketplace>
        }
      />
      <Route path={routeConfig.searchResults.filters}

        element={<FilterPage />}>
        <Route
          path={routeConfig.searchResults.filter}
          element={
            <FilterPanel
              activeFilterChanged={(group) => {
                setActiveFilter(group);
              }}
              activeFilterPath={activeFilter}
              activeFilterGroup={selectActiveFilterGroup}
              isLoadingFilters={false} />
          }
        ></Route>
        <Route
          path=""
          element={
            <div className={classes.selectedFiltersPanel}>
              <SelectedFiltersPanel
                onSelected={(filter) => setActiveFilter(filter)}
              />
            </div>
          }
        ></Route>
      </Route>


      <Route path="/edit/:id/:countryCode?" element={<EditListing />} />
      <Route path="adverts"></Route>
      <Route path="terms" element={<MarketPlaceSuspense jsxElement={<TermsOfService />} />}></Route>
      <Route path="privacy" element={<MarketPlaceSuspense jsxElement={<Privacy />} />}></Route>
      <Route path="company" element={<MarketPlaceSuspense jsxElement={<Company />} />}></Route>
      <Route path="sites" element={<MarketPlaceSuspense jsxElement={<Sites />} />}></Route>
      <Route path="sitemap" element={<Sitemap />}></Route>
      <Route path="contact-us" element={<MarketPlaceSuspense jsxElement={<ContactUs />} />}></Route>
      {listingRoutes}
      {usrRoutes}

      <Route
        path="/"
        element={
          <Suspense fallback={<LoadingWidget style={{ height: 100, width: 100 }} isLoading={true} />}>
            {/* <Home /> */}
          </Suspense>
        }
      />

      {localeResourceRoutes}

      <Route
        path="*"
        element={
          <Marketplace>
            <div>
              <h2>Looks like you found a page that doesnt exist</h2>
            </div>
          </Marketplace>
        }
      />
      {CollectionRoutes()}
    </Routes>
  );
}

function LocaleResourceRoutes() {
  return (
    <Route path="/locales/">
      <Route path="en/en/en.json" />
      <Route path="de/de/de.json" />
    </Route>
  );
}

function MarketPlaceSuspense(props: { jsxElement: JSX.Element; hideSearchBar?: boolean; }) {
  return (
    <Marketplace hideSearchBar={props?.hideSearchBar}>
      <Suspense fallback={<LoadingWidget style={{ height: 100, width: 100 }} isLoading={true} />}>
        {props.jsxElement}
      </Suspense>
    </Marketplace>
  );
}

function MarketPlaceSearchResultsRoutes() {
  return (
    <Route
      path={routeConfig.searchResults.path}
      element={
        <Marketplace>
          <Suspense fallback={<LoadingWidget style={{ height: 100, width: 100 }} isLoading={true} />}>
            <ListingSearchResultsPage />
          </Suspense>
        </Marketplace>
      }>
      <Route
        path={routeConfig.searchResults.seriesId}
        element={<MarketPlaceSuspense jsxElement={<ListingSearchResultsPage location={location.pathname} />} />}
      ></Route>
      <Route
        path={routeConfig.searchResults.seriesIdSetId} //"series/:series/set/:set"
        element={<MarketPlaceSuspense jsxElement={<ListingSearchResultsPage location={location.pathname} />} />}
      ></Route>
      <Route
        path={routeConfig.searchResults.seriesNameSetNameCardName} // "series/:series/set/:set/name/:name"
        element={<MarketPlaceSuspense jsxElement={<ListingSearchResultsPage location={location.pathname} />} />}
      ></Route>
    </Route>
  );
}



