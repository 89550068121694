import React, { useState } from 'react';

// move highlight according to key pressed;
const positions = {
    'ArrowUp': -1,
    'ArrowDown': 1,
    // 'ArrowLeft': -1,
    // 'ArrowRight': 1
};


export function useRowHighlighter(listLength: number, className: string, onSelected?: (i: number) => void) {
    const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);

    const getHighlightClass = (index: number) => index === highlightedIndex ? className : '';

    const onRowKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {

        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            onSelected && onSelected(highlightedIndex);
        }

        if (Object.keys(positions).includes(event.key)) {

            const newIndex = highlightedIndex + positions[event.key as keyof typeof positions];
            event.preventDefault();
            event.stopPropagation();
            if (listLength < newIndex || newIndex < 0)
                return;

            setHighlightedIndex(newIndex);
        }
    };

    return {
        highlightedIndex,
        getHighlightClass,
        onRowKeyDown,
        setHighlightedIndex,
        resetHighlightedIndex: () => setHighlightedIndex(-1),
    };
}
