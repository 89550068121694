import React, { createContext, useEffect } from 'react';
import { ISnackbarMessage } from './globalSlice';
import { useSnackbar } from '../shared/hooks/snackbar';
import { useAppSelector } from '../hook';


export const snackBarContext = createContext({} as ISnackbarMessage);

export function SnackbarProvider(props: { children?: React.ReactNode; }) {
    const { enqueue, snackbar } = useSnackbar();

    const selectSnackBarMessage = useAppSelector((state: any) => state.global.snackbarMessage);
    const message = React.useRef('');

    useEffect(() => {
        if (selectSnackBarMessage && selectSnackBarMessage.snackbarMessage != message.current) {
            enqueue(selectSnackBarMessage.variant, selectSnackBarMessage.snackbarMessage);
            message.current = selectSnackBarMessage.snackbarMessage;
        }
    }, [selectSnackBarMessage?.snackbarMessage]);
    const Provider = snackBarContext.Provider;

    return <Provider value={{} as ISnackbarMessage} >
        {props.children}
        {snackbar}
    </Provider>;
}


