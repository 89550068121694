import { ReCaptchaAction } from 'src/helpers/recaptcha';
import { postAsync } from '../baseService';
import { seller } from '../service-config';



export interface IUserService {
    updateMidService: (param: { userId: string, mId: string; }) => Promise<void>;
}


const userService: IUserService = {
    updateMidService: async (param: { userId: string, mId: string; }) => {
        try {

            await postAsync<void, { id: string, mId: string; type: string; }>(
                seller.updateMidUrl,
                {
                    type: 'USER_MERCHANT_ID',
                    id: param.userId,
                    mId: param.mId
                },
                {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
                ,
                ReCaptchaAction.api_post,
                true
            );

        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
        }
    }
};


export const { updateMidService } = userService;