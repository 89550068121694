import React from 'react';

export default function Sites() {
  return (
    <div>
      <h2>This is not really an important page other than to list out a few of our other domains.</h2>
      <p>
        <a aria-label='link' href="https://www.midnightmerchant.com.au">MidnightMerchant.com.au</a>
      </p>
      <p>
        <a aria-label='link' href="https://www.midnightmerchant.com.au">Midnight-Merchant.com.au</a>
      </p>
      <p>
        <a aria-label='link' href="https://www.okonomi.one">OkonomiOne</a>
      </p>
      <p>
        <a aria-label='link' href="https://www.collectorspoke.com">CollectorSpoke</a>
      </p>
      <p>
        <a aria-label='link' href="https://midnightmerchant.net">MidnightMerchant.net</a>
      </p>

      <p></p>
    </div>
  );
}
