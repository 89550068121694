import React from 'react';
import { Heading } from '../../../components/shared/Heading';
import classes from './header-and-content.module.scss';
import { HeadingProps } from 'src/components/shared/InstructionProps';

export function HeaderAndContent(props: { title?: string, content?: string, children?: JSX.Element | JSX.Element[]; headingProps?: HeadingProps; }) {

    return (
        <div className={classes.headerAndContent}>
            <span aria-label={props.title} className={classes.title}>
                <Heading text={props.title} />
            </span>
            <hr />
            {props.children && <div className={classes.content} aria-labelledby={props.title}>
                {/* {props.content} */}
                {props.children}
            </div>}
        </div>);
}


export function Content(props: { content: string | Array<any>; }) {
    return (
        <div className={classes.headerAndContent}>
            <div className={classes.content}>
                {Array.isArray(props.content) ? <></> : props.content}
            </div>
        </div>);
}

