import React from 'react';
import { IStepperButtonProps } from './iStepperButtonProps';
import classes from './../../styles/stepperButtons.module.scss';
import { RainbowButton } from '../../components/shared/Button';

export function StepperButtons(props: IStepperButtonProps) {
  const buttonStyle = {
    // minWidth: 180,
    height: 40,
  };

  return (
    <stepper-buttons class={classes.container}>
      {props.previousProps.isHidden ? (
        <div style={buttonStyle}></div>
      ) : (
        <RainbowButton
          disabled={props.previousProps.disabled}
          onClick={props.previousProps.onClick}
          variant="secondary-outline"
          style={{ ...buttonStyle }}
        >
          {'< '} {props.previousProps.label || 'Previous'}
        </RainbowButton>
      )}
      <div className={classes.children}>{props.children}</div>
      {props.nextProps.isHidden ? (
        <div></div>
      ) : (
        <RainbowButton
          style={buttonStyle}
          onClick={props.nextProps.onClick}
          disabled={props.nextProps.disabled}
        >
          {(props.nextProps.label || 'Next')} {' >'}
        </RainbowButton>
      )}
    </stepper-buttons>
  );
}
