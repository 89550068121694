import React, { useEffect, useRef } from 'react';
import {
  selectActiveFilter,
  selectFilterGroups,
  selectIsLoadingSearchFilters,
  selectShowFilterDialog,
} from '../features/listingSearch/listingSearchSelectors';
import { useAppDispatch, useAppSelector } from '../hook';
import classes from '../styles/FilterPanel.module.scss';
import { CSSProperties, useCallback, useState } from 'react';

import { FilterDialog } from './FIlterDialog';
import { Filter } from '../models/filter';
import {
  setSelectedFilters,
  setShowFilterDialog,
  setActiveFilter as setFilter,
  clearAllFilters,
} from '../features/listingSearch/listingSearchSlice';

import { useNavigate } from 'react-router-dom';
import { FilterGroups, ISetSelectedFilter } from '../models/filterGroup';
import FAIcon from './shared/FAIcon';
import { getListingFiltersCounts, searchListings } from '../features/listingSearch/searchActions';


export function SelectedFiltersPanel(props: {
  className?: string;
  dialog?: boolean;
  onSelected?: (path: string[]) => void;
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const activeFilterGroup = useAppSelector<Filter>(selectActiveFilter);
  const isLoadingSearchFilters = useAppSelector<boolean>(selectIsLoadingSearchFilters);
  const filterGroup = useAppSelector<Filter | undefined>(selectFilterGroups);
  const showDialog = useAppSelector(selectShowFilterDialog);
  const [activeFilter, setActiveFilter] = useState<string[]>([]);
  const previousActiveFilter = useRef<string[]>();


  useEffect(() => {

    if (previousActiveFilter.current === activeFilter) {
      return;
    }

    dispatch(setFilter(activeFilter));
    previousActiveFilter.current = activeFilter;
    dispatch(getListingFiltersCounts());

  }, [activeFilter]);


  const onFilterClicked = (filterGroup: Filter) => {
    let path = filterGroup.path;

    if (Object.keys(filterGroup.filters).length === 0) {
      path = filterGroup.path.filter(
        (item, index) => index !== filterGroup.path.length - 1
      );
    }

    const newPath = filterGroup.childrenType ? [...path, filterGroup.childrenType] : filterGroup.path;

    setActiveFilter(newPath);
    dispatch(setFilter(newPath));

    props.onSelected?.(path);

    if (props.dialog) {
      dispatch(setShowFilterDialog(true));
    } else {
      navigate(path[path.length - 1], {
        state: { selectedFilter: path },
      });
    }
  };

  const getFiltersFromKey = useCallback((filterIndex: FilterGroups): JSX.Element[] => {

    const filters = Object.keys(filterIndex).map((I) => filterIndex[I])
      .sort((a, b) => (a.category?.order ?? 0) - (b.category?.order ?? 0));

    const groups = filters.groupBy(i => i.category?.displayName ?? '');

    return Object.keys(groups).map((category) =>
      <React.Fragment key={category}>
        <div className={classes.filterCategory}>
          {groups[category][0].category?.icon && <img src={groups[category][0].category?.icon} alt="filter-icon" />}{category}</div>
        {groups[category].map((filterGroup: Filter) => (
          <div className={classes['search-filters']} key={filterGroup.value}>
            <div
              className={`${classes['search-filter-group-header']}`}
              onClick={() => {
                onFilterClicked(filterGroup);
              }}
            >
              {filterGroup.label}
            </div>
            <div className={classes['search-filter-group']}>
              <FilterList
                filter={filterGroup}
                onFilterChecked={onFilterClicked}
                removeFilter={removeFilter}
                onlyShowIfSelected
              ></FilterList>
            </div>
          </div>
        ))}
      </React.Fragment>);
  }, []);

  const clearFilters = () => {
    dispatch(clearAllFilters());
    dispatch(searchListings(0));
  };

  const removeFilter = async (path: string[]) => {
    dispatch(setSelectedFilters({ path: path, autoCommit: true, checked: false } as ISetSelectedFilter));
    dispatch(searchListings(0));
    setActiveFilter([]);
  };

  return (
    <>
      <FilterDialog
        activeFilterPath={activeFilter}
        activeFilterGroup={activeFilterGroup}
        activeFilterChanged={setActiveFilter}
        filters={filterGroup}
        showDialog={showDialog}
        onClose={() => dispatch(setShowFilterDialog(false))}
        isLoadingFilters={isLoadingSearchFilters}
      ></FilterDialog>
      <div
        className={classes['search-filters-container'] + ' ' + (props.className ?? '')}
      >
        <div className={classes.filterheading}>
          <div className={classes.iconHeading}>
            <img src={`${process.env.REACT_APP_IMAGES_URL}/assets/pokemon/menu-categories/refine-search.svg`} alt='refine search' />
            <span>Refine Search</span>
          </div>
          <a aria-label='link' onClick={clearFilters} href="#">Clear All </a>
        </div>
        <div className={classes.container}>
          {getFiltersFromKey(filterGroup?.filters || {})}
        </div>
      </div >

    </>
  );
}

export function FilterList(props: {
  filter: Filter;
  className?: string;
  style?: CSSProperties;
  onlyShowIfSelected?: boolean;
  removeFilter: (path: string[]) => void;
  onFilterChecked: (filterGroup: Filter) => void;
}): JSX.Element {
  const mapObjectKeys = (filter: Filter) => {
    const objectKeys = Object.keys(filter.filters).map((i) => filter.filters[i]) || [];
    return objectKeys;
  };

  const Filters = useCallback(
    (innerProps: {
      filter: Filter;
      className?: string;
      style?: CSSProperties;
      onlyShowIfSelected?: boolean;
      removeFilter: (path: string[]) => void;
      onFilterChecked: (filterGroup: Filter) => void;
    }) => {
      return (
        <>
          {mapObjectKeys(innerProps.filter)
            .filter((f) => (innerProps.onlyShowIfSelected ? f.selected && !f.isDirty : true)).map((filter, index) => (
              <div
                className={classes.filter}
                key={`${filter.value}_${index}`}
                style={{ flexDirection: 'column', ...innerProps.style }}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <label
                    className={classes.selectedFilter}
                    onClick={() => innerProps.onFilterChecked(filter)}
                    aria-label={filter.label}
                  >
                    {filter.label}
                  </label>
                  <div
                    className={classes.close}
                    onClick={() => innerProps.removeFilter(filter.path)}
                  >
                    <FAIcon uniCode="F00d" />
                  </div>
                </div>
                <Filters
                  filter={filter}
                  className={props.className}
                  style={{ paddingLeft: 15 }}
                  onFilterChecked={props.onFilterChecked}
                  removeFilter={props.removeFilter}
                  onlyShowIfSelected
                ></Filters>
              </div>
            ))}
        </>
      );
    },
    [props.className, props.onFilterChecked, props.removeFilter, props.filter]
  );

  return (
    <Filters
      filter={props.filter}
      onlyShowIfSelected
      className={props.className}
      removeFilter={props.removeFilter}
      onFilterChecked={props.onFilterChecked}
    ></Filters>
  );
}
