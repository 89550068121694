import React, { useEffect, useState } from 'react';
import classes from './faq.module.scss';
import { ReactComponent as FAQICON } from '../../assets/svg/user-profile/faq.svg';

import { Heading } from '../../components/shared/Heading';
import { error } from '../../actions/logActions';
import axios from 'axios';
import { Link, Outlet } from 'react-router-dom';
import { Main } from 'src/components/Main';
import { routeConfig } from 'src/routes/routeConfig';
import { useMobileFooterBar } from 'src/views/useMobileFooterBar';

export function useFAQ(url = '/content/faq.json') {

    const [faqs, setFaqs] = useState<{ title: string, content: string; }[]>([]);

    useEffect(() => {
        let isActive = true;

        const data = async () => {
            const data = await axios.get(url);
            try {
                const json = await data.data;
                if (isActive) {
                    setFaqs(json);
                }
            } catch (e: any) {
                error('FAQ_LOAD_ERROR', { errorMessage: e.message, currentUrl: url, moduleName: 'FAQ.tsx' });
            }
        };

        data();

        return () => {
            isActive = false;
        };
    }, [url]);

    return faqs;
}


export function FAQ(props: { url: string; title: string; }) {



    const {
        MobileFooterBar,
    } = useMobileFooterBar();


    return (
        <Main>
            <div className={classes.faq} >
                <Heading color="#98FFF9">
                    <div className="flex-row-left flex-column-gap-5">
                        <FAQICON height={30} width={30} />
                        {props.title}
                    </div>
                </Heading>
                <div className={classes.content}>
                    <div className={classes.faqMenu}>
                        <Link to={routeConfig.faq.sellerInformation}>Seller Information</Link>
                        <Link to={routeConfig.faq.buyerInformation}>Buyer Information</Link>
                        <Link to={routeConfig.faq.cardKnowledge}>Card Knowledge</Link>
                        <Link to={routeConfig.faq.priceData}>Price Data</Link>
                        <Link to={routeConfig.faq.discord}>Discord</Link>
                        <Link to={routeConfig.faq.siteRules}>Site Rules</Link>
                        <Link to={routeConfig.faq.disputes}>Disputes</Link>
                    </div>
                    <div className={classes.faqData}>
                        <Outlet />
                        {MobileFooterBar}
                    </div>
                </div>
            </div >
        </Main>
    );
}

