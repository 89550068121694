/* eslint-disable @typescript-eslint/no-unused-vars */
import { createListenerMiddleware } from '@reduxjs/toolkit';
import { getMarketAnalyticsSnapshot, getMarketAnalyticsSummary } from '../../features/listing/listingActions';
import { IAsyncPayload } from 'src/features/listing/IAsyncPayload';
import { setIsLoadingMarketData, setMarketAnalyticsGraphData, setMarketAnalyticsSummary } from '../../features/listing/listingSlice';
import { IMarketDataSnapshot, IMarketDataSummary } from '../../services/listing-service/models/IMarketDataSummary';

export const listingMiddleware = createListenerMiddleware();


const getMarketAnalyticsPending = listingMiddleware.startListening({
    actionCreator: getMarketAnalyticsSnapshot.pending,
    effect: (action, listenerApi) => {
        listenerApi.cancelActiveListeners();
        listenerApi.dispatch(setMarketAnalyticsGraphData({ data: {}, isLoading: true }));
    }
});

const getMarketAnalyticsFulfilled = listingMiddleware.startListening({
    actionCreator: getMarketAnalyticsSnapshot.fulfilled,
    effect: (action, listenerApi) => {
        listenerApi.cancelActiveListeners();
        const graphData = graphDataSetMapper(action.payload);
        listenerApi.dispatch(setMarketAnalyticsGraphData({ data: graphData, isLoading: false }));
    }
});

const getMarketAnalyticsSummaryPending = listingMiddleware.startListening({
    actionCreator: getMarketAnalyticsSummary.pending,
    effect: (action, listenerApi) => {
        listenerApi.cancelActiveListeners();
        listenerApi.dispatch(setMarketAnalyticsSummary({ data: {} as IMarketDataSummary, isLoading: true }));
    }
});

const getMarketAnalyticsSummaryFulfilled = listingMiddleware.startListening({
    actionCreator: getMarketAnalyticsSummary.fulfilled,
    effect: (action, listenerApi) => {
        listenerApi.cancelActiveListeners();
        listenerApi.dispatch(setMarketAnalyticsSummary({ data: action.payload.data, isLoading: false }));
    }
});

const graphDataSetMapper = (payload: IAsyncPayload<IMarketDataSnapshot[]>) => {
    const graph = {
        labels: payload.data?.sort((a, b) => a.unix_date - b.unix_date).map(c => new Date(c.month_date).toLocaleString('en-AU', { month: 'short', year: '2-digit' })),
        datasets: [
            {
                type: 'line' as const,
                label: '5th Percentile ',
                fill: false,
                borderColor: '#00FFFF',
                backgroundColor: '#0B8E90',
                borderWidth: 2,
                pointRadius: 0,
                data: payload.data?.map((c: any) => c.sold_price_5th_percentile_3_roll),
            },
            {
                type: 'line' as const,
                label: 'Average Sold Price',
                fill: false,
                borderColor: '#05FF00',
                backgroundColor: '#0D8E11',
                borderWidth: 2,
                pointRadius: 0,
                data: payload.data?.map((c: any) => c.sold_avg_input_3_roll),
            },
            {
                type: 'line' as const,
                label: 'Median Sold Price',
                fill: false,
                borderColor: '#EE8F00',
                backgroundColor: '#825611',
                borderWidth: 2,
                pointRadius: 0,
                data: payload.data?.map((c: any) => c.sold_price_median_3_roll),
            },
            {
                type: 'line' as const,
                label: '95th Percentile',
                fill: false,
                borderColor: '#B140C3',
                backgroundColor: '#632F72',
                borderWidth: 2,
                pointRadius: 0,
                data: payload.data?.map((c: any) => c.sold_price_95th_percentile_3_roll),
            },
        ]
    };
    return graph;
};