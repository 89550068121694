import React from 'react';
import { InsightsPage } from './InsightsPage';
import { Route } from 'react-router-dom';
import { InsightsCenter } from 'src/views/InsightsCenter';
import { CardInsights } from './CardInsights';
import { Main } from 'src/components/Main';

export function InsightsRoutes() {
    return (
        <Route path="insights">
            <Route path="/insights" element={<InsightsCenter><InsightsPage /></InsightsCenter>} />
            <Route path="/insights/card/:id/:series/:set/:name" element={<Main><CardInsights /></Main>} />
        </Route>
    );
}
