import React, { ReactNode } from 'react';
import classes from './accent.module.scss';

export function Accent(props: { children?: ReactNode, variant?: 'primary' | 'secondary', style?: React.CSSProperties; }) {
    return (
        <span className={classes[props.variant ?? 'primary']} style={props.style}>
            {props.children}
        </span>
    );
}
