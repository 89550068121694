/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import { createListenerMiddleware } from '@reduxjs/toolkit';
import {
    listingAutoComplete,
    getFilters,
    getListingFiltersCounts,
    searchListings,
    // searchListingsV2,
} from '../../features/listingSearch/searchActions';
import { logEvents } from '../../actions/logActions';
import {
    setAutoCompleteSearchResults,
    setDirtyFilters,
    setIsLoadingAutocomplete,
    setIsLoadingFilters,
    setIsLoadingListings,
    setListingSearchString,
    setSelectedFilters,
    setSolrSearchFilter,
    setStateFilterCounts
} from '../../features/listingSearch/listingSearchSlice';
import { getListingSearchParameters, getSelectedDataFilters, SearchType } from '../../services/search-service/searchUtilities';
import { IStoreState } from '../IStore';

export const searchMiddleware = createListenerMiddleware();

const setSelectedFiltersListener = searchMiddleware.startListening({
    actionCreator: setSelectedFilters,
    effect: (action, listenerApi) => {
        listenerApi.cancelActiveListeners();
        const { queryFilter } = getListingSearchParameters((listenerApi.getState() as IStoreState), SearchType.filters);
        listenerApi.dispatch(setSolrSearchFilter(queryFilter));
    }
});

const searchFiltersPendingListener = searchMiddleware.startListening({
    actionCreator: getListingFiltersCounts.pending,
    effect: (action, listenerApi) => {
        listenerApi.cancelActiveListeners();
        listenerApi.dispatch(setIsLoadingFilters(true));
    }
});

const searchFiltersFullFilledListener = searchMiddleware.startListening({
    actionCreator: getListingFiltersCounts.fulfilled,
    effect: (action, listenerApi) => {
        listenerApi.cancelActiveListeners();
        listenerApi.dispatch(setStateFilterCounts(action.payload));
        listenerApi.dispatch(setIsLoadingFilters(false));
    }
});
const searchFiltersRejectedListener = searchMiddleware.startListening({
    actionCreator: getListingFiltersCounts.rejected,
    effect: (action, listenerApi) => {
        listenerApi.cancelActiveListeners();
        listenerApi.dispatch(setIsLoadingFilters(false));
    }
});

const searchListingsFulfilledListener = searchMiddleware.startListening({
    actionCreator: searchListings.fulfilled,
    effect: (action, listenerApi) => {
        listenerApi.cancelActiveListeners();
        listenerApi.dispatch(setDirtyFilters(false));
        listenerApi.dispatch(setIsLoadingListings(false));
        listenerApi.dispatch(logEvents({
            eventName: 'SEARCH_TEXT', payload: {
                searchTerm: (listenerApi.getState() as IStoreState)
                    .listingSearch.listingSearchParameters?.autoCompleteSearchString
            }
        }));
        const selectedFilters = getSelectedDataFilters((listenerApi.getState() as IStoreState).listingSearch.filterData);
        listenerApi.dispatch(logEvents({ eventName: 'SEARCH_CATEGORY', payload: { 'search-filters': selectedFilters.join(',') } }));
    }
});

const searchListingsRejectedListener = searchMiddleware.startListening({
    actionCreator: searchListings.rejected,
    effect: (action, listenerApi) => {
        listenerApi.cancelActiveListeners();
        listenerApi.dispatch(setIsLoadingListings(false));
    }
});


const searchListingsPendingListener = searchMiddleware.startListening({
    actionCreator: searchListings.pending,
    effect: (action, listenerApi) => {
        listenerApi.cancelActiveListeners();
        listenerApi.dispatch(setIsLoadingListings(true));
    }
});

const getFiltersFulfilledListener = searchMiddleware.startListening({
    actionCreator: getFilters.fulfilled,
    effect: (action, listenerApi) => {
        listenerApi.cancelActiveListeners();
        // listenerApi.dispatch(getListingFiltersCounts());

    }
});

const autocompleteSearchPendingListener = searchMiddleware.startListening({
    actionCreator: listingAutoComplete.pending,
    effect: (action, listenerApi) => {
        listenerApi.cancelActiveListeners();
        listenerApi.dispatch(setIsLoadingAutocomplete(true));
    }
});

const autocompleteSearchRejectedListener = searchMiddleware.startListening({
    actionCreator: listingAutoComplete.rejected,
    effect: (action, listenerApi) => {
        listenerApi.cancelActiveListeners();
        listenerApi.dispatch(setIsLoadingAutocomplete(false));
    }
});

const autocompleteSearchFullFilledListener = searchMiddleware.startListening({
    actionCreator: listingAutoComplete.fulfilled,
    effect: (action, listenerApi) => {
        listenerApi.cancelActiveListeners();
        listenerApi.dispatch(setAutoCompleteSearchResults(action.payload));
        listenerApi.dispatch(setIsLoadingAutocomplete(false));
    }
});

const setListingSearchStringAction = searchMiddleware.startListening({
    actionCreator: setListingSearchString,
    effect: (action, listenerApi) => {
        listenerApi.cancelActiveListeners();
        //listenerApi.dispatch(searchListingsV2(0));
        listenerApi.dispatch(searchListings(0));
        listenerApi.dispatch(setIsLoadingAutocomplete(false));
    }
});


