import { gql } from '@apollo/client';



export const getMessageQuery = {
  query: gql`query Query($userId: String) {
  listingMessages(userId: $userId) {
    listingId
    timeStamp
    title
    senderId
    imgUrl
    correspondents
    messages {
      recipient
      sender
      timestamp
      body
    }
  }
}
`,
  variables: {
    userId: undefined,
  },
};

