/* eslint-disable no-console */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { Card, db, Manifest } from './db';

export const getCards = createAsyncThunk<Card[]>(
  'data/getCards',
  async (arg, thunkApi) => {
    try {
      const response = await axios.get('/data/cards.json');
      return response.data;
    } catch (ex: any) {
      console.warn(ex);
      return thunkApi.rejectWithValue(ex);
    }
  }
);

export const getRemoteManifest = createAsyncThunk<Manifest[]>(
  'data/getRemoteManifest',
  async (arg, thunkApi) => {
    try {
      const response = await axios.get('/data/data-manifest.json');
      return response.data;
    } catch (ex: any) {
      console.warn(ex);
      return thunkApi.rejectWithValue(ex);
    }
  }
);

export const getLocalManifest = createAsyncThunk(
  'data/getActiveManifest',
  async (arg, thunkApi) => {
    try {
      return db.manifest.toArray();
    } catch (ex: any) {
      console.warn(ex);
      return thunkApi.rejectWithValue(ex);
    }
  }
);
