import React from 'react';
import { Dialog } from '../../components/shared/dialog';
import { RainbowButton } from '../../components/shared/Button';
import classes from './confirm-dialog.module.scss';
import { Instruction } from '../../components/shared/Instruction';


export interface ConfirmDialogProps {
    title?: string;
    message?: string;
    notes?: string;
    open: boolean;
    children?: React.ReactNode | React.ReactNode[];
    onClose: () => void;
    onConfirm: () => void;
}

export function ConfirmDialog(props: ConfirmDialogProps) {
    return <Dialog show={props.open} onClose={props.onClose}>
        <div className={classes.container}>
            <div className={classes.title}>{props.title ?? 'Are you sure?'}</div>
            <div className={classes.description}>
                <p>
                    {props.message ?? 'Are you sure?'}
                </p>

                {props.notes && <Instruction size='small' >
                    {props.notes ?? ''}
                </Instruction>}
            </div>
            {props.children}
            <div className={classes.actions}>
                <RainbowButton variant='secondary-outline' onClick={props.onClose}>Cancel</RainbowButton>
                <RainbowButton onClick={props.onConfirm}>Confirm</RainbowButton>
            </div>
        </div>
    </Dialog>;
}
