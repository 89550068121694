
import React from 'react';

export const useDebounceCallback = <CallbackArgs extends any[]>(
  callback: (...args: CallbackArgs) => void,
  wait = 100,
  leading = false
): ((...args: CallbackArgs) => void) => {
  const storedCallback = useLatest(callback);
  const timeout = React.useRef<ReturnType<typeof setTimeout>>();

  // Cleans up pending timeouts when the deps change
  React.useEffect(
    () => () => {
      timeout.current && clearTimeout(timeout.current);
      timeout.current = void 0;
    },
    [wait, leading, storedCallback]
  );

  return React.useCallback(function () {
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;
    const { current } = timeout;
    // Calls on leading edge
    if (current === void 0 && leading) {
      timeout.current = setTimeout(() => {
        timeout.current = void 0;
      }, wait);
      // eslint-disable-next-line prefer-spread
      return storedCallback.current.apply(null, args as any);
    }
    // Clear the timeout every call and start waiting again
    current && clearTimeout(current);
    // Waits for `wait` before invoking the callback
    timeout.current = setTimeout(() => {
      timeout.current = void 0;
      storedCallback.current.apply(null, args as any);
    }, wait);
  }, [wait, leading, storedCallback]);
};

export const useDebounce = <State>(
  initialState: State | (() => State),
  wait?: number,
  leading?: boolean
): [
    State,
    React.Dispatch<React.SetStateAction<State>>,
    React.Dispatch<React.SetStateAction<State>>
  ] => {
  const state = React.useState(initialState);
  return [state[0], useDebounceCallback(state[1], wait, leading), state[1]];
};



export function debounce(this: any, func: any, timeout = 300) {
  let timer: any;
  return (...args: any[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}


export const useLatest = <T>(current: T) => {
  const storedValue = React.useRef(current);
  React.useEffect(() => {
    storedValue.current = current;
  });
  return storedValue;
};

