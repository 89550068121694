import React, { useEffect } from 'react';
import { Dialog } from './shared/dialog';
import classes from './ratingDialog.module.scss';
import { createPortal } from 'react-dom';
import { IUserRating } from '../features/user/buyer/buyerActions';
import { TextBox } from './shared/TextBox';
import { useDebounceCallback } from '../hooks/useDebounce';
import Slider from '@mui/material/Slider';
import { Instruction } from './shared/Instruction';

const defaultRating: IUserRating = {
    speedOfShipping: 0,
    accuracyOfDescription: 0,
    communication: 0,
    comment: ''
} as IUserRating;

export function RatingDialog(props: { show: boolean; onClose: () => void; onRatingSelected?: (rating: IUserRating) => void, selectedRating?: IUserRating; onSubmit?: () => void; }) {
    const [isOpen, setIsOpen] = React.useState(props.show);
    const [rating, setRating] = React.useState<IUserRating>(props.selectedRating || defaultRating);
    const textDebounce = useDebounceCallback(onCommentChanged, 500);

    function onRatingSelected(rate: any) {
        setRating({ ...rating, ...rate });
        props.onRatingSelected?.({ ...rating, ...rate });
    }

    function onCommentChanged(comment: string) {
        setRating({ ...rating, comment });
        props.onRatingSelected?.({ ...rating, comment });
    }

    function submitRating() {
        props.onSubmit?.();
        props.onClose();
    }

    function cancel() {
        props.onRatingSelected?.({} as IUserRating);
        props.onClose();
    }

    function sliderValueText(value: number) {
        return `${value}`;
    }

    useEffect(() => {
        setIsOpen(props.show);
    }, [props.show]);


    return createPortal(
        <Dialog show={isOpen} onClose={props.onClose} style={{ padding: 20 }}>
            <user-rating-dialog class={classes['user-rating-dialog']}>
                <div className={classes.heading}>
                    <h4>Rate your experience</h4>
                </div>
                <div className={classes.ratings}>

                    <Instruction text="Postage" color="white" italic={false} />
                    <Instruction text="Less than 48 hours before the seller sent you a tracking number" size='small' />
                    <Slider
                        defaultValue={1}
                        getAriaValueText={sliderValueText}
                        aria-labelledby="discrete-slider"
                        valueLabelDisplay="auto"
                        value={rating.speedOfShipping}
                        onChange={(e, v) => onRatingSelected({ speedOfShipping: v })}
                        step={1}
                        marks
                        min={1}
                        max={5}
                    />

                    <Instruction text="Quality Accuracy" color="white" italic={false} />
                    <Instruction text="The item was as described in the listing." size='small' />
                    <Slider
                        defaultValue={1}
                        getAriaValueText={sliderValueText}
                        aria-labelledby="discrete-slider"
                        valueLabelDisplay="auto"
                        value={rating.accuracyOfDescription}
                        onChange={(e, v) => onRatingSelected({ accuracyOfDescription: v })}
                        step={1}
                        marks
                        min={1}
                        max={5}
                    />

                    <Instruction text="Communication" color="white" italic={false} />
                    <Instruction text="The seller responded within a few hours, and had meaningful answers" size='small' />
                    <Slider
                        defaultValue={1}
                        getAriaValueText={sliderValueText}
                        aria-labelledby="discrete-slider"
                        valueLabelDisplay="auto"
                        value={rating.communication}

                        onChange={(e, v) => onRatingSelected({ communication: v })}
                        step={1}
                        marks
                        min={1}
                        max={5}
                    />

                    <Instruction text="Comments" color="white" italic={false} />
                    <Instruction text="A couple of comments to help the seller improve" size='small' />
                    <TextBox
                        inputProps={{
                            defaultValue: rating.comment,
                            onChange: (event) => textDebounce(event.currentTarget.value),
                            maxLength: 255,
                            name: 'description',
                            required: true,
                            className: classes.comment

                        }}
                    ></TextBox>
                </div>
                <div className={classes.actionButtons} >
                    <a aria-label='link' className={classes.cancelButton} style={{ padding: 5 }} onClick={() => cancel()}>Cancel</a>
                    <a aria-label='link' className={classes.postButton} style={{ padding: 5 }} onClick={() => submitRating()}>Post review</a>
                </div>
            </user-rating-dialog>
        </Dialog>, document.body);

}
