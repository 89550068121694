
import React, { CSSProperties } from 'react';

const style: CSSProperties = { fontFamily: 'fa' };

export default function FAIcon(props: {
  uniCode: string;
  style?: CSSProperties;
  onClick?: (event: React.MouseEvent<HTMLSpanElement>) => void;
}) {
  return (
    <span
      style={{ ...style, ...props.style }}
      dangerouslySetInnerHTML={{ __html: `&#x${props.uniCode};` }}
      onClick={props.onClick}
    ></span>
  );
}
