import React, { useCallback } from 'react';
import { useState } from 'react';
import { ListingType, ListingTypeLink } from '../../components/tiles/Tiles';
import classes from '../../styles/cardListing.module.scss';
import { Section } from './Section';

import { ReactComponent as SingleCard } from '../../assets/svg/create-listing/create-single.svg';
import { ReactComponent as MixedBundle } from '../../assets/svg/create-listing/create-mixed-bundle.svg';
import { ReactComponent as SealedProduct } from '../../assets/svg/create-listing/create-sealed-product.svg';
import { ComingSoon } from '../../components/shared/ComingSoon';
import { RainbowButton } from '../../components/shared/Button';
import { useNavigate } from 'react-router-dom';

export function CardListingType(props: {
  onSelected: (value: ListingType) => void;
  selected?: ListingType;
  name?: string;
}) {

  const [selected, setSelected] = useState<ListingType>(props.selected || ListingType.unknown);
  const navigate = useNavigate();
  const isSelected = useCallback((type: ListingType) => {
    return selected === type;
  }, [selected]);

  const backToMarketplace = () => {
    navigate('/');
  };
  const onSelected = (value: ListingType) => {
    setSelected(value);
    props.onSelected(value);
  };
  return (
    <Section title="" subTitle="">
      <div
        style={{
          textAlign: 'center',
        }}
      >
        <div className={`${classes.root} ${!isSelected(ListingType.unknown) ? classes.selected : ''}`}        >
          <div className={`${classes.singleCard} ${isSelected(ListingType.singleCard) ? classes.selected : ''}`} >
            <ListingTypeLink
              className={isSelected(ListingType.singleCard) ? classes.selected : ''}
              isSelected={isSelected(ListingType.singleCard)}
              img={SingleCard}
              label="createListing.listingType.singleCard"
              onClick={() => onSelected(ListingType.singleCard)}
              to="/create-listing/search"
              imgProps={{ style: { height: 240 } }}
            />
            <span style={{ color: '#505659', fontStyle: 'italic' }} >Have a particular card you want to sell? This is the option for you!</span>
          </div>


          <div className={classes.mixedBundle}>
            <ListingTypeLink
              className={selected === ListingType.mixedBundle ? classes.selected : ''}
              isSelected={selected === ListingType.mixedBundle}
              img={MixedBundle}
              label="createListing.listingType.bundleCards"
              onClick={() => onSelected(ListingType.mixedBundle)}
              to="/create-listing/title"
            />
            <span style={{ color: '#505659', fontStyle: 'italic' }} >Just want to sell a pile of goodies for one price?</span>
          </div>
          <ComingSoon contentProps={{ style: { display: 'flex' } }}>

            <div className={classes.bundleSealed}>
              <ListingTypeLink
                className={selected === ListingType.mixedBundle ? classes.selected : ''}
                isSelected={selected === ListingType.mixedBundle}
                img={SealedProduct}
                label="createListing.listingType.sealedSingle"
                onClick={() => onSelected(ListingType.mixedBundle)}
                to="/create-listing/search"
              />
              <span style={{ color: '#505659', fontStyle: 'italic' }} >Have a particular sealed product that needs a new home?</span>
            </div>
          </ComingSoon>
        </div>
        <div className={classes.actions}>
          <RainbowButton onClick={backToMarketplace}>Back to Marketplace</RainbowButton>
          <span>*<a aria-label='link' href="/terms" target='_blank'>View Terms of Service</a></span>
        </div>
      </div>

    </Section >
  );
}
