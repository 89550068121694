/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import BarcodeReader from 'src/barcodeReader/zxing_reader';
const reader = await BarcodeReader();


export enum BarcodeFormat {
    Aztec = 'Aztec',
    Codabar = 'Codabar',
    Code128 = 'Code128',
    Code39 = 'Code39',
    Code93 = 'Code93',
    DataBar = 'DataBar',
    DataBarExpanded = 'DataBarExpanded',
    DataMatrix = 'DataMatrix',
    DXFilmEdge = 'DXFilmEdge',
    EAN13 = 'EAN-13',
    EAN8 = 'EAN-8',
    ITF = 'ITF',
    LinearCodes = 'Linear-Codes',
    MatrixCodes = 'Matrix-Codes',
    MaxiCode = 'MaxiCode',
    MicroQRCode = 'MicroQRCode',
    None = 'None',
    PDF417 = 'PDF417',
    QRCode = 'QRCode',
    rMQRCode = 'rMQRCode',
    UPCA = 'UPC-A',
    UPCE = 'UPC-E',
}

export function useBarCodeReader(canvasRef: React.RefObject<HTMLCanvasElement>) {

    const [decodedBarcode, setDecodedBarcode] = React.useState<string>();
    const [mediaDevices, setMediaDevices] = React.useState<MediaDeviceInfo[]>();

    useEffect(() => {

        if (!canvasRef.current) return;

        const ctx = canvasRef.current.getContext('2d', { willReadFrequently: true });
        const video = document.createElement('video');
        video.setAttribute('id', 'video');
        video.setAttribute('width', canvasRef.current.width.toString());
        video.setAttribute('height', canvasRef.current.height.toString());
        video.setAttribute('autoplay', '');

        function readBarcodeFromCanvas(canvas: HTMLCanvasElement, format: string, mode: string) {
            if (canvas === null) return { error: 'Canvas not found' };

            const imgWidth = canvas.width;
            const imgHeight = canvas.height;
            const imageData = canvas.getContext('2d')?.getImageData(0, 0, imgWidth, imgHeight);
            const sourceBuffer = imageData?.data;

            if (reader != null) {
                const buffer = reader._malloc(sourceBuffer?.byteLength);
                reader.HEAPU8.set(sourceBuffer, buffer);
                const result = reader.readBarcodeFromPixmap(buffer, imgWidth, imgHeight, mode, format);
                reader._free(buffer);
                return result;
            } else {
                return { error: 'ZXing not yet initialized' };
            }
        }

        function drawResult(code: any) {
            if (ctx) {
                ctx.beginPath();
                ctx.lineWidth = 4;
                ctx.strokeStyle = 'yellow';
                // ctx.textAlign = "center";
                // ctx.fillStyle = "#green"
                // ctx.font = "25px Arial";
                // ctx.fontWeight = "bold";

                ctx.moveTo(code.position.topLeft.x, code.position.topLeft.y);
                ctx.lineTo(code.position.topRight.x, code.position.topRight.y);
                ctx.lineTo(code.position.bottomRight.x, code.position.bottomRight.y);
                ctx.lineTo(code.position.bottomLeft.x, code.position.bottomLeft.y);
                ctx.lineTo(code.position.topLeft.x, code.position.topLeft.y);
                ctx.stroke();
                // ctx.fillText(code.text, (topLeft.x + bottomRight.x) / 2, (topLeft.y + bottomRight.y) / 2);
            }
        }

        function escapeTags(htmlStr: string) {
            return htmlStr.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&#39;');
        }

        const processFrame = function () {
            if (ctx && canvasRef.current) {
                ctx.drawImage(video, 0, 0, canvasRef.current.width, canvasRef.current.height);

                const code = readBarcodeFromCanvas(canvasRef.current, '', 'true');
                if (code.format) {
                    const result = escapeTags(code.text);
                    setDecodedBarcode(result);
                    drawResult(code);
                }
                requestAnimationFrame(processFrame);
            }
        };

        const updateVideoStream = function () {
            navigator.mediaDevices
                .getUserMedia({ video: { facingMode: 'environment' }, audio: false })
                .then(function (stream) {
                    video.srcObject = stream;
                    video.setAttribute('playsinline', 'true'); // required to tell iOS safari we don't want fullscreen
                    video.play();
                    processFrame();
                })
                .catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.error('Error accessing camera:', error);
                });
        };



        updateVideoStream();

    }, [canvasRef]);

    return {
        decodedBarcode,
        mediaDevices
    };

}
