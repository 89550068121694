/* eslint-disable no-console */
import { useEffect } from 'react';
import { useAppDispatch } from '../hook';
import { getSession, printSession, removeSession, setSession, StorageKey } from '../stores/Storage';
import { createAsyncThunk } from '@reduxjs/toolkit';

interface SessionStorage {
    key: StorageKey;
    value: any;
}

const setSessionStorage = createAsyncThunk('session/setSession', async (sessionStorage: SessionStorage, thunkApi: any) => {
    try {
        await setSession(sessionStorage.key, sessionStorage.value);
        return true;
    } catch (ex) {
        thunkApi.rejectWithValue(ex);
    }
});

export const removeSessionItem = createAsyncThunk('session/clearSession',
    async (sessionKey: StorageKey, thunkApi: any) => {
        try {
            await removeSession(sessionKey);
            return true;
        } catch (ex) {
            thunkApi.rejectWithValue(ex);
        }
    });


// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getSessionStorage = createAsyncThunk('session/getSession', async (key: StorageKey, thunkApi: any) => {
    try {
        return await getSession(key);
    } catch (ex) {
        thunkApi.rejectWithValue(ex);
        return false;
    }
});


export function useSessionStorage<T>(key: StorageKey, value: T) {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setSessionStorage({ key, value }));
        printSession(key);
    }, [value]);


    return {
        getSession: (key: StorageKey) => dispatch(getSessionStorage(key)),
        removeSessionItem: (key: StorageKey) => dispatch(removeSessionItem(key)),
        setSession: (key: StorageKey, value: T) => dispatch(setSessionStorage({ key, value })),
    };

}

export function useApiStorage<T>(key: StorageKey, value: T) {
    useEffect(() => {
        printSession(key);
    }, [value]);
}
