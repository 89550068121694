/* eslint-disable react/display-name */
import React from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../hook';

import { selectCurrentListing, selectListingErrors } from './createListingSelectors';

export function ListingFailed() {

    const currentListing = useAppSelector(selectCurrentListing);
    const errors = useAppSelector(selectListingErrors);

    const CurrentListing = () => <div>Creating {currentListing?.card?.name}<br></br></div>;

    const ErrorMessage = () => {

        if (Array.isArray(errors) && errors.length > 0) {
            return <div>{errors.map((error: any, index: number) =>
                <pre key={index}>{JSON.stringify(decodeURI(error), null, 2)}</pre>)}</div>;
        } else {
            return <pre>
                Something went wrong and we have notified the douch that put this all together
                Please try again later {JSON.stringify(errors, null, 2)}</pre>;
        }
    };


    return (
        <div style={{ maxWidth: 400 }}>
            <h1>Listing Failed</h1>
            <CurrentListing></CurrentListing>
            <ErrorMessage></ErrorMessage>
            <Link to="/">Home</Link>
        </div>
    );
}