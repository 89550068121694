import classes from '../../styles/text-field.module.scss';
import React, { forwardRef, useEffect, useRef, useState } from 'react';


// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const TextField = forwardRef(function (props: any, ref: any) {
  return <div className={classes.textField}>
    <input
      {...props}
      value={props.value} defaultValue={props.defaultValue} ref={ref} />
  </div>;
});


export function PhoneNumber(props: { type?: 'PhoneNumber', inputRef: any; }) {
  const [input, setInput] = useState();
  const inputRef = useRef<any>();

  const handleChange = () => {
    const value = inputRef.current.value
      .replace(/\D/g, '')
      .match(/(\d{0,4})(\d{0,3})(\d{0,3})/);

    inputRef.current.value = !value[2]
      ? value[1]
      : `${value[1]}-${value[2]}${`${value[3] ? `-${value[3]}` : ''}`}${`${value[4] ? `-${value[4]}` : ''
      }`}`;
    const numbers = inputRef.current.value.replace(/(\D)/g, '');
    setInput(numbers);
  };

  useEffect(() => {
    handleChange();
  }, [input]);

  return (
    <input {...props} type="text" ref={inputRef} onChange={handleChange} />
  );
}
