import React, { ComponentType } from 'react';
import { WithAuthenticationRequiredOptions, withAuthenticationRequired } from '@auth0/auth0-react';

export interface ProtectedRouteProps<P extends object> {
  component: ComponentType<P>;
  args?: WithAuthenticationRequiredOptions;
}

const ProtectedRoute: <T extends object>(props: ProtectedRouteProps<T>) => JSX.Element = <T extends object>(
  props: ProtectedRouteProps<T>
) => {
  const Component: any = withAuthenticationRequired(props.component, props.args);
  return <Component />;
};

export default ProtectedRoute;
