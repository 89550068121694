import { IListing } from '../../models/create-listing/iListing';
import { RootState } from '../../stores/store';
import { ICard } from './models/iCard';


const listingSelectors = {

    selectListingErrors: (state: RootState) =>
        state.createListing.listingError
    , selectCurrentListing: (state: RootState): IListing =>
        state.createListing.currentListing
    , selectCard: (state: RootState): ICard | undefined =>
        state.createListing.currentListing?.card
    , selectCardSearchInProgress: (state: RootState): boolean =>
        state.createListing.cardSearchInProgress
    , selectFeeTable: (state: RootState) => state.createListing.currentListing.feeTable
    , selectLoadingStatus: (state: RootState) => state.createListing.isLoading
    , selectListingTitle: (state: RootState) => state.createListing.currentListing.title

};

export const {
    selectCard,
    selectCardSearchInProgress,
    selectCurrentListing,
    selectListingErrors,
    selectFeeTable,
    selectLoadingStatus,
    selectListingTitle
} = listingSelectors;
