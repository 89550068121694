/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction, configureStore } from '@reduxjs/toolkit';
import listingSearchReducer from '../features/listingSearch/listingSearchSlice';
import cardSearchReducer from '../features/cardSearch/cardSearchSlice';
import listingReducer from '../features/listing/listingSlice';
import dataReducer from '../data/dataSlice';
import userReducer from '../features/user/userSlice';
import globalReducer, { setSnackbarMessage } from '../features/globalSlice';
import createListingReducer from '../features/createListing/store/createListingSlice';
import insightListingReducer from '../features/insights/insightsSlice';

import { searchMiddleware } from './middleware/searchMiddleware';
import { createListingMiddleware } from './middleware/createListingMiddleware';
import { listingMiddleware } from './middleware/listingMiddleware';
import { userMiddleware } from './middleware/userMiddleware';
import { cardsMiddleware } from './middleware/cardsMiddleware';

export interface AsyncPayload<TResultType = any> extends PayloadAction<TResultType> {
  isLoading?: boolean;
}

export const snackbarMiddleware = (store: any) => (next: any) => (action: any) => {
  if (action.type?.endsWith('rejected')) {
    store.dispatch(setSnackbarMessage({ snackbarMessage: action.payload, variant: 'error' }));
  }
  return next(action);
};

export const store = configureStore({
  reducer: {
    listingSearch: listingSearchReducer,
    cardSearch: cardSearchReducer,
    data: dataReducer,
    createListing: createListingReducer,
    listing: listingReducer,
    user: userReducer,
    insights: insightListingReducer,
    global: globalReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(
      searchMiddleware.middleware,
      createListingMiddleware.middleware,
      listingMiddleware.middleware,
      userMiddleware.middleware,
      cardsMiddleware.middleware,
      snackbarMiddleware
    ),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export type AppStore = typeof store;


export default store;

