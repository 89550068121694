import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ReactComponent as Hamburger } from '../assets/svg/hamburger.svg';
import classes from './nav-menu.module.scss';
import * as React from 'react';

const ITEM_HEIGHT = 100;

export interface NavOption {
    label: string;
    value: string;
    icon: JSX.Element;
}

export interface ActionOption {
    label: string;
    value: string;
    icon: JSX.Element;
    action?: (value: string) => void;
}


export default function NavMenu(props: { options: NavOption[], onOptionSelected: (option: NavOption) => void, children?: React.ReactNode; variant?: 'context' | 'dark'; }) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [selected, setSelected] = React.useState<NavOption>(props.options[0]);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (option: NavOption) => {
        setSelected(option);
        setAnchorEl(null);
        props.onOptionSelected(option);
    };

    return (
        <div>
            {props.children}
            <IconButton
                aria-label="user-menu"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"

                style={{ color: 'white' }}
                onClick={handleClick}
            >
                <Hamburger />
            </IconButton>
            <Menu
                id="long-menu"
                PopoverClasses={{
                    paper: props.variant ? classes[props.variant] : classes['dark']
                }}
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                    },
                }}
            >
                {props.options.map((option: NavOption) => (
                    <MenuItem key={option.label}
                        className={classes.menuItem}
                        selected={option.label === selected.label}
                        onClick={() => handleClose(option)}>

                        {option.icon}
                        {option.label}

                    </MenuItem>
                ))}
            </Menu>
        </div >
    );
}



export function ActionMenu(props: {
    options: ActionOption[],
    onOptionSelected: (option: ActionOption) => void,
    variant?: 'context' | 'dark';
    children?: React.ReactNode;
    triggerElement: React.ReactNode;
}) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [selected, setSelected] = React.useState<ActionOption>(props.options[0]);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (option: ActionOption) => {
        setSelected(option);
        setAnchorEl(null);
        props.onOptionSelected(option);
    };

    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div onClick={handleClick}>
            {props.triggerElement}
        </div>
        <Menu
            id="long-menu"
            PopoverClasses={{
                paper: props.variant ? classes[props.variant] : classes['dark']
            }}
            MenuListProps={{
                'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={open}
            onClose={handleClose}
            PaperProps={{
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: '20ch',
                },
            }}>

            {props.options.map((option: ActionOption) => (
                <MenuItem key={option.label}
                    className={classes.menuItem}
                    selected={option.label === selected.label}
                    onClick={() => handleClose(option)}>
                    {option.icon}
                    {option.label}
                </MenuItem>
            ))}
            <div>
                {props.children}
            </div>
        </Menu>
    </div >;
}