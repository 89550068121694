import React from 'react';
import styles from '../../styles/createListing.module.scss';
import { ICard } from '../createListing/models/iCard';
import { useEffect } from 'react';
import { Section } from '../createListing/Section';
import { CardSearchResults } from './CardSearchResults';
import { useAppDispatch, useAppSelector } from '../../hook';

import { useValidator as useValididatorState } from '../../hooks/useValididator';
import { IValidateEffect } from '../../hooks/IValidateEffect';
import { ListingType } from '../../components/tiles/Tiles';
import { selectCardSearchParameters, selectCardSearchResults } from './cardSearchSelectors';
import { CardSearchBar } from '../../components/SearchBar/CardSearchBar';
import { searchCardsAction } from './cardActions';
import { Instruction } from '../../components/shared/Instruction';
import Divider from '@mui/material/Divider';

export function CardSearch(props: {
  onSelected: (card: ICard) => void;
  selected: ICard | undefined;
  onValidate?: (x: IValidateEffect) => void;
  advertType: ListingType | undefined;
}) {
  const dispatch = useAppDispatch();
  const searchParameters = useAppSelector(selectCardSearchParameters);
  const searchResults = useAppSelector(selectCardSearchResults);

  const { value: selectedCard, setValue: setSelectedCard } = useValididatorState<ICard>(props.selected);

  useEffect(() => {
    props.onValidate?.({ isValid: selectedCard?.isValid, canProceed: selectedCard?.canProceed });
  }, [selectedCard?.canProceed, selectedCard.isValid]);

  const onCardSelected = (card: ICard) => {
    setSelectedCard(card);
    props.onSelected?.(card);
  };

  const onSearchBarCardSelected = async () => {
    if (searchParameters) dispatch(searchCardsAction());
  };

  return (
    <Section title="Find your card" invalid={selectedCard.isValid === false} >
      <>
        <Instruction text='Use the search bar to find your card. Use name, set, series or number' />
        <CardSearchBar onSelected={onSearchBarCardSelected} style={{ paddingRight: 10 }} />
        <Divider />
        <CardSearchResults
          searchResults={searchResults}
          selected={selectedCard.model}
          onCardAdded={onCardSelected}
          className={styles.searchResults}
        />
      </>
    </Section>
  );
}
