import React, { useState, useEffect } from 'react';

// export const SCROLL_DIRECTION_DOWN = "SCROLL_DIRECTION_DOWN";
// export const SCROLL_DIRECTION_UP = "SCROLL_DIRECTION_UP";
// export const SCROLL_DIRECTION_NONE = "SCROLL_DIRECTION_NONE";

export type direction =
  | 'SCROLL_DIRECTION_DOWN'
  | 'SCROLL_DIRECTION_UP'
  | 'SCROLL_DIRECTION_NONE';

export const useScrollDirection = (
  callback: (e: direction) => void,
  ref: React.RefObject<Window>
) => {
  const [reference, setReference] = useState(ref.current);


  const [lastYPosition, setLastYPosition] = useState(reference?.scrollY || 0);
  const [timer, setTimer] = useState<NodeJS.Timeout>();

  const handleScroll = () => {
    if (timer !== null) {
      timer && clearTimeout(timer);
    }

    setTimer(
      setTimeout(function () {
        callback('SCROLL_DIRECTION_NONE');
      }, 500)
    );
    if (reference && reference.scrollY === lastYPosition)
      return 'SCROLL_DIRECTION_NONE';

    const direction = ((): direction => {
      return lastYPosition < (reference?.scrollY || 0)
        ? 'SCROLL_DIRECTION_DOWN'
        : 'SCROLL_DIRECTION_UP';
    })();

    callback(direction);
    setLastYPosition(reference?.scrollY || 0);
  };
  useEffect(() => {
    setReference(ref.current);
  }, [reference, ref]);

  useEffect(() => {

    reference?.addEventListener('scroll', handleScroll);
    return () => reference?.removeEventListener('scroll', handleScroll);
  });
};

export const useScrollPosition = <T extends HTMLElement>(
  callback: (value: number) => void,
  ref: React.RefObject<T>
) => {
  const [reference] = useState(ref.current);

  const handleScroll = () => {
    callback(reference?.scrollTop || 0);
  };

  useEffect(() => {
    reference?.addEventListener('scroll', handleScroll);
    return () => reference?.removeEventListener('scroll', handleScroll);
  });
};
