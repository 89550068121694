import React from 'react';
import { IToggleButtonProps } from './IToggleButtonProps';
import classes from '../../styles/toggleButtonGroup.module.scss';


export function ToggleButtonGroup(props: IToggleButtonProps) {

    return <div className={`${classes.root} ${props.className ?? ''}`}>
        {props.options.map((option, index) => {
            return <div key={`${index}_tg_button`} className={`${classes.option} ${props.value == option.value ? classes.selected : ''}`} >
                <button aria-label={props.value ?? 'button'} type='button' onClick={props.onChange} value={option.value as string}>{option.label}</button></div>;
        })}
    </ div >;
}
