/* eslint-disable @typescript-eslint/no-unused-vars */
import { createListenerMiddleware } from '@reduxjs/toolkit';
import { getInsightCardDetails, getInsightsCardList } from 'src/features';
import { setInsightCardDetails, setInsightCardSearchResults } from 'src/features/insights/insightsSlice';

export const cardsMiddleware = createListenerMiddleware();


const cardsMiddlewareListeners = {
    getCardsInsightsListPending: cardsMiddleware.startListening({
        actionCreator: getInsightsCardList.pending,
        effect: (action, listenerApi) => {
            listenerApi.cancelActiveListeners;
            listenerApi.dispatch(setInsightCardSearchResults({ data: undefined, isLoading: true }));
        }
    }),
    getCardsInsightsListFFulfilled: cardsMiddleware.startListening({
        actionCreator: getInsightsCardList.fulfilled,
        effect: (action, listenerApi) => {
            listenerApi.cancelActiveListeners;
            listenerApi.dispatch(setInsightCardSearchResults({ data: action.payload, isLoading: false }));
        }
    }),
    getInsightCardDetailsPending: cardsMiddleware.startListening({
        actionCreator: getInsightCardDetails.pending,
        effect: (action, listenerApi) => {
            listenerApi.cancelActiveListeners;
            listenerApi.dispatch(setInsightCardDetails({ data: undefined, isLoading: true }));
        }
    }),
    getInsightCardsDetails: cardsMiddleware.startListening({
        actionCreator: getInsightCardDetails.fulfilled,
        effect: (action, listenerApi) => {
            listenerApi.cancelActiveListeners;
            listenerApi.dispatch(setInsightCardDetails({ data: action.payload, isLoading: false }));
        }
    })
};


