import * as React from 'react';
import Chip from '@mui/material/Chip';
import { ITagItem } from './ListingTags';
import classes from '../../styles/selected-tags.module.scss';



export function SelectedTags(props: { selectedItems: ITagItem[]; deleteTag?: (v: string) => void; style?: React.CSSProperties; }) {

    return <div className={classes.selectedItems} style={{ ...props.style }}>
        {props.selectedItems.map((data, index) => <Chip
            key={index}
            label={data.title}
            onDelete={props.deleteTag && (() => props.deleteTag?.(data.value))}
            className={classes.selectedChip} />

        )}
    </div>;
}
