import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { logEvents } from '../../actions/logActions';
import {
  deleteListingService,
  getListingService,
  getMarketAnalyticsService,
  getMarketAnalyticsSummaryService,
  IGetMarketAnalyticsParameters,
  setTrackingDetailsService,
} from '../../services/listing-service/listing-service';
import { setMarketAnalyticsGraphData, setMarketAnalyticsSummary, setSelectedListing } from './listingSlice';
import { IListing } from '../../models/create-listing/iListing';
import { IMarketDataSnapshot, IMarketDataSummary } from '../../services/listing-service/models/IMarketDataSummary';
import { ITracking } from 'src/components/TrackingDialog';
import { IAsyncPayload } from './IAsyncPayload';

export type BaseLoadingType<TReturnType, TArgType> = AsyncThunk<IAsyncPayload<TReturnType>, TArgType, any>;
export type GetListingType = BaseLoadingType<IListing, { listingId: string, countryCode?: string; }>;
export type GetMarketAnalyticsSnapshotType = BaseLoadingType<IMarketDataSnapshot[], IGetMarketAnalyticsParameters>;
export type GetMarketAnalyticsSummaryType = BaseLoadingType<IMarketDataSummary, IGetMarketAnalyticsParameters>;

interface IListingActions {
  getListing: GetListingType;
  getMarketAnalyticsSnapshot: GetMarketAnalyticsSnapshotType;
  getMarketAnalyticsSummary: GetMarketAnalyticsSummaryType;
  deleteListing: AsyncThunk<void, string, any>;
  setTrackingDetails: AsyncThunk<void, ITracking, any>;
}


const listingActions: IListingActions = {

  setTrackingDetails: createAsyncThunk('listing/setTrackingDetails',
    async (trackingDetails: ITracking, thunkApi: any) => {

      try {
        await setTrackingDetailsService(trackingDetails);

      } catch (ex: any) {
        // eslint-disable-next-line no-console
        thunkApi.dispatch(
          logEvents({
            eventName: 'SET_LISTING_TRACKING_DETAILS',
            payload: { error: `Failed to set listing tracking details: ${trackingDetails.listingId} ${trackingDetails.trackingNumber} ${trackingDetails.trackingUrl}` },
          })
        );
        return thunkApi.rejectWithValue(ex.message);
      }
    })

  , deleteListing: createAsyncThunk('listing/deleteListing',
    async (listingId: string, thunkApi: any) => {
      try {
        await deleteListingService(listingId);
      } catch (ex: any) {
        thunkApi.dispatch(
          logEvents({
            eventName: 'DELETE_LISTING_ERROR',
            payload: { error: `failed to delete listing with listingId: ${listingId}` },
          })
        );
        return thunkApi.rejectWithValue(ex.message);
      }
    }),


  getListing: createAsyncThunk('listing/getListing',
    async (listing: { listingId: string, countryCode?: string; }, thunkApi: any) => {
      const { listingId, countryCode } = listing;
      try {
        thunkApi.dispatch(setSelectedListing({ data: undefined, isLoading: true }));
        const result = await getListingService(listingId, countryCode);

        if (!result?.err && !result?.result.err) {
          return { data: result?.result, isLoading: false };
        }

        thunkApi.dispatch(logEvents({ eventName: 'LISTING_GET_ERROR', payload: { error: result.err } }));
        return thunkApi.rejectWithValue(result.err);
      } catch (ex: any) {
        thunkApi.dispatch(
          logEvents({
            eventName: 'LISTING_GET_ERROR',
            payload: { error: `failed to get listing with listingId: ${listingId}` },
          })
        );
        return thunkApi.rejectWithValue(ex.message);
      }

    }),
  getMarketAnalyticsSnapshot: createAsyncThunk(
    'listing/getFiveYearsMarketAnalytics',
    async (marketParams: IGetMarketAnalyticsParameters, thunkApi: any) => {
      try {
        thunkApi.dispatch(setMarketAnalyticsGraphData({ data: [], isLoading: false }));
        const result = await getMarketAnalyticsService(marketParams);

        if (!result?.err) {
          return { data: result?.result.response.docs ?? [], isLoading: false };
        }

        thunkApi.dispatch(logEvents({ eventName: 'GET_MARKET_ANALYTICS_ERROR', payload: { error: result.err } }));
        return thunkApi.rejectWithValue('We had trouble getting market analytics ');
      } catch (ex: any) {
        thunkApi.dispatch(
          logEvents({
            eventName: 'GET_MARKET_ANALYTICS_ERROR',
            payload: { error: `failed to get listing with listingId: ${marketParams}` },
          })
        );
        return thunkApi.rejectWithValue('We had trouble getting market analytics ');
      }
    }
  ),
  getMarketAnalyticsSummary: createAsyncThunk(
    'listing/getMarketAnalyticsSummary',
    async (marketParams: IGetMarketAnalyticsParameters, thunkApi: any) => {
      try {

        thunkApi.dispatch(setMarketAnalyticsSummary({ data: {} as IMarketDataSummary, isLoading: true }));
        const result = await getMarketAnalyticsSummaryService(marketParams);
        if (!result?.err) {
          return {
            data: result?.result.response.docs?.[0] ?? {},
            isLoading: false
          };
        }

        thunkApi.dispatch(logEvents({ eventName: 'GET_MARKET_ANALYTICS_ERROR', payload: { error: result.err } }));
        return thunkApi.rejectWithValue('We had trouble getting market analytics summary');
      } catch (ex: any) {
        thunkApi.dispatch(
          logEvents({
            eventName: 'GET_MARKET_ANALYTICS_ERROR',
            payload: { error: `failed to get market analytics: ${marketParams}` },
          })
        );
        return thunkApi.rejectWithValue('We had trouble getting market analytics summary');
      }
    }
  ),
};

export const {
  getListing,
  getMarketAnalyticsSnapshot,
  getMarketAnalyticsSummary,
  deleteListing,
  setTrackingDetails
} = listingActions;
